import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
} from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import URL from "./Host";
import close from "./img/close.png";
import deleteImg from "./img/Inbox cleanup-rafiki.png";
import Noimg from "./img/noimg.jpg";
import Base from "./Base";

export default function Knowladge() {
  const [news, setNews] = useState([]);
  const [news1, setNews1] = useState([]);
  const [page, setPage] = useState(0);
  const [newsId, setNewsId] = useState();
  const [base, setBase] = useState([]);
  const [newId, setNewId] = useState();
  const [oneuser, setOneuser] = useState();
  const [options, setoptions] = useState([]);
  const [select, setSelect] = useState([]);
  const [data, setData] = useState();
  const [dostup, setDostup] = useState(
    JSON.parse(sessionStorage.getItem("dos"))
  );

  const title = document.querySelectorAll("#Notiftitle");
  const description = document.querySelectorAll("#Notifdestcription");
  const user = document.querySelectorAll("#Notifuser");

  useEffect(() => {
    axios
      .get(`${URL}/api/notification`, {
        headers: { Authorization: "Beraer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        const Filter=res.data.filter(item=>item.notification_id==0)
        setNews(Filter);
        setNews1(Filter);
      });
    axios
      .get(`${URL}/auth/users`, {
        headers: { Authorization: "Beraer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        setBase(res.data);
      });
    axios
      .get(`${URL}/auth/oneuser`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        res.data.map((item) => {
          setOneuser(item.id);
        });
      });
  }, []);

  function newsPage(id) {
    setNewsId(id);
    news.map((item) => {
      if (item.id == id) {
        var time = new Date(item.time_create);
        var a = time.getHours();
        var b = time.getMinutes();
        setData(a + " : " + b);
      }
    });
    setPage(1);
    var formdata = new FormData();
    formdata.append("read", true);

    axios
      .get(`${URL}/api/notification/read/${id}`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        axios
        .get(`${URL}/api/notification`, {
          headers: { Authorization: "Beraer " + sessionStorage.getItem("token") },
        })
        .then((res) => {
          const Filter=res.data.filter(item=>item.notification_id==0)
          setNews(Filter);
          setNews1(Filter);
        });
      })
      .catch((err) => {
        alert("Информация не отправлена");
      });
  }

  function postNewModal() {
    document.querySelector("#postNotif_Modal").style = "display:flex";
  }
  function postNewClose() {
    document.querySelector("#postNotif_Modal").style = "display:none";
  }

  function putNewModal(id) {
    setNewId(id);
    news.map((item) => {
      if (item.id == id) {
        title[1].value = item.title;
        description[1].value = item.description;
        // user[1].value = item.to_user_id
      }
    });
    document.querySelector("#putNotif_Modal").style = "display:flex";
  }
  function putNewClose() {
    document.querySelector("#putNotif_Modal").style = "display:none";
  }

  function deleteNewModal(id) {
    setNewId(id);
    document.querySelector("#deleteNotif_Modal").style = "display:flex";
  }
  function deleteNewClose() {
    document.querySelector("#deleteNotif_Modal").style = "display:none";
  }

  const columns = [
    {
      title: "Заглавие",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "",
      dataIndex: "id",
      key: "balance",
      render: (dataIndex, _salom) => (
        <div>
          {dostup[0].notification_put ||
          sessionStorage.getItem("position") == 3 ? (
            <button
              className="user_post_button"
              onClick={() => putNewModal(dataIndex)}
            >
              Редактировать
            </button>
          ) : (
            <div></div>
          )}{" "}
          {dostup[0].notification_delete ||
          sessionStorage.getItem("position") == 3 ? (
            <button
              className="user_post_button"
              onClick={() => deleteNewModal(dataIndex)}
            >
              Удалить
            </button>
          ) : (
            <div></div>
          )}{" "}
          <button
            className="user_post_button_malumot"
            onClick={() => Read(dataIndex, _salom)}
          >
            Информация
          </button>
        </div>
      ),
    },
  ];

  function postNew() {
    for (let i = 0; i < select.length; i++) {
      var formdata = new FormData();
      formdata.append("title", title[0].value);
      formdata.append("description", description[0].value);
      formdata.append("user_id", oneuser);
      formdata.append("notification_id",0)
      formdata.append("to_user_id", select[i].id);

      axios
        .post(`${URL}/api/notification`, formdata, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          document.querySelector("#postNotif_Modal").style = "display:none";
          axios
          .get(`${URL}/api/notification`, {
            headers: { Authorization: "Beraer " + sessionStorage.getItem("token") },
          })
          .then((res) => {
            const Filter=res.data.filter(item=>item.notification_id==0)
            setNews(Filter);
            setNews1(Filter);
          });
          window.location.reload();
        })
        .catch((err) => {
          alert("Информация не отправлена");
        });
    }
  }
  function putNew() {
    for (let i = 0; i < select.length; i++) {
      var formdata = new FormData();
      formdata.append("title", title[1].value);
      formdata.append("description", description[1].value);
      formdata.append("user_id", oneuser);
      formdata.append("notification_id",0)
      formdata.append("read",false)
      formdata.append("to_user_id", select[i].id);

      axios
        .put(`${URL}/api/notification/${newId}`, formdata, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          document.querySelector("#putNotif_Modal").style = "display:none";
          axios
          .get(`${URL}/api/notification`, {
            headers: { Authorization: "Beraer " + sessionStorage.getItem("token") },
          })
          .then((res) => {
            const Filter=res.data.filter(item=>item.notification_id==0)
            setNews(Filter);
            setNews1(Filter);
          });
          window.location.reload();
        })
        .catch((err) => {
          alert("Информация не изменилась");
        });
    }
  }
  function deleteNew() {
    axios
      .delete(`${URL}/api/notification/${newId}`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Данные удалены");
        document.querySelector("#deleteNotif_Modal").style = "display:none";
        axios
        .get(`${URL}/api/notification`, {
          headers: { Authorization: "Beraer " + sessionStorage.getItem("token") },
        })
        .then((res) => {
          const Filter=res.data.filter(item=>item.notification_id==0)
          setNews(Filter);
          setNews1(Filter);
        });
      })
      .catch((err) => {
        alert("Данные не удалены");
      });
  }

  function Select() {
    document.querySelector(".select_div").style = "display: block !important;";
  }
  function Select1() {
    document.querySelector(".select_div1").style = "display: block !important;";
  }

  function SelectNone() {
    document.querySelector(".select_div").style = "display:none";
  }
  function SelectNone1() {
    document.querySelector(".select_div1").style = "display:none";
  }

  function SelcetOption(name, id, key) {
    var a = select;
    var t = true;
    for (let i = 0; i < a.length; i++) {
      if (a[i].id == id) {
        t = false;
        a.splice(i, 1);
        document.querySelectorAll("#select-input2")[key].style = "";
      }
    }
    if (t) {
      document.querySelectorAll("#select-input2")[key].style =
        "background-color: #1890ff;";
      a.push({ title: name, id: id });
    }
    document.querySelector("#select-input").innerHTML = "";
    a.map((item) => {
      document.querySelector(
        "#select-input"
      ).innerHTML += `<p class="SelectIchi">${item.title}</p>`;
    });
    setSelect(a);
  }
  function SelcetOption1(name, id, key) {
    var a = select;
    var t = true;
    for (let i = 0; i < a.length; i++) {
      if (a[i].id == id) {
        t = false;
        a.splice(i, 1);
        document.querySelectorAll("#select-input3")[key].style = "";
      }
    }
    if (t) {
      document.querySelectorAll("#select-input3")[key].style =
        "background-color: #1890ff;";
      a.push({ title: name, id: id });
    }
    document.querySelector("#select-input1").innerHTML = "";
    a.map((item) => {
      document.querySelector(
        "#select-input1"
      ).innerHTML += `<p class="SelectIchi">${item.title}</p>`;
    });
    setSelect(a);
  }
  function Read(dataIndex, id) {
    window.location = "/mynotification"
    localStorage.setItem("NotifId", id.to_user_id);
    // localStorage.setItem("NotificationId", id.id);
    localStorage.setItem("NotifKey",id.id)
    if (id.user_id !== oneuser) {
      var formdata = new FormData();
      formdata.append("read", true);

      axios
        .get(`${URL}/api/notification/read/${dataIndex}`, formdata, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          axios
            .get(`${URL}/api/notification`, {
              headers: {
                Authorization: "Beraer " + sessionStorage.getItem("token"),
              },
            })
            .then((res) => {
              setNews(res.data);
            });
        })
        .catch((err) => {
          alert("Информация не отправлена");
        });
    }
  }

  function Search(e) {
    const Search = news.filter((item) => item.title.includes(e.target.value));
    if (e.target.value) {
      setNews(Search);
    } else {
      setNews(news1);
    }
  }

  return (
    <div>
      <div>
        <div className="search_big_div">
          {dostup[0].notification_post ||
          sessionStorage.getItem("position") == 3 ? (
            <button onClick={() => postNewModal()} className="user_post_button">
              Добавить
            </button>
          ) : (
            <div></div>
          )}
          <div style={{ position: "relative" }}>
            <AiOutlineSearch className="search" />
            <input
              onChange={Search}
              placeholder="Введите здесь..."
              type="text"
            />
          </div>
        </div>
        <Table dataSource={news} columns={columns} />
      </div>

      <div id="postNotif_Modal" className="Modaldiv">
        <div className="postUserModal_div" style={{ width: "340px" }}>
          <div className="postUserModal_div_icon">
            <img src={close} className="icon" onClick={() => postNewClose()} />
          </div>
          <div
            style={{ display: "flex", flexWrap: "wrap" }}
            className="postUserModal_div_label"
          >
            <label htmlFor="">
              <p>Заглавие</p>
              <input id="Notiftitle" type="text" />
            </label>
            <label htmlFor="">
              <p>Описание</p>
              <input id="Notifdestcription" type="text" />
            </label>
            <label style={{ width: "100%" }} htmlFor="">
              <p>Какому человеку вы отправляете</p>
              <div className="select_big_div">
                {/* <input onClick={()=>Select()} id='select-input' type="text" /> */}

                <div onClick={() => Select()} id="select-input"></div>
                <div onClick={() => SelectNone()} className="select_div">
                  {base.map((item, key) => {
                    return (
                      <p
                        id="select-input2"
                        onClick={() =>
                          SelcetOption(item.username, item.id, key)
                        }
                        value={item.id}
                      >
                        {item.username}
                      </p>
                    );
                  })}
                </div>
              </div>
            </label>
          </div>
          <div className="postUserModal_div_button">
            <button onClick={() => postNew()}>Отправить</button>
          </div>
        </div>
      </div>
      <div id="putNotif_Modal" className="Modaldiv">
        <div className="postUserModal_div" style={{ width: "340px" }}>
          <div className="postUserModal_div_icon">
            <img src={close} className="icon" onClick={() => putNewClose()} />
          </div>
          <div
            style={{ display: "flex", flexWrap: "wrap" }}
            className="postUserModal_div_label"
          >
            <label htmlFor="">
              <p>Заглавие</p>
              <input id="Notiftitle" type="text" />
            </label>
            <label htmlFor="">
              <p>Описание</p>
              <input id="Notifdestcription" type="text" />
            </label>
            <label style={{ width: "100%" }} htmlFor="">
              <p>Какому человеку вы отправляете</p>
              <div className="select_big_div">
                {/* <input onClick={()=>Select()} id='select-input' type="text" /> */}

                <div onClick={() => Select1()} id="select-input1"></div>
                <div onClick={() => SelectNone1()} className="select_div1">
                  {base.map((item, key) => {
                    return (
                      <p
                        id="select-input3"
                        onClick={() =>
                          SelcetOption1(item.username, item.id, key)
                        }
                        value={item.id}
                      >
                        {item.username}
                      </p>
                    );
                  })}
                </div>
              </div>
            </label>
          </div>
          <div className="postUserModal_div_button">
            <button onClick={() => putNew()}>Отправить</button>
          </div>
        </div>
      </div>
      <div id="deleteNotif_Modal" className="deleteModal">
        <div className="deleteModal_div">
          <div className="postUserModal_div_icon">
            <img
              src={close}
              className="icon"
              onClick={() => deleteNewClose()}
            />
          </div>
          <img src={deleteImg} alt="" />
          <p>Вы хотите отключить это уведомление?</p>
          <div className="deleteButton_div">
            <button onClick={() => deleteNewClose()}>Отмена</button>
            <button onClick={() => deleteNew()}>Подтвердить</button>
          </div>
        </div>
      </div>
    </div>
  );
}
