import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
} from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import URL from "./Host";
import close from "./img/close.png";
import deleteImg from "./img/Inbox cleanup-rafiki.png";
import Noimg from "./img/noimg.jpg";
import Base from "./Base";

export default function Knowladge() {
  const [news, setNews] = useState([]);
  const [news1, setNews1] = useState([]);
  const [page, setPage] = useState(0);
  const [newsId, setNewsId] = useState();
  const [base, setBase] = useState([]);
  const [newId, setNewId] = useState();
  const [dostup, setDostup] = useState(
    JSON.parse(sessionStorage.getItem("dos"))
  );
  const [checkbox, setCheckbox] = useState(false);

  const name = document.querySelectorAll("#Newusername");
  const image = document.querySelectorAll("#Newimage");
  const description = document.querySelectorAll("#Newdestcription");
  const base_id = document.querySelectorAll("#Newbase");
  const link = document.querySelectorAll("#Newlink");

  useEffect(() => {
    axios.get(`${URL}/api/knowladge`).then((res) => {
      setNews(res.data);
      setNews1(res.data);
    });
    axios.get(`${URL}/api/base_theme`).then((res) => {
      setBase(res.data);
    });
  }, []);

  function newsPage(id) {
    setNewsId(id);
    setPage(1);
  }

  function postNewModal() {
    document.querySelector("#postNew_Modal").style = "display:flex";
  }
  function postNewClose() {
    document.querySelector("#postNew_Modal").style = "display:none";
  }

  function putNewModal(id) {
    setCheckbox(true);
    setNewId(id);
    news.map((item) => {
      if (item.id == id) {
        name[1].value = item.name;
        description[1].value = item.description;
        link[1].value = item.link;
        setTimeout(() => {
          document.querySelector("#checkboxnews").checked = true;
          document.querySelector("#image_malumotCheckboxNews").value =
            item.image;
        }, 100);
      }
    });
    document.querySelector("#putNew_Modal").style = "display:flex";
  }
  function putNewClose() {
    setCheckbox(false);
    document.querySelector("#putNew_Modal").style = "display:none";
  }

  function deleteNewModal(id) {
    setNewId(id);
    document.querySelector("#deleteNew_Modal").style = "display:flex";
  }
  function deleteNewClose() {
    document.querySelector("#deleteNew_Modal").style = "display:none";
  }

  const columns = [
    {
      title: "Изображение",
      dataIndex: "image",
      key: "image",
      render: (image) => <img alt={image} src={image} />,
    },
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "id",
      key: "balance",
      render: (dataIndex) => (
        <div>
          {dostup[0].news_put || sessionStorage.getItem("position") == 3 ? (
            <button
              className="user_post_button"
              onClick={() => putNewModal(dataIndex)}
            >
              Редактировать
            </button>
          ) : (
            <div></div>
          )}{" "}
          {dostup[0].news_delete || sessionStorage.getItem("position") == 3 ? (
            <button
              className="user_post_button"
              onClick={() => deleteNewModal(dataIndex)}
            >
              Удалить
            </button>
          ) : (
            <div></div>
          )}{" "}
          <button
            className="user_post_button_malumot"
            onClick={() => newsPage(dataIndex)}
          >
            Информация
          </button>
        </div>
      ),
    },
  ];

  function postNew() {
    var formdata = new FormData();
    formdata.append("name", name[0].value);
    formdata.append("description", description[0].value);
    checkbox == false
      ? formdata.append("image", document.querySelector("#Newimage").files[0])
      : formdata.append(
          "image",
          document.querySelector("#imageChecboxsNews").value
        );
    formdata.append("link", link[0].value);
    formdata.append("base_theme", base_id[0].value);

    axios
      .post(`${URL}/api/knowladge`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Информация отправлена");
        document.querySelector("#postNew_Modal").style = "display:none";
        axios
          .get(`${URL}/api/knowladge`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setNews(res.data);
          });
      })
      .catch((err) => {
        alert("Информация не отправлена");
      });
  }
  function putNew() {
    var formdata = new FormData();
    formdata.append("name", name[1].value);
    formdata.append("description", description[1].value);
    checkbox == false
      ? formdata.append("image", document.querySelector("#Newimage1").files[0])
      : formdata.append(
          "image",
          document.querySelector("#image_malumotCheckboxNews").value
        );
    formdata.append("link", link[1].value);
    formdata.append("base_theme", base_id[1].value);

    axios
      .put(`${URL}/api/knowladge/${newId}`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Информация изменилась");
        setCheckbox(false);
        document.querySelector("#putNew_Modal").style = "display:none";
        axios
          .get(`${URL}/api/knowladge`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setNews(res.data);
          });
      })
      .catch((err) => {
        alert("Информация не изменилась");
      });
  }
  function deleteNew() {
    axios
      .delete(`${URL}/api/knowladge/${newId}`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Данные удалены");
        document.querySelector("#deleteNew_Modal").style = "display:none";
        axios
          .get(`${URL}/api/knowladge`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setNews(res.data);
          });
      })
      .catch((err) => {
        alert("Данные не удалены");
      });
  }

  function Search(e) {
    const Serach = news.filter((item) => item.name.includes(e.target.value));
    if (e.target.value) {
      setNews(Serach);
    } else {
      setNews(news1);
    }
  }

  function Checkbox(checked1) {
    setCheckbox(checked1);
    if (checked1 == true) {
      news.map((item) => {
        if (item.id == newId) {
          setTimeout(() => {
            document.querySelector("#image_malumotCheckboxNews").value =
              item.image;
          }, 100);
        }
      });
    }
  }
  return (
    <div>
      {page == 1 ? (
        news.map((item) => {
          if (item.id == newsId) {
            return (
              <div>
                <div className="exit_button">
                  <button onClick={() => setPage(0)}>Назад</button>
                </div>
                <div className="news_page">
                  {item.image == null ? (
                    <img src={Noimg} alt="" />
                  ) : (
                    <img
                      src={item.image}
                      alt=""
                    />
                  )}
                  <div style={{ marginTop: "20px", width: "70%" }}>
                    <h1>{item.name}</h1>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            );
          }
        })
      ) : (
        <div>
          <div className="search_big_div">
            {dostup[0].news_post || sessionStorage.getItem("position") == 3 ? (
              <button
                onClick={() => postNewModal()}
                className="user_post_button"
              >
                Добавить
              </button>
            ) : (
              <div></div>
            )}
            <div style={{ position: "relative" }}>
              <AiOutlineSearch className="search" />
              <input
                onChange={Search}
                placeholder="Введите здесь..."
                type="text"
              />
            </div>
          </div>
          <Table dataSource={news} columns={columns} />
          <Base />
        </div>
      )}

      <div id="postNew_Modal" className="Modaldiv">
        <div className="postUserModal_div" style={{ width: "340px" }}>
          <div className="postUserModal_div_icon">
            <img src={close} className="icon" onClick={() => postNewClose()} />
          </div>
          <div
            style={{ display: "flex", flexWrap: "wrap" }}
            className="postUserModal_div_label"
          >
            <label htmlFor="">
              <p>Имя</p>
              <input id="Newusername" placeholder="Имя" type="text" />
            </label>
            <label htmlFor="">
              <p>
                Изображение{" "}
                <input
                  type="checkbox"
                  onClick={(e) => Checkbox(e.target.checked)}
                  style={{ width: "15px", height: "15px" }}
                />
              </p>
              {checkbox == false ? (
                <input
                  id="Newimage"
                  className="image"
                  placeholder="Изображение"
                  type="file"
                />
              ) : (
                <input
                  id="imageChecboxsNews"
                  className="image"
                  placeholder="Изображение"
                  type="text"
                />
              )}
            </label>
            <label htmlFor="">
              <p>Ссылка</p>
              <input id="Newlink" placeholder="Ссылка" type="text" />
            </label>
            <label htmlFor="">
              <p>Основа</p>
              <select name="" id="Newbase">
                {base.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select>
            </label>
            <label style={{width:'100%'}} htmlFor="">
              <p>Описание</p>
              <textarea
                className="description"
                id="Newdestcription"
                placeholder="Описание"
                type="text"
              />
            </label>
          </div>
          <div className="postUserModal_div_button">
            <button onClick={() => postNew()}>Отправить</button>
          </div>
        </div>
      </div>
      <div id="putNew_Modal" className="Modaldiv">
        <div className="postUserModal_div" style={{ width: "340px" }}>
          <div className="postUserModal_div_icon">
            <img src={close} className="icon" onClick={() => putNewClose()} />
          </div>
          <div
            style={{ display: "flex", flexWrap: "wrap" }}
            className="postUserModal_div_label"
          >
            <label htmlFor="">
              <p>Имя</p>
              <input id="Newusername" type="text" />
            </label>
            <label htmlFor="">
              <p>
                Изображение{" "}
                <input
                  type="checkbox"
                  id="checkboxnews"
                  onClick={(e) => Checkbox(e.target.checked)}
                  style={{ width: "15px", height: "15px" }}
                />
              </p>
              {checkbox == false ? (
                <input
                  id="Newimage1"
                  className="image"
                  placeholder="Изображение"
                  type="file"
                />
              ) : (
                <input id="image_malumotCheckboxNews" type="text" />
              )}
            </label>
            <label htmlFor="">
              <p>Ссылка</p>
              <input id="Newlink" type="text" />
            </label>
            <label htmlFor="">
              <p>Основа</p>
              <select name="" id="Newbase">
                {base.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select>
            </label>
            <label style={{width:'100%'}} htmlFor="">
              <p>Описание</p>
              <textarea
              className="description"
                id="Newdestcription"
                placeholder="Описание"
                type="text"
              />
            </label>
          </div>
          <div className="postUserModal_div_button">
            <button onClick={() => putNew()}>Отправить</button>
          </div>
        </div>
      </div>
      <div id="deleteNew_Modal" className="deleteModal">
        <div className="deleteModal_div">
          <div className="postUserModal_div_icon">
            <img
              src={close}
              className="icon"
              onClick={() => deleteNewClose()}
            />
          </div>
          <img src={deleteImg} alt="" />
          <p>Вы хотите удалить студента</p>
          <div className="deleteButton_div">
            <button onClick={() => deleteNewClose()}>Отмена</button>
            <button onClick={() => deleteNew()}>Подтвердить</button>
          </div>
        </div>
      </div>
    </div>
  );
}
