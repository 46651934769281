import React, { useEffect, useState } from "react";
import Salom from "./img/Inbox cleanup-rafiki.png";
import { BsTelegram, BsArrow90DegLeft } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { AiFillCloseCircle } from "react-icons/ai";
import URL from "./Host";
import axios from "axios";

export default function Course_comment() {
  const [comment, setCommment] = useState([]);
  const [user, setUser] = useState([]);
  const [oneuser, setOneuser] = useState([]);
  const [image, setImage] = useState();
  const [Name, setName] = useState();
  const [text, setText] = useState();
  const [subComment, setSubComment] = useState([]);
  const [CommentId, setCommentId] = useState(0);
  const [CommenKey, setCommenKey] = useState();
//   const [length,setLength]=useState(0)

  useEffect(() => {
    axios.get(`${URL}/api/course_theme_comment/`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        const Filter = res.data.filter(
          (item) => item.theme == localStorage.getItem("themesID") && item.task_commnet_id==0
        );
        setCommment(Filter);

        for (let i = 0; i < Filter.length; i++) {
          for (let j = 0; j < res.data.length; j++) {
            if (Filter[i].subcomment == res.data[j].id) {
              // setSubComment(res.data)
              Filter[i].subName = res.data[j].user_id;
              Filter[i].text1 = res.data[j].text;
            }
          }
        }
        setCommment(Filter);
        axios
        .get(`${URL}/auth/users`, {
          headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
        })
        .then((res1) => {
          for (let i = 0; i < Filter.length; i++) {
            for (let j = 0; j < res1.data.length; j++) {
              if (Filter[i].subName == res1.data[j].id) {
                Filter[i].subName = res1.data[j].username;
              }
            }
          }
          setCommment(Filter);
          setUser(res1.data);
        });
      });

    axios
      .get(`${URL}/auth/oneuser`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        res.data.map((item) => {
          setOneuser(item.id);
        });
      });
  }, []);

  function commentPost() {
    var formdata = new FormData();
    formdata.append("theme", localStorage.getItem("themesID"));
    formdata.append("image", null);
    formdata.append("text", document.querySelector("#message_send").value);
    formdata.append("subcomment", CommentId);
    formdata.append("user_id", oneuser);
    formdata.append("task_commnet_id", 0);

    axios
      .post(`${URL}/api/course_theme_comment/`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        axios.get(`${URL}/api/course_theme_comment/`, {
            headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
          })
          .then((res) => {
            const Filter = res.data.filter(
              (item) => item.theme == localStorage.getItem("themesID") && item.task_commnet_id==0
            );
            setCommment(Filter);
    
            for (let i = 0; i < Filter.length; i++) {
              for (let j = 0; j < res.data.length; j++) {
                if (Filter[i].subcomment == res.data[j].id) {
                  // setSubComment(res.data)
                  Filter[i].subName = res.data[j].user_id;
                  Filter[i].text1 = res.data[j].text;
                }
              }
            }
            setCommment(Filter);
            axios
            .get(`${URL}/auth/users`, {
              headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
            })
            .then((res1) => {
              for (let i = 0; i < Filter.length; i++) {
                for (let j = 0; j < res1.data.length; j++) {
                  if (Filter[i].subName == res1.data[j].id) {
                    Filter[i].subName = res1.data[j].username;
                  }
                }
              }
              setCommment(Filter);
              setUser(res1.data);
            });
          });
        setCommentId(0);
        document.querySelector("#message_send").value=" "
        document.querySelectorAll("#subcomment")[CommenKey].style =
          "position: fixed;top:-100%";
      })
      .catch((err) => {});
  }

  function commentdelete(id) {
    axios
      .delete(`${URL}/api/course_theme_comment/${id}`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        axios.get(`${URL}/api/course_theme_comment/`, {
            headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
          })
          .then((res) => {
            const Filter = res.data.filter(
              (item) => item.theme == localStorage.getItem("themesID") && item.task_commnet_id==0
            );
            setCommment(Filter);
    
            for (let i = 0; i < Filter.length; i++) {
              for (let j = 0; j < res.data.length; j++) {
                if (Filter[i].subcomment == res.data[j].id) {
                  // setSubComment(res.data)
                  Filter[i].subName = res.data[j].user_id;
                  Filter[i].text1 = res.data[j].text;
                }
              }
            }
            setCommment(Filter);
            axios
            .get(`${URL}/auth/users`, {
              headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
            })
            .then((res1) => {
              for (let i = 0; i < Filter.length; i++) {
                for (let j = 0; j < res1.data.length; j++) {
                  if (Filter[i].subName == res1.data[j].id) {
                    Filter[i].subName = res1.data[j].username;
                  }
                }
              }
              setCommment(Filter);
              setUser(res1.data);
            });
          });
      });
  }

  function subCommentModal(key, image, username, text, id) {
    document.querySelectorAll("#subcomment")[key].style = "position: fixed;top:0px";
    setImage(image);
    setName(username);
    setText(text);
    setCommenKey(key);
    setCommentId(id);
  }
  function subCommentClose(key) {
    setCommentId(0);
    document.querySelectorAll("#subcomment")[key].style = "position: fixed;top:-100%";
  }

  return (
    <div>
      <div className="comment_big_div">
        {/* oyna */}
        <div className="comment_big_div_oyna_div">
          {/* ozi */}

            {comment.map((item, key) => {
                return (
                  <div>
                    {user.map((user) => {
                      if (item.user_id == user.id && item.subcomment == 0) {
                        return (
                          <div className="comment_big_div_oyna_div_message">
                            <div className="comment_big_div_oyna_div_message_img">
                              <img src={user.image} alt="" />
                            </div>
                            <div className="comment_big_div_oyna_div_message_div">
                              <h1 style={{color:'#000'}}>{user.username}</h1>
                              <p style={{color:'#000'}}>{item.text}</p>
                              <div className="comment_big_div_oyna_div_message_div_delete">
                                <p style={{color:'#1890ff'}}
                                  onClick={() =>
                                    subCommentModal(
                                      key,
                                      user.image,
                                      user.username,
                                      item.text,
                                      item.id
                                    )
                                  }
                                >
                                  <BsArrow90DegLeft />
                                  Отвечать
                                </p>
                                <p onClick={() => commentdelete(item.id)}>
                                  <MdDelete />
                                  Удалить
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                    <div
                      id="subcomment"
                      className="comment_big_div_message_div_otevt_oyna"
                    >
                      <div className="comment_big_div_oyna_div_message_img">
                        <img src={image} alt="" />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          paddingRight: "10px",
                        }}
                      >
                        <div className="comment_big_div_oyna_div_message_div">
                          <h1>{Name}</h1>
                          <p>{text}</p>
                        </div>
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#fff",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => subCommentClose(key)}
                        >
                          <AiFillCloseCircle />
                        </p>
                      </div>
                    </div>
                  </div>
                );
            })}

            {comment.map((item,key) => {
                return (
                  <div>
                    {user.map((user) => {
                      if (item.user_id == user.id && item.subcomment != 0) {
                        return (
                          <div>
                            <div className="comment_big_div_oyna_div_message_div_otvet">
                              <h1>{item.subName}</h1>
                              <p>{item.text1}</p>
                            </div>
                            <div className="comment_big_div_oyna_div_message">
                              <div className="comment_big_div_oyna_div_message_img">
                                <img src={user.image} alt="" />
                              </div>
                              <div className="comment_big_div_oyna_div_message_div">
                                <h1 style={{color:'#000'}}>{user.username}</h1>
                                <p style={{color:'#000'}}>{item.text}</p>
                                <div className="comment_big_div_oyna_div_message_div_delete">
                                  <p style={{color:'#1890ff'}} onClick={()=>subCommentModal(
                                    key,
                                    user.image,
                                    user.username,
                                    item.text,
                                    item.id
                                  )}>
                                    <BsArrow90DegLeft />
                                    Отвечать
                                  </p>
                                  <p onClick={()=>commentdelete(item.id)}>
                                    <MdDelete />
                                    Удалить
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                );
            })}

          

          {/* otevet */}
          
        </div>

        {/* Message */}
        <div className="comment_big_div_message_div">
          <input id="message_send" type="text" />
          <button onClick={() => commentPost()}>
            <BsTelegram />
          </button>
        </div>
      </div>
    </div>
  );
}
