
import React, { useEffect, useState } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import "./styles/Chat.css"
import { IoArrowBackOutline } from 'react-icons/io';
import tgimg from "./img/photo_2023-06-25_22-19-50 (2).jpg";
import axios from "axios";
import URL from "./Host";
import userNull from "./img/149071.png";





function Chat({ socket, email, room }) {
    const [currentMessage, setCurrentMessage] = useState("");
    const [messageList, setMessageList] = useState([]);
    const [user, setUser] = useState([])
    const [userImage, setUserImage] = useState([])
    const [theme, setTheme] = useState(localStorage.getItem("back"))

    const sendMessage = async () => {
        if (currentMessage !== "") {
            const messageData = {
                room: room,
                author: email,
                message: currentMessage,
                time:
                    new Date(Date.now()).getHours() +
                    ":" +
                    new Date(Date.now()).getMinutes(),
            };

            await socket.emit("send_message", messageData);
            setMessageList((list) => [...list, messageData]);
            setCurrentMessage("");
        }
    };

    function send1() {
        if (theme == "moon") {
            document.querySelector(".chat-window .chat-footer button").style = "color:white";
        } else {
            document.querySelector(".chat-window .chat-footer button").style = "color:black";
        }
        if (theme == "moon") {
            document.querySelector(".chat-window .chat-footer button").style = "background:rgb(33,33,33)";
        } else {
            document.querySelector(".chat-window .chat-footer button").style = "background:white";
        }
    }
    function send2() {
        if (theme == "moon") {
            document.querySelector(".chat-window .chat-footer button").style = "color:black";
        } else {
            document.querySelector(".chat-window .chat-footer button").style = "color:white";
        }
        if (theme == "moon") {
            document.querySelector(".chat-window .chat-footer button").style = "background:rgb(33,33,33)";
        } else {
            document.querySelector(".chat-window .chat-footer button").style = "background:white";
        }
    } function back() {
        if (theme == "moon") {
            document.querySelector(".yozishma_bolim_text_nik").style = "background:rgb(33,33,33);color:white;display:block";
        } else {
            document.querySelector(".yozishma_bolim_text_nik").style = "background:#dbdbdb;color:black;display:block";
        }
        document.querySelector(".yozishma_small_div").style = "display:none"
    }
    useEffect(() => {
        if (theme == "moon") {
            document.querySelector(".chat-window .chat-header p").style = "color:white";
        } else {
            document.querySelector(".chat-window .chat-header p").style = "color:black";
        }
        socket.on("receive_message", (data) => {
            setMessageList((list) => [...list, data]);
            socket.on("load_messages", (data) => {
                setMessageList(data);
                console.log(data, "load messages");
            });
            // socket.emit("join_room", { email, room });
            console.log(data, " receive_message");
            console.log(messageList, "usestate");
        });
    }, [socket]);
    useEffect(() => {
        axios.get(`${URL}/auth/oneuser`,{headers:{Authorization:"Bearer "+sessionStorage.getItem("token")}}).then(res1=>{
        axios.get(`${URL}/auth/allusers`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
            socket.on("load_messages", (data) => {
                setMessageList(data);
                console.log(data, "load messages");
                data.map(item => {
                    const [img, img1] = item.room.split("_")
                    res1.data.map(oneuser=>{
                        if (img1==oneuser.email) {
                            const filter = res.data.filter(user => user.email == img)
                            setUserImage(filter)                        
                        }else{
                            const filter = res.data.filter(user => user.email == img1)
                            setUserImage(filter)
                        }
                    })

                })
                
            });
        })})

    }, [socket]);




    return (
        <div className="chat-window">
            <div className="chat-header">
                <i style={theme == "moon" ? { color: "white" } : { color: "black" }} id="back_icons" onClick={() => back()} class='bx bx-arrow-back'></i>
                {userImage.map(item => {
                    return <div style={{ marginLeft: "15px" }} className="tg_img">

                        {item.image?(<img style={{ height: "100%" }} src={"https://markazback2.onrender.com/" + item.image} alt="" />):(<img style={{ height: "100%" }} src={userNull} alt="" />)}

                    </div>
                })}
                <p >
                    {(() => {
                        if (!email) {
                            return "foydalanuvchini tanlang";
                        }
                        // split the room into two parts
                        const [email1, email2] = room.split("_");
                        // determine which part to display
                        const displayName = email1 === email ? email2 : email1;
                        return displayName.slice(0, -10);
                    })()}
                </p>


            </div>
            <div className="chat-body">
                <ScrollToBottom className="message-container">
                    {messageList.map((messageContent) => {
                        return (
                            <div
                                className="message"
                                id={email === messageContent.author ? "you" : "other"}
                            >
                                <div>
                                    <div className="message-content">
                                        <p>{messageContent.message}</p>
                                        <div className="timee"><p id="time">{messageContent.time}</p></div>

                                    </div>
                                    <div className="message-meta">

                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </ScrollToBottom>
            </div>
            <div className="chat-footer">
                <input
                    type="text"
                    value={currentMessage}
                    placeholder="Write a message..."
                    onChange={(event) => {
                        setCurrentMessage(event.target.value);
                    }}
                    onKeyPress={(event) => {
                        event.key === "Enter" && sendMessage();
                    }}
                />
                <button onMouseLeave={() => send2()} onMouseEnter={() => send1()} onClick={sendMessage}><i style={{ fontSize: "25px" }} class='bx bx-send'></i></button>
            </div>
        </div>
    );
}

export default Chat;