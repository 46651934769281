import React, { useEffect, useState } from "react";
import URL from "./Host";
import axios from "axios";
import close from "./img/close.png";

export default function Mynotification() {
  const [news, setNews] = useState([]);
  const [newsChat, setNewsChat] = useState([]);
  const [Oneuser, setOneuser] = useState([]);

  useEffect(() => {
    axios
      .get(`${URL}/api/notification`, {
        headers: { Authorization: "Beraer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        const Notif = res.data.filter(
          (item) => item.notification_id == localStorage.getItem("NotifKey")
        );
        const salom = res.data.filter(
          (item) => item.id == localStorage.getItem("NotifKey")
        );
        setNews(salom);
        setNewsChat(Notif);
        axios
          .get(`${URL}/auth/oneuser`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res1) => {
            res1.data.map((item) => {
              setOneuser(item);
              for (let i = 0; i < Notif.length; i++) {
              if (Notif[i].user_id!==item.id) {
                var formdata = new FormData()
                formdata.append("read", true)
                axios.get(`${URL}/api/notification/read/${Notif[i].id}`, formdata, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
                    axios.get(`${URL}/api/notification`, { headers: { Authorization: "Beraer " + sessionStorage.getItem("token") } }).then(res => {
                        const Notif=res.data.filter(item=>item.notification_id==localStorage.getItem("NotifKey"))
                        const salom=res.data.filter(item=>item.id==localStorage.getItem("NotifKey"))
                        setNews(salom)
                        setNewsChat(Notif)
                    })
                }).catch(err => {
                    alert("Информация не отправлена")
                })
              }
              }
            });
          });
      });
  }, []);

  function postMyNotifOpen(id) {
    document.querySelector("#postNotifMy_Modal").style = "display:flex";
  }

  function postMyNotifClose() {
    document.querySelector("#postNotifMy_Modal").style = "display:none";
  }

  function postNotifMy() {
    var formdata = new FormData();
    formdata.append("title", Oneuser.username);
    formdata.append("user_id", Oneuser.id);
    formdata.append("to_user_id", localStorage.getItem("NotifId"));
    formdata.append("notification_id", localStorage.getItem("NotifKey"));
    formdata.append(
      "description",
      document.querySelector("#MyNotiftitle").value
    );

    axios
      .post(`${URL}/api/notification`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        document.querySelector("#MyNotiftitle").value = "";
        axios
          .get(`${URL}/api/notification`, {
            headers: {
              Authorization: "Beraer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            const Notif = res.data.filter(
              (item) => item.notification_id == localStorage.getItem("NotifKey")
            );
            const salom = res.data.filter(
              (item) => item.id == localStorage.getItem("NotifKey")
            );
            setNews(salom);
            setNewsChat(Notif);
          });
      })
      .catch((err) => {
        alert("Не удалось отправить данные. Повторите попытку.");
      });
  }

  return (
    <div>
      <div className="exit_button">
        <button
          onClick={() => {
            window.location = "/notification";
          }}
        >
          Назад
        </button>
      </div>
      {news.map((item) => {
        return (
          <div>
            <div className="notification_page">
              <div className="notification_page_div">
                <div className="notification_page_div_read_div">
                  {item.read == false ? (
                    <p
                      className="notification_page_div_read"
                      style={{ color: "red" }}
                    ></p>
                  ) : (
                    <p className="notification_page_div_read"></p>
                  )}
                </div>
                <div>
                  {item.read == false ? (
                    <p className="notification_page_div_read_text">
                      Непрочитанное сообщение
                    </p>
                  ) : (
                    <p className="notification_page_div_read_text">
                      Сообщение прочитано
                    </p>
                  )}
                  <h1>{item.title}</h1>
                  <p style={{ color: "rgb(100, 90, 90)" }}>
                    {item.description}
                  </p>
                </div>
                <p style={{ color: "black" }}>{item.time_craete}</p>
              </div>
            </div>
          </div>
        );
      })}
      {newsChat.map((item) => {
        return (
          <div>
            <div className="notification_page">
              <div className="notification_page_div">
                <div className="notification_page_div_read_div">
                  {item.read == false ? (
                    <p
                      className="notification_page_div_read"
                      style={{ color: "red" }}
                    ></p>
                  ) : (
                    <p className="notification_page_div_read"></p>
                  )}
                </div>
                <div>
                  {item.read == false ? (
                    <p className="notification_page_div_read_text">
                      Непрочитанное сообщение
                    </p>
                  ) : (
                    <p className="notification_page_div_read_text">
                      Сообщение прочитано
                    </p>
                  )}
                  <h1>{item.title}</h1>
                  <p style={{ color: "rgb(100, 90, 90)" }}>
                    {item.description}
                  </p>
                </div>
                <p style={{ color: "black" }}>{item.time_craete}</p>
              </div>
            </div>
          </div>
        );
      })}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          padding: "10px",
          background: "#1890ff",
          borderRadius: "10px",
        }}
      >
        <input
          style={{
            border: "0",
            width: "100%",
            borderRadius: "5px",
            height: "30px",
          }}
          id="MyNotiftitle"
          type="text"
        />
        <button
          style={{
            width: "100px",
            height: "30px",
            borderRadius: "5px",
            background: "blue",
            color: "white",
            border: "0",
            cursor: "pointer",
          }}
          onClick={() => postNotifMy()}
        >
          Send
        </button>
      </div>

      <div id="postNotifMy_Modal" className="Modaldiv">
        <div className="postUserModal_div" style={{ width: "340px" }}>
          <div className="postUserModal_div_icon">
            <img
              src={close}
              className="icon"
              onClick={() => postMyNotifClose()}
            />
          </div>
          <div
            style={{ display: "flex", flexWrap: "wrap" }}
            className="postUserModal_div_label"
          >
            <label htmlFor="">
              <p>Отправить ему сообщение</p>
              <input id="MyNotiftitle" type="text" />
            </label>
          </div>
          <div className="postUserModal_div_button">
            <button>Отправить</button>
          </div>
        </div>
      </div>
    </div>
  );
}
