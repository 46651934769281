import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
} from "antd";
import { AiOutlineSearch } from "react-icons/ai"
import axios from "axios"
import URL from "./Host"
import close from "./img/close.png"
import deleteImg from "./img/Inbox cleanup-rafiki.png"
import Noimg from "./img/noimg.jpg"
import Help_category from "./Help_category"


export default function Knowladge() {
  const [news, setNews] = useState([])
  const [news1, setNews1] = useState([])
  const [page, setPage] = useState(0)
  const [newsId, setNewsId] = useState()
  const [base, setBase] = useState([])
  const [newId, setNewId] = useState()
  const [dostup,setDostup]=useState(JSON.parse(sessionStorage.getItem("dos")))


  const name = document.querySelectorAll("#Helpusername")
  const image = document.querySelectorAll("#Helpimage")
  const description = document.querySelectorAll("#Helpdestcription")



  useEffect(() => {
    axios.get(`${URL}/api/call_me/`,{headers:{Authorization:"Bearer " + sessionStorage.getItem("token")}}).then(res => {
      setNews(res.data)
      setNews1(res.data)
    })
    // axios.get(`${URL}/api/base_theme`).then(res => {
    //   setBase(res.data)
    // })

  }, [])


  function newsPage(id) {
    setNewsId(id)
    setPage(1)

    axios.get(`${URL}/api/call_me/read/${id}`).then(res=>{
      setBase(res.data)
    })
  }

  function postNewModal() {
    document.querySelector("#postHelp_Modal").style = "display:flex";
  }
  function postNewClose() {
    document.querySelector("#postHelp_Modal").style = "display:none";
  }

  function putNewModal(id) {
    setNewId(id)
    news.map(item => {
      if (item.id == id) {
        name[1].value = item.title
        description[1].value = item.description

      }
    })
    document.querySelector("#putHelp_Modal").style = "display:flex"
  }
  function putNewClose() {
    document.querySelector("#putHelp_Modal").style = "display:none"
  }

  function deleteNewModal(id) {
    setNewId(id)
    document.querySelector("#deleteHelp_Modal").style = "display:flex"
  }
  function deleteNewClose() {
    document.querySelector("#deleteHelp_Modal").style = "display:none"
  }

  const columns = [
    {
      title: 'Полное имя',
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: 'Электронная почта',
      dataIndex: 'email',
      key: 'email',
    },
    {
    title:'',
    dataIndex:'read',
    key:"read",
    render:(dataIndex)=><div>{dataIndex==false?(<button className='position_notification' style={{backgroundColor:'red',marginLeft:'40px'}}>Непрочитанный</button>):(<button style={{backgroundColor:'#1890ff',marginLeft:'40px'}} className='position_notification'>Прочитано</button>)}</div>
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'balance',
      render: (dataIndex) => <div><button className="user_post_button" onClick={() => newsPage(dataIndex)}>Информация</button>        {(dostup[0].call_me_delete || sessionStorage.getItem("position")==3)?(<button className="user_post_button" onClick={() => deleteNewModal(dataIndex)} >Удалить</button>):(<div></div>)}     </div>,
    },
  ]

  function postNew() {
    var formdata = new FormData()
    formdata.append("title", name[0].value)
    formdata.append("description", description[0].value)
    formdata.append("image", image[0].files[0])


    axios.post(`${URL}/api/help/`, formdata, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      alert("Добавлена информация")
      document.querySelector("#postHelp_Modal").style = "display:none";
      axios.get(`${URL}/api/help/`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
        setNews(res.data)
      })
    }).catch(err => {
      alert("Информация не добавлена")
    })
  }
  function putNew() {
    var formdata = new FormData()
    formdata.append("title", name[1].value)
    formdata.append("description", description[1].value)
    formdata.append("image", image[1].files[0])


    axios.put(`${URL}/api/help/${newId}`, formdata, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      alert("Информация изменена")
      document.querySelector("#putHelp_Modal").style = "display:none";
      axios.get(`${URL}/api/help/`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
        setNews(res.data)
      })
    }).catch(err => {
      alert("Информация не изменилась")
    })
  }
  function deleteNew(){
    axios.delete(`${URL}/api/help/${newId}`,{headers:{Authorization:"Bearer " + sessionStorage.getItem("token")}}).then(res=>{
      alert("Данные удалены")
      document.querySelector("#deleteHelp_Modal").style = "display:none"
      axios.get(`${URL}/api/help/`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
        setNews(res.data)
      })
    }).catch(err=>{
      alert("Данные не удалены")
    })
  }

  function Search(e) {
    const Search = news.filter(item => item.fullname.includes(e.target.value))
    if (e.target.value) {
      setNews(Search)
    } else {
      setNews(news1)
    }
  }


  return (
    <div>
      {page == 1 ? (
        news.map(item => {
          if (item.id == newsId) {
            return (
              <div>
                <div className="exit_button"><button onClick={() => {setPage(0);window.location.reload()}}>Назад</button></div>
                <div className='news_page'>
                  <div style={{ marginTop: '20px', width: '70%' }}>
                    <h1>Полное имя :</h1>
                    <p>{item.fullname}</p>
                    <h1>Электронная почта :</h1>
                    <p>{item.email}</p>
                    <h1>Покупка :</h1>
                    <p>{item.purchase}</p>
                    <h1>Сообщение :</h1>
                    <p>{item.message}</p>
                  </div>
                </div>
              </div>
            )
          }
        })
        ) : (<div><div style={{display:'flex',justifyContent:'end'}} className="search_big_div"><AiOutlineSearch className="search" /><input onChange={Search} placeholder="Введите здесь..." type="text" /></div>
          <Table dataSource={news} columns={columns} />
          </div>)}



      <div id="postHelp_Modal" className="Modaldiv">
        <div className="postUserModal_div" style={{ width: '340px' }}>
          <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={() => postNewClose()} /></div>
          <div style={{ display: 'flex', flexWrap: "wrap" }} className="postUserModal_div_label">
            <label htmlFor="">
              <p>Имя</p>
              <input id="Helpusername" placeholder="Имя" type="text" />
            </label>
            <label htmlFor="">
              <p>Изображение</p>
              <input id="Helpimage" placeholder="Изображение" type="file" />
            </label>
            <label htmlFor="">
              <p>Описание</p>
              <input style={{ paddingBottom: '100px', paddingTop: '10px' }} id="Helpdestcription" placeholder="Описание" type="text" />
            </label>
          </div>
          <div className="postUserModal_div_button">
            <button onClick={() => postNew()}  >Отправить</button>
          </div>
        </div>
      </div>
      <div id="putHelp_Modal" className="Modaldiv">
        <div className="postUserModal_div" style={{ width: '340px' }}>
          <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={() => putNewClose()} /></div>
          <div style={{ display: 'flex', flexWrap: "wrap" }} className="postUserModal_div_label">
            <label htmlFor="">
              <p>Имя</p>
              <input id="Helpusername" type="text" />
            </label>
            <label htmlFor="">
              <p>Изображение</p>
              <input id="Helpimage" type="file" />
            </label>
            <label htmlFor="">
              <p>Описание</p>
              <input style={{ paddingBottom: '100px', paddingTop: '10px' }} id='Helpdestcription' placeholder="Описание" type="text" />
            </label>
          </div>
          <div className="postUserModal_div_button">
            <button onClick={() => putNew()}>Отправить</button>
          </div>
        </div>
      </div>
      <div id="deleteHelp_Modal" className="deleteModal">
        <div className="deleteModal_div">
          <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={() => deleteNewClose()} /></div>
          <img src={deleteImg} alt="" />
          <p>Вы хотите удалить студента</p>
          <div className="deleteButton_div">
            <button onClick={() => deleteNewClose()}>Отмена</button>
            <button onClick={()=>deleteNew()}>Подтвердить</button>
          </div>
        </div>
      </div>
    </div>
  )
}
