import axios from "axios";
import React, { useEffect, useState } from "react";
import URL from "./Host";
import { Table } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import close from "./img/close.png";
import deleteImg from "./img/Inbox cleanup-rafiki.png";

export default function Course_theme() {
  const [course_typeId, setCourse_typeId] = useState();
  const [course_type, setCourse_type] = useState([]);
  const [course_type1, setCourse_type1] = useState([]);
  const [dostup, setDostup] = useState(
    JSON.parse(sessionStorage.getItem("dos"))
  );
  const [checkbox,setCheckbox]=useState(false)


  const username = document.querySelectorAll("#Help_category_eusername");
  const image = document.querySelectorAll("#Help_category_image");

  useEffect(() => {
    axios
      .get(`${URL}/api/help_category/`, {
        headers: {
          Authorization: "Berarer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setCourse_type(res.data);
        setCourse_type1(res.data);
        console.log(res.data);
      });
  }, []);

  function postCourse_type_Modal() {
    document.querySelector("#postHelp_category_Modal").style = "display:flex";
  }

  function postCourse_type_Close() {
    document.querySelector("#postHelp_category_Modal").style = "display:none";
  }

  function putCourse_type_Modal(id) {
    setCheckbox(true)
    setCourse_typeId(id);
    course_type.map((item) => {
      if (item.id === id) {
        username[1].value = item.name;
        setTimeout(() => {
            document.querySelector("#HelpCateCheckbox").checked=true
            document.querySelector("#image_malumotCheckboxHelp_cat1").value=item.image
        }, 100);
      }
    });
    document.querySelector("#putHelp_category_Modal").style = "display:flex";
  }

  function putCourse_type_Close() {
    setCheckbox(false)
    document.querySelector("#putHelp_category_Modal").style = "display:none";
  }

  function deleteCourse_type_Modal(id) {
    setCourse_typeId(id);
    document.querySelector("#deleteHelp_category_Modal").style = "display:flex";
  }

  function deleteCourse_type_Close() {
    document.querySelector("#deleteHelp_category_Modal").style = "display:none";
  }

  const columns = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "username",
    },
    {
      title: "",
      dataIndex: "id",
      key: "balance",
      render: (dataIndex) => (
        <div>
          {dostup[0].category_put || sessionStorage.getItem("position") == 3 ? (
            <button
              onClick={() => putCourse_type_Modal(dataIndex)}
              className="user_post_button"
            >
              Редактировать
            </button>
          ) : (
            <div></div>
          )}{" "}
          {dostup[0].category_delete ||
          sessionStorage.getItem("position") == 3 ? (
            <button
              onClick={() => deleteCourse_type_Modal(dataIndex)}
              className="user_post_button"
            >
              Удалить
            </button>
          ) : (
            <div></div>
          )}{" "}
        </div>
      ),
    },
  ];

  function postCourse_type() {
    var formdata = new FormData();
    formdata.append("name", username[0].value);
    // formdata.append("image", image[0].files[0]);
    checkbox==false?formdata.append("image", document.querySelector("#Help_category_image").files[0]):formdata.append("image", document.querySelector("#image_malumotCheckboxHelp_cat").value)  

    axios
      .post(`${URL}/api/help_category/`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Информация отправлена");
        document.querySelector("#postHelp_category_Modal").style =
          "display:none";
        axios
          .get(`${URL}/api/help_category/`, {
            headers: {
              Authorization: "Berarer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setCourse_type(res.data);
            console.log(res.data);
          });
      })
      .catch((err) => {
        alert("Эта информация включена");
      });
  }

  function putCourse_type() {
    var formdata = new FormData();
    formdata.append("name", username[1].value);
    // formdata.append("image", image[1].files[0]);
    checkbox==false?formdata.append("image", document.querySelector("#Help_category_image1").files[0]):formdata.append("image", document.querySelector("#image_malumotCheckboxHelp_cat1").value)  

    axios
      .put(`${URL}/api/help_category/${course_typeId}`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        setCheckbox(false)
        alert("Информация изменена");
        document.querySelector("#putHelp_category_Modal").style =
          "display:none";
        axios
          .get(`${URL}/api/help_category/`, {
            headers: {
              Authorization: "Berarer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setCourse_type(res.data);
            console.log(res.data);
          });
      })
      .catch((err) => {
        alert("Информация не изменилась");
      });
  }

  function deleteCourse_type() {
    axios
      .delete(`${URL}/api/help_category/${course_typeId}`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        document.querySelector("#deleteHelp_category_Modal").style =
          "display:none";
        alert("Данные удалены");
        axios
          .get(`${URL}/api/help_category/`, {
            headers: {
              Authorization: "Berarer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setCourse_type(res.data);
            console.log(res.data);
          });
      })
      .catch((err) => {
        alert("Данные не удалены");
      });
  }

  function Search(e) {
    const Search = course_type.filter((item) =>
      item.name.includes(e.target.value)
    );
    if (e.target.value) {
      setCourse_type(Search);
    } else {
      setCourse_type(course_type1);
    }
  }

  
  function Checkbox(checked1){
    setCheckbox(checked1)
    if(checked1==true){
        course_type.map((item) => {
            if (item.id === course_typeId) {
              setTimeout(() => {
                  document.querySelector("#image_malumotCheckboxHelp_cat1").value=item.image
              }, 100);
            }
          });
    }
  }

  return (
    <div>
      <h1 style={{ marginTop: "100px" }}>Категория справки</h1>
      <div className="search_big_div">
        {dostup[0].category_post || sessionStorage.getItem("position") == 3 ? (
          <button
            onClick={() => postCourse_type_Modal()}
            className="user_post_button"
          >
            Добавить
          </button>
        ) : (
          <div></div>
        )}
        <div style={{ position: "relative" }}>
          <AiOutlineSearch className="search" />
          <input onChange={Search} placeholder="Введите здесь..." type="text" />
        </div>
      </div>
      <Table dataSource={course_type} columns={columns} />

      <div id="postHelp_category_Modal" className="Modaldiv">
        <div className="postUserModal_div" style={{ width: "340px" }}>
          <div className="postUserModal_div_icon">
            <img
              src={close}
              className="icon"
              onClick={() => postCourse_type_Close()}
            />
          </div>
          <div
            style={{ display: "flex", flexWrap: "wrap" }}
            className="postUserModal_div_label"
          >
            <label htmlFor="">
              <p>Имя</p>
              <input
                id="Help_category_eusername"
                placeholder="Имя"
                type="text"
              />
            </label>
            <label htmlFor="">
              <p>
                Изображение{" "}
                <input
                  type="checkbox"
                  id="tablesCheckbox"
                  onClick={(e) => Checkbox(e.target.checked)}
                  style={{ width: "15px", height: "15px" }}
                />
              </p>
              {checkbox == false ? (
                <input id="Help_category_image" type="file" />
              ) : (
                <input id="image_malumotCheckboxHelp_cat" type="text" />
              )}
            </label>
          </div>
          <div className="postUserModal_div_button">
            <button onClick={() => postCourse_type()}>Отправить</button>
          </div>
        </div>
      </div>
      <div id="putHelp_category_Modal" className="Modaldiv">
        <div className="postUserModal_div" style={{ width: "340px" }}>
          <div className="postUserModal_div_icon">
            <img
              src={close}
              className="icon"
              onClick={() => putCourse_type_Close()}
            />
          </div>
          <div
            style={{ display: "flex", flexWrap: "wrap" }}
            className="postUserModal_div_label"
          >
            <label htmlFor="">
              <p>Имя</p>
              <input id="Help_category_eusername" type="text" />
            </label>
            <label htmlFor="">
              <p>
                Изображение{" "}
                <input
                  type="checkbox"
                  id="HelpCateCheckbox"
                  onClick={(e) => Checkbox(e.target.checked)}
                  style={{ width: "15px", height: "15px" }}
                />
              </p>
              {checkbox == false ? (
                <input id="Help_category_image1" type="file" />
              ) : (
                <input id="image_malumotCheckboxHelp_cat1" type="text" />
              )}
            </label>
          </div>
          <div className="postUserModal_div_button">
            <button onClick={() => putCourse_type()}>Отправить</button>
          </div>
        </div>
      </div>
      <div id="deleteHelp_category_Modal" className="deleteModal">
        <div className="deleteModal_div">
          <div className="postUserModal_div_icon">
            <img
              src={close}
              className="icon"
              onClick={() => deleteCourse_type_Close()}
            />
          </div>
          <img src={deleteImg} alt="" />
          <p>Вы хотите удалить студента</p>
          <div className="deleteButton_div">
            <button onClick={() => deleteCourse_type_Close()}>Отмена</button>
            <button onClick={() => deleteCourse_type()}>Подтвердить</button>
          </div>
        </div>
      </div>
    </div>
  );
}
