import axios from "axios";
import React, { useEffect, useState } from "react";
import URL from "./Host";
import { MdEmail } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import close from "./img/close.png";
import deleteImg from "./img/Inbox cleanup-rafiki.png";
import { BsTelephoneFill } from "react-icons/bs";

export default function Operator() {
  const [partner, setPartner] = useState([]);
  const [operator_work, setOperator_work] = useState([]);
  const [operatorId, setOperatorId] = useState([]);
  const [page, setPage] = useState(0);
  const [pageID, setPageID] = useState(0);
  const [checkbox,setCheckbox]=useState(false)

  const image = document.querySelectorAll("#OperatorImage");
  const address = document.querySelectorAll("#OperatorDescription");
  const email = document.querySelectorAll("#OperatorEmail");
  const twiter = document.querySelectorAll("#OperatorTwiter");
  const call_me = document.querySelectorAll("#OperatorCall_me");
  const WhatsApp = document.querySelectorAll("#OperatorWhatsapp");

  useEffect(() => {
    axios
      .get(`${URL}/api/company`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        setPartner(res.data);
      });
  }, []);

  function deleteCompany() {
    axios
      .delete(`${URL}/api/company/${operatorId}`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Оператор удален");
        document.querySelector("#deleteCompany").style = "display:none";
        axios
          .get(`${URL}/api/company`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setPartner(res.data);
          });
      })
      .catch((err) => {
        alert("Не удалось удалить оператора");
      });
  }

  function postCompany() {
    var formdata = new FormData();
    // formdata.append("image", image[0].files[0]);
    checkbox==false?formdata.append("image",document.querySelector("#OperatorImage").files[0]):formdata.append("image",document.querySelector("#image_malumotCheckboxOperator").value)
    formdata.append("address", address[0].value);
    formdata.append("email", email[0].value);
    formdata.append("twiter", twiter[0].value);
    formdata.append("call_me", call_me[0].value);
    formdata.append("whatsapp", WhatsApp[0].value);

    axios
      .post(`${URL}/api/company`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Оператор добавлен");
        document.querySelector("#postCompany").style = "display:none";
        axios
          .get(`${URL}/api/company`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setPartner(res.data);
          });
      })
      .catch((err) => {
        alert("Оператор не добавлен");
      });
  }

  function putCompany() {
    var formdata = new FormData();
    // formdata.append("image", image[1].files[0]);
    checkbox==false?formdata.append("image",document.querySelector("#OperatorImage1").files[0]):formdata.append("image",document.querySelector("#image_malumotCheckboxOperator1").value)
    formdata.append("address", address[1].value);
    formdata.append("email", email[1].value);
    formdata.append("twiter", twiter[1].value);
    formdata.append("call_me", call_me[1].value);
    formdata.append("whatsapp", WhatsApp[1].value);

    axios
      .put(`${URL}/api/company/${operatorId}`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Сменился оператор");
        setCheckbox(false)
        document.querySelector("#putCompany").style = "display:none";
        axios
          .get(`${URL}/api/company`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setPartner(res.data);
          });
      })
      .catch((err) => {
        alert("Оператор не менялся");
      });
  }

  function postCompanyOpen() {
    document.querySelector("#postCompany").style = "display:flex";
  }

  function postCompanyClose() {
    document.querySelector("#postCompany").style = "display:none";
  }

  function putCompanyOpen(
    id,
    name1,
    deckription1,
    email1,
    twiter1,
    call_me1,
    whatsapp1,
    image
  ) {
    setCheckbox(true)
    setOperatorId(id);
    address[1].value = deckription1;
    email[1].value = email1;
    twiter[1].value = twiter1;
    call_me[1].value = call_me1;
    WhatsApp[1].value = whatsapp1;
    setTimeout(() => {
      document.querySelector("#image_malumotCheckboxOperator1").value=image
      document.querySelector("#checkboxOperator").checked=true

    }, 100);
    document.querySelector("#putCompany").style = "display:flex";
  }

  function putCompanyClose() {
    setCheckbox(false)
    document.querySelector("#putCompany").style = "display:none";
  }

  function deleteCompanyOpen(id) {
    setOperatorId(id);
    document.querySelector("#deleteCompany").style = "display:flex";
  }

  function deleteCompanyClose() {
    document.querySelector("#deleteCompany").style = "display:none";
  }


  function Checkbox(checked1){
    setCheckbox(checked1)
    if(checked1==true){
      partner.map(item => {
        if (item.id == operatorId) {
          setTimeout(() => {
            document.querySelector("#image_malumotCheckboxOperator1").value=item.image
          }, 100);
    
        }
      })
    }
  }



  return (
    <div>

        <div>
          <button
            onClick={() => postCompanyOpen()}
            className="user_post_button_malumot"
          >
            Добавлять
          </button>
          <div 
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              flexWrap: "wrap",
            }}
          >
            {partner.map((item) => {
              return (
                <div id="cards11" className="cards">
                  <div className="forsa_img">
                    <img style={{ maxWidth: "none" }} src={item.image} alt="" />
                  </div>
                  <p>{item.address}</p>
                  <div className="two">
                    <div className="icon">
                      {/* <a style={{color:"black"}} href={{mailto:${item.twiter}}}><div className="dumalo"><GrFacebookOption/></div></a> */}
                      <a style={{ color: "black" }} href={item.whatsapp}>
                        <div className="dumalo">
                          <IoLogoWhatsapp />
                        </div>
                      </a>
                      <a
                        style={{ color: "black" }}
                        href={`tel:${item.call_me}`}
                      >
                        <div className="dumalo">
                          <BsTelephoneFill />
                        </div>
                      </a>
                      <a
                        style={{ color: "black" }}
                        href={`mailto:${item.email}`}
                      >
                        <div className="dumalo">
                          <MdEmail />
                        </div>
                      </a>
                    </div>
                    <div
                      style={{ display: "flex", gap: "10px" }}
                      className="view"
                    >
                      <h4
                        onClick={() => deleteCompanyOpen(item.id)}
                        style={{ color: "red" }}
                      >
                        delete
                      </h4>
                      <h4
                        onClick={() =>
                          putCompanyOpen(
                            item.id,
                            item.name,
                            item.address,
                            item.email,
                            item.twiter,
                            item.call_me,
                            item.whatsapp,
                            item.image
                          )
                        }
                      >
                        edit
                      </h4>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div id="postCompany" className="Modaldiv">
            <div className="postUserModal_div">
              <div className="postUserModal_div_icon">
                <img
                  src={close}
                  className="icon"
                  onClick={() => postCompanyClose()}
                />
              </div>
              <div
                style={{ display: "flex", flexWrap: "wrap" }}
                className="postUserModal_div_label"
              >
                <label htmlFor="">
                  <p>Изображение     <input type="checkbox"  onClick={(e)=>Checkbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
                  {checkbox==false?(<input  id="OperatorImage" type="file" />):(<input id="image_malumotCheckboxOperator" type="text" />)}
                </label>
                <label htmlFor="">
                  <p>Адрес</p>
                  <input id="OperatorDescription" type="text" />
                </label>
                <label htmlFor="">
                  <p>Электронная почта</p>
                  <input id="OperatorEmail" type="text" />
                </label>
                <label htmlFor="">
                  <p>Твиттер</p>
                  <input id="OperatorTwiter" type="text" />
                </label>
                <label htmlFor="">
                  <p>Позвоните мне</p>
                  <input id="OperatorCall_me" type="text" />
                </label>
                <label htmlFor="">
                  <p>WhatsApp</p>
                  <input id="OperatorWhatsapp" type="text" />
                </label>
              </div>
              <div className="postUserModal_div_button">
                <button onClick={() => postCompany()}>Отправить</button>
              </div>
            </div>
          </div>
          <div id="putCompany" className="Modaldiv">
            <div className="postUserModal_div" >
              <div className="postUserModal_div_icon">
                <img
                  src={close}
                  className="icon"
                  onClick={() => putCompanyClose()}
                />
              </div>
              <div
                style={{ display: "flex", flexWrap: "wrap" }}
                className="postUserModal_div_label"
              >
                <label htmlFor="">
                  <p>Изображение     <input type="checkbox" id='checkboxOperator'  onClick={(e)=>Checkbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
                  {checkbox==false?(<input  id="OperatorImage1" type="file" />):(<input id="image_malumotCheckboxOperator1" type="text" />)}
                </label>
                <label htmlFor="">
                  <p>Адрес</p>
                  <input id="OperatorDescription" type="text" />
                </label>
                <label htmlFor="">
                  <p>Электронная почта</p>
                  <input id="OperatorEmail" type="text" />
                </label>
                <label htmlFor="">
                  <p>Твиттер</p>
                  <input id="OperatorTwiter" type="text" />
                </label>
                <label htmlFor="">
                  <p>Позвоните мне</p>
                  <input id="OperatorCall_me" type="text" />
                </label>
                <label htmlFor="">
                  <p>WhatsApp</p>
                  <input id="OperatorWhatsapp" type="text" />
                </label>
              </div>
              <div className="postUserModal_div_button">
                <button onClick={() => putCompany()}>Отправить</button>
              </div>
            </div>
          </div>
          <div id="deleteCompany" className="deleteModal">
            <div className="deleteModal_div">
              <div className="postUserModal_div_icon">
                <img
                  src={close}
                  className="icon"
                  onClick={() => deleteCompanyClose()}
                />
              </div>
              <img src={deleteImg} alt="" />
              <p>Вы хотите удалить Компания</p>
              <div className="deleteButton_div">
                <button onClick={() => deleteCompanyClose()}>Отмена</button>
                <button onClick={() => deleteCompany()}>Подтвердить</button>
              </div>
            </div>
          </div>
        </div>
    
    </div>
  );
}
