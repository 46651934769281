import { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
  Switch,
  Upload,
  message,
} from "antd";
import close from "./img/close.png"
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import {BsTelegram,BsYoutube} from 'react-icons/bs'

import BgProfile from "../assets/images/bg-profile.jpg";
import profilavatar from "../assets/images/face-1.jpg";
import convesionImg from "../assets/images/face-3.jpg";
import convesionImg2 from "../assets/images/face-4.jpg";
import convesionImg3 from "../assets/images/face-5.jpeg";
import convesionImg4 from "../assets/images/face-6.jpeg";
import convesionImg5 from "../assets/images/face-2.jpg";
import project1 from "../assets/images/home-decor-1.jpeg";
import project2 from "../assets/images/home-decor-2.jpeg";
import project3 from "../assets/images/home-decor-3.jpeg";
import axios from "axios"
import URL from "./Host"

function Profile() {
  const [imageURL, setImageURL] = useState(false);
  const [, setLoading] = useState(false);
  const [superAdmin, setSuperAdmin] = useState([])
  const [id,setId]=useState()
  const [balance,setbalance]=useState()
  const [position,setPosition]=useState()
  const [checkbox,setCheckbox]=useState(false)
  const [superId,setSuperId]=useState()


  const username=document.querySelectorAll("#Superusername")
  const last_name=document.querySelectorAll("#Superlast_name")
  const phone_number=document.querySelectorAll("#Superphone_number")
  const email=document.querySelectorAll("#Superemail")
  const address=document.querySelectorAll("#Superaddress")
  const password=document.querySelectorAll("#Superpassword")
  const image=document.querySelectorAll("#Superimage")
  const description=document.querySelectorAll("#Superdescription")


  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(false);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
        setImageURL(false);
      });
    }
  };

  useEffect(() => {
    axios.get(`${URL}/auth/oneuser`, { headers: { Authorization: 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
      setSuperAdmin(res.data)
      console.log(res.data,"sa");
      res.data.map(item=>{
        setId(item.id)
        setbalance(item.balance)
        setPosition(item.position)
      })
    })
  }, [])

  function putSuperAdmin(){
    var formdata=new FormData()
    formdata.append("username",username[0].value)
    formdata.append("last_name",last_name[0].value) 
    formdata.append("address",address[0].value)
    formdata.append("description",description[0].value)
    formdata.append("password",password[0].value)
    formdata.append("email",email[0].value)
    // formdata.append("image",image[0].files[0])
    checkbox==false?formdata.append("image",document.querySelector("#Superimage").files[0]):formdata.append("image",document.querySelector("#image_malumotCheckboxSuper").value)
    formdata.append("balance",balance)
    formdata.append("position",position)
    formdata.append("phone_number",phone_number[0].value)
    formdata.append("youtobe",document.querySelector("#Superyoutube").value)
    formdata.append("telegram",document.querySelector("#Supertelegram").value)
    formdata.append("instagram",document.querySelector("#Superinstagram").value)

    axios.put(`${URL}/auth/userssuperadmin/${id}`,formdata,{headers:{Authorization:"Bearer " + sessionStorage.getItem("token")}}).then(res=>{
    alert("Информация изменилась")
    document.querySelector('#putSuperAdmin').style="display:none"
    axios.get(`${URL}/auth/oneuser`, { headers: { Authorization: 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
      setSuperAdmin(res.data)
    })
    }).catch(err=>{
      alert("Информация не изменилась")
    })

  }

  function putSuperAdminModal(id){
    setCheckbox(true)
    setSuperId(id)
    superAdmin.map(item=>{
      if (item.id==id) {
      username[0].value=item.username
      last_name[0].value=item.last_name
      phone_number[0].value=item.phone_number
      email[0].value=item.email
      address[0].value=item.address
      password[0].value=item.password
      description[0].value=item.description
      document.querySelector("#Superyoutube").value=item.youtobe
      document.querySelector("#Supertelegram").value=item.telegram
      document.querySelector("#Superinstagram").value=item.instagram
      setTimeout(() => {
        document.querySelector("#checkboxSuper").checked=true
      document.querySelector("#image_malumotCheckboxSuper").value=item.image
      }, 100); 
    }
    })
    document.querySelector('#putSuperAdmin').style="display:flex"
  }

  function putSuperAdminClose(){
    document.querySelector('#putSuperAdmin').style="display:none"
  }

  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];

  const uploadButton = (
    <div className="ant-upload-text font-semibold text-dark">
      {<VerticalAlignTopOutlined style={{ width: 20, color: "#000" }} />}
      <div>Upload New Project</div>
    </div>
  );

  const data = [
    {
      title: "Sophie B.",
      avatar: convesionImg,
      description: "Hi! I need more information…",
    },
    {
      title: "Anne Marie",
      avatar: convesionImg2,
      description: "Awesome work, can you…",
    },
    {
      title: "Ivan",
      avatar: convesionImg3,
      description: "About files I can…",
    },
    {
      title: "Peterson",
      avatar: convesionImg4,
      description: "Have a great afternoon…",
    },
    {
      title: "Nick Daniel",
      avatar: convesionImg5,
      description: "Hi! I need more information…",
    },
  ];

  const project = [
    {
      img: project1,
      titlesub: "Project #1",
      title: "Modern",
      disciption:
        "As Uber works through a huge amount of internal management turmoil.",
    },
    {
      img: project2,
      titlesub: "Project #2",
      title: "Scandinavian",
      disciption:
        "Music is something that every person has his or her own specific opinion about.",
    },
    {
      img: project3,
      titlesub: "Project #3",
      title: "Minimalist",
      disciption:
        "Different people have different taste, and various types of music, Zimbali Resort",
    },
  ];

  function Checkbox(checked1){
    setCheckbox(checked1)
    if(checked1==true){
      superAdmin.map(item=>{
        if (item.id==superId) {
        setTimeout(() => {
        document.querySelector("#image_malumotCheckboxSuper").value=item.image
        }, 100); 
      }
      })
    }
  }
  

  return (
    <div>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>

      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row  justify="space-between" align="middle" gutter={[24, 0]}>
            <Col style={{flex:'100%',width:'100%',maxWidth:'100%'}} span={24} md={12} className="col-info">
             {superAdmin.map(item => { 
             return <Avatar.Group style={{width:'100%'}}>
                <Avatar size={74} shape="square" src={item.image} />
                <div style={{display:'flex',alignItems:'start',justifyContent:'space-between',width:'100%'}}>
                  <div className="avatar-info">
                    <h4 className="font-semibold m-0">{item.username}</h4>
                    <p>Суперадмин</p>
                  </div>
                  <div><span style={{marginBottom:'0px'}}>Balance:</span><h1 style={{fontSize:'25px',fontWeight:'900'}}>{item.balance}</h1></div>               
                  </div>
              </Avatar.Group>
            })}
            </Col>
            {/* <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Radio.Group defaultValue="a">
                <Radio.Button value="a">OVERVIEW</Radio.Button>
                <Radio.Button value="b">TEAMS</Radio.Button>
                <Radio.Button value="c">PROJECTS</Radio.Button>
              </Radio.Group>
            </Col> */}
          </Row>
        }
      ></Card>

      <Row gutter={[24, 0]}>
        <Col  span={24} md={8} id="mb-24" className="mb-24">
          {superAdmin.map(item=>{
            return(
              <Card
              bordered={false}
              title={<h6 className="font-semibold m-0">Информация профиля</h6>}
              className="header-solid h-full card-profile-information"
              extra={<Button onClick={()=>putSuperAdminModal(item.id)} type="link">{pencil}</Button>}
              bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            >
              <p className="text-dark">
                {item.description}
              </p>
              <hr className="my-25" />
              <Descriptions title={item.username}>
                <Descriptions.Item label="Фамилия" span={3}>
                  {item.last_name}
                </Descriptions.Item>
                <Descriptions.Item label="Мобильный" span={3}>
                  {item.phone_number}
                </Descriptions.Item>
                <Descriptions.Item label="Электронная почта" span={3}>
                  {item.email}
                </Descriptions.Item>
                <Descriptions.Item label="Общественный" span={3}>
                  <a href={item.youtobe} style={{ color: "red" }} className="mx-5 px-5">
                    {<BsYoutube />}
                  </a>
                  <a href={item.telegram} className="mx-5 px-5">
                    {<BsTelegram style={{ color: "#344e86" }} />}
                  </a>
                  <a href={item.instagram} className="mx-5 px-5">
                    {<InstagramOutlined style={{ color: "#e1306c" }} />}
                  </a>
                </Descriptions.Item>
              </Descriptions>
            </Card>
            )
          })}
        </Col>
      
      </Row>
      {/* <Card
        bordered={false}
        className="header-solid mb-24"
        title={
          <div>
            <h6 className="font-semibold">Projects</h6>
            <p>Architects design houses</p>
          </div>
        }
      >
        <Row gutter={[24, 24]}>
          {project.map((p, index) => (
            <Col span={24} md={12} xl={6} key={index}>
              <Card
                bordered={false}
                className="card-project"
                cover={<img alt="example" src={p.img} />}
              >
                <div className="card-tag">{p.titlesub}</div>
                <h5>{p.titile}</h5>
                <p>{p.disciption}</p>
                <Row gutter={[6, 0]} className="card-footer">
                  <Col span={12}>
                    <Button type="button">VIEW PROJECT</Button>
                  </Col>
                  <Col span={12} className="text-right">
                    <Avatar.Group className="avatar-chips">
                      <Avatar size="small" src={profilavatar} />
                      <Avatar size="small" src={convesionImg} />
                      <Avatar size="small" src={convesionImg2} />
                      <Avatar size="small" src={convesionImg3} />
                    </Avatar.Group>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
          <Col span={24} md={12} xl={6}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader projects-uploader"
              showUploadList={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageURL ? (
                <img src={imageURL} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Col>
        </Row>
      </Card> */}

      <div id="putSuperAdmin" className="Modaldiv">
      <div className="postUserModal_div">
      <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={()=>putSuperAdminClose()}/></div>
         <div className="postUserModal_div_label">
        <label htmlFor="">
          <p>Имя пользователя</p>
          <input id="Superusername"  type="text" />
        </label>
        <label htmlFor="">
          <p>Фамилия</p>
          <input id="Superlast_name"  type="text" />
        </label>
        <label htmlFor="">
          <p>Телефон номер</p>
          <input id="Superphone_number"  type="text" />
        </label>
        <label htmlFor="">
          <p>Электронная почта</p>
          <input id="Superemail"  type="text" />
        </label>
        <label htmlFor="">
          <p>Адрес</p>
          <input id="Superaddress"  type="text" />
        </label>
        <label htmlFor="">
          <p>Пароль</p>
          <input id="Superpassword"  type="text" />
        </label>
        <label htmlFor="">
          <p>Изображение     <input type="checkbox" id='checkboxSuper'  onClick={(e)=>Checkbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
          {checkbox==false?(<input  id="Superimage" type="file" />):(<input id="image_malumotCheckboxSuper" type="text" />)}
        </label>
        <label htmlFor="">
        <p>YouTube link</p>
        <input id="Superyoutube"  type="text" />
      </label>
      <label htmlFor="">
      <p>Telegram link</p>
      <input id="Supertelegram"  type="text" />
    </label>
    <label htmlFor="">
    <p>Instagram Link</p>
    <input id="Superinstagram"  type="text" />
  </label>
        </div>
        <label style={{width:'100%'}} htmlFor="">
          <p>Описание</p>
          <textarea id="Superdescription"  className="description" type="text" />
        </label>
        <div className="postUserModal_div_button">
        <button onClick={()=>putSuperAdmin()}>Редактировать</button>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Profile;
