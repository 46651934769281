import React from 'react'

export default function Qurilmalar() {
  return (
    <div>
      <div className="qurilma_big_div">
      <h1>1.В этом видео вы узнаете о видеочате</h1>
      <iframe  src="https://www.youtube.com/embed/EukhMNNosXw?si=cDgn5Dlz7J3FMfqu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div className="qurilma_big_div">
      <h1>2.В этом видео вы узнаете о Stripe.</h1>
      <iframe  src="https://www.youtube.com/embed/YTc0Zi70AjM?si=a7mRFea34HnpcYzq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <div className="qurilma_big_div">
      <h1>3.В этом видео вы узнаете о PayPal</h1>
      <iframe  src="https://www.youtube.com/embed/0oo3d06LH0M?si=vxTW48-6a1o13qhL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </div>
  )
}
