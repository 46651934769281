
import axios from "axios";
import URL from "../../../pages/Host";


  var diagramma=[]

  axios.get(`${URL}/pay/payment`,{headers:{Authorization:"Bearer "+sessionStorage.getItem("token")}}).then(res=>{
    
    for (let i = 0; i < 12; i++) {
     diagramma.push(0)
    for (let j = 0; j < res.data.length; j++) {
     if ((res.data[j].time_create).slice(5,7)==`${i}`.padStart(2,"0")) {
      diagramma[i]=diagramma[i]+res.data[j].amout*1
     }
    }
    }
    
  })

const eChart = {
    series: [
    {
      name: "Деньги",
      data: diagramma,
      color: "#fff",
    }, 
    ],

  options: {
    chart: {
      type: "bar",
      width: "100%",
      height: "auto",

      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: [
            "#fff",
            "#fff",
            "#fff",
            "#fff",
            "#fff",
            "#fff",
            "#fff",
            "#fff",
            "#fff",
            "#fff",
            "#fff",
            "#fff",
          ],
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: [
            "#fff",
            "#fff",
            "#fff",
            "#fff",
            "#fff",
            "#fff",
            "#fff",
            "#fff",
            "#fff",
            "#fff",
          ],
        },
      },
    },

    tooltip: {
      y: {
        formatter: function (val,index) {
          // window.location.reload()
          localStorage.setItem("diagrammaAmout",val)
          localStorage.setItem("diagrammaDate",index.dataPointIndex)
          return "$ " + val + " Тысячи";
        },
      },
    },
  },
};

export default eChart;
