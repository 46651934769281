import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import Teacher from "./pages/Teacher";
import Course from "./pages/Course"
import Student from './pages/Student'
import Main from "./components/layout/Main";
import Admin from "./pages/Admin"
import Theme from "./pages/Course_category"
import Knowladge from "./pages/knowladge"
import Test from "./pages/Test"
import Feedback from "./pages/feedback"
import Help from "./pages/Help"
import Edication from "./pages/Edication"
import Calendar from "./pages/ReactBigCalendar"
import Mynotification from "./pages/Mynotification"
import Notification from "./pages/Notification";
import Chat from "./pages/userChat";
import AllChat from "./pages/AllChatSelecet";
import Universtate from "./pages/Unuvercity"
import Task_Comment from "./pages/Course_task_comment"
import VideoChat from "./pages/join"
import Video from "./pages/metting"
import Qurilmalar from "./pages/Qurilmalar";
import Servis from "./pages/Srevices";
import Partner from "./pages/Homiy";
import Sertificat from "./pages/Sertificat";
import Operator from "./pages/Operator";
import Company from "./pages/Company";
import Languange from "./pages/Languange";

import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { useEffect, useState } from "react";
import axios from "axios";
import URL from "./pages/Host";
import { Alert } from "antd";

function App() {
  const [token, setToken] = useState(sessionStorage.getItem("token"))
  const [dostup, setDostup] = useState(JSON.parse(sessionStorage.getItem('dos')))
  const [position, setPosition] = useState(sessionStorage.getItem("position"))
  const onClose = (e) => {
    console.log(e, 'I was closed.');
  };

  return (
    <div className="App" >
      <BrowserRouter>
        <Switch>
          {token ? (
            (dostup.length == 1 || sessionStorage.getItem("position") == 3) ? (
              <Main >
                {/* <Route exact path="/" component={Home} /> */}
                <Route  exact path="/operator" component={Operator} />
                <Route  exact path="/company" component={Company} />
                <Route  exact path="/languange" component={Languange} />
                {(dostup[0].create_video||sessionStorage.getItem("position") == 3)?(<Route  exact path="/themes" component={Task_Comment} />):(<div></div>)}
                {(dostup[0].create_video||sessionStorage.getItem("position") == 3)?(<Route  exact path="/videochat" component={VideoChat} />):(<div></div>)}
                {(dostup[0].create_video||sessionStorage.getItem("position") == 3)?( <Route  exact path="/video/:id" component={Video} />):(<div></div>)}
                {(dostup[0].pomish||sessionStorage.getItem("position") == 3)?(<Route  exact path="/devices" component={Qurilmalar} />):(<div></div>)}
                {(dostup[0].servis_get||sessionStorage.getItem("position") == 3)?(<Route  exact path="/servis" component={Servis} />):(<div></div>)}
                {(dostup[0].homiy_get||sessionStorage.getItem("position") == 3)?(<Route  exact path="/partner" component={Partner} />):(<div></div>)}
                {(dostup[0].sertifikat_get||sessionStorage.getItem("position") == 3)?(<Route  exact path="/sertificat" component={Sertificat} />):(<div></div>)}
                {(dostup[0].admin_get || sessionStorage.getItem("position") == 3) ? (<Route exact path="/admin" component={Admin} />) : (<div></div>)}
                {(dostup[0].universitet_get || sessionStorage.getItem("position") == 3)?(<Route exact path="/university" component={Universtate} />):(<div></div>)}
                {(dostup[0].dars_get || sessionStorage.getItem("position") == 3)?(<Route exact path="/edication" component={Edication} />):(<div></div>)}
                {(dostup[0].get_allchat || sessionStorage.getItem("position") == 3)?(<Route exact path="/allchat" component={AllChat} />):(<div></div>)}
                {(dostup[0].chat_get || sessionStorage.getItem("position") == 3)?(<Route exact path="/chat" component={Chat} />):(<div></div>)}
                {(dostup[0].notification_get || sessionStorage.getItem("position") == 3)?(<Route exact path="/mynotification" component={Mynotification} />):(<div></div>)}
                {(dostup[0].test_get || sessionStorage.getItem("position") == 3)?(<Route exact path="/test" component={Test} />):(<div></div>)}
                {(dostup[0].notification_get || sessionStorage.getItem("position") == 3)?(<Route exact path="/notification" component={Notification} />):(<div></div>)}
                {(dostup[0].help_get || sessionStorage.getItem("position") == 3)?(<Route exact path="/help" component={Help} />):(<div></div>)}
                {(dostup[0].call_me_get || sessionStorage.getItem("position") == 3)?(<Route exact path="/feedback" component={Feedback} />):(<div></div>)}
                {(dostup[0].news_get || sessionStorage.getItem("position") == 3)?(<Route exact path="/news" component={Knowladge} />):(<div></div>)}
                {(dostup[0].diogram_get || sessionStorage.getItem("position") == 3)?(<Route exact path="/dashboard" component={Home} />):(<div></div>)}
                {(dostup[0].course_get || sessionStorage.getItem("position") == 3)?(<Route exact path="/theme" component={Theme} />):(<div></div>)}
                {(dostup[0].alluser_get || sessionStorage.getItem("position") == 3)?(<Route exact path="/user" component={Tables} />):(<div></div>)}
                {(dostup[0].get_pay || sessionStorage.getItem("position") == 3)?(<Route exact path="/billing" component={Billing} />):(<div></div>)}
                {(dostup[0].course_get || sessionStorage.getItem("position") == 3)?(<Route exact path="/course" component={Course} />):(<div></div>)}
                {(dostup[0].teacher_get || sessionStorage.getItem("position") == 3)?(<Route exact path="/teacher" component={Teacher} />):(<div></div>)}
                {(dostup[0].student_get || sessionStorage.getItem("position") == 3)?(<Route exact path="/student" component={Student} />):(<div></div>)}
                <Route exact path="/profile" component={Profile} />
                {/* <Redirect from="*" to="/dashboard" /> */}
              </Main>
            ) : (
              <div>
                <Alert
                  message="Error Text"
                  description="Sizga hech qanday ruxsat topilmadi"
                  type="error"
                  closable
                  onClose={onClose}
                />
                <Redirect from="*" to="/" />
                <Route path="/" exact component={SignIn} />
              </div>
            )
          ) : (<Route path="/" exact component={SignIn} />)}

        </Switch></BrowserRouter>
    </div>
  );
}

export default App;
