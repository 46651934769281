import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography } from "antd";
import eChart from "./configs/eChart";
import { useEffect, useState } from "react";
import axios from "axios";
import URL from "../../pages/Host";

function EChart() {
  const { Title, Paragraph } = Typography;
  const [user,setUser]=useState(0)
  const [course,setCourse]=useState(0)


  useEffect(()=>{
  axios.get(`${URL}/auth/users`,{headers:{Authorization:"Bearer "+sessionStorage.getItem("token")}}).then(res=>{
    const Filter=res.data.filter(item=>(item.time_create).slice(5,7)==localStorage.getItem("diagrammaDate").padStart(2,"0"))
    setUser(Filter.length)
  })
  axios.get(`${URL}/api/super/registerCourse`,{headers:{Authorization:"Bearer "+sessionStorage.getItem("token")}}).then(res=>{
    const Filter=res.data.filter(item=>(item.time_create).slice(5,7)==localStorage.getItem("diagrammaDate").padStart(2,"0"))
    setCourse(Filter.length)
  })

  },[])

  const items = [
    {
      Title: user,
      user: "Пользователь",
    },
    {
      Title: course,
      user: "Курс распродан",
    },
    {
      Title: `${localStorage.getItem("diagrammaAmout")?localStorage.getItem("diagrammaAmout"):0}$`,
      user: "Деньги",
    },
  ];

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          className="bar-chart"
          options={eChart.options}
          series={eChart.series}
          type="bar"
          height={220}
        />
      </div>
      {/*<div  className="chart-vistior">
        <Title level={5}>Ежемесячные данные</Title>
        <Paragraph className="lastweek">
        Чем в прошлом месяце
        </Paragraph>
        <Paragraph className="lastweek">
        Здесь вы можете увидеть изменения по сравнению с прошлым месяцем.
        </Paragraph>
        <Row gutter>
          {items.map((v, index) => (
            <Col style={{width:'100%',maxWidth:'100%',flex:1}} xs={6} xl={6} sm={6} md={6} key={index}>
              <div className="chart-visitor-count">
                <Title level={4}>{v.Title}</Title>
                <span>{v.user}</span>
              </div>
            </Col>
          ))}
        </Row>
          </div>*/}
    </div>
  );
}

export default EChart;
