import axios from 'axios'
import React, { useEffect, useState } from 'react'
import URL from './Host'
import { Table } from "antd";
import { AiOutlineSearch } from "react-icons/ai"
import close from "./img/close.png"
import Task from "./Course_task_comment"
import deleteImg from "./img/Inbox cleanup-rafiki.png"


export default function Course_category() {

    const [course_typeId,setCourse_typeId]=useState()
    const [course_type, setCourse_type] = useState([])
    const [course_type1, setCourse_type1] = useState([])
    const [course,setCourse] = useState([])
    const [page,setPage]=useState(0)
    const [checkbox,setCheckbox]=useState(false)
    const [Videocheckbox,setVideoCheckbox]=useState(false)


    const username=document.querySelectorAll("#Themeusername")
    const content=document.querySelectorAll("#Themecontent")
    const image=document.querySelectorAll("#Themeimage")
    const video=document.querySelectorAll("#Themevideo")
    const extra_data=document.querySelectorAll("#Themeextra_data")


    useEffect(() => {
        axios.get(`${URL}/api/course_data_theme`, { headers: { Authorization: "Berarer " + sessionStorage.getItem("token") } }).then(res => {
            const CourseTheme=res.data.filter(item=>item.category==localStorage.getItem("CourseCategory"))
            setCourse_type(CourseTheme)
            setCourse_type1(CourseTheme)
            console.log(res.data);
        })
        axios.get(`${URL}/api/course_data_category`,{headers:{Authorization:"Bearer " + sessionStorage.getItem("token")}}).then(res=>{
            const CourseCategory=res.data.filter(item=>item.course==localStorage.getItem("Course"))
            setCourse(CourseCategory) 
        })
    }, [])

    function postCourse_type_Modal() {
        document.querySelector("#postCourse_theme_Modal").style = "display:flex"
    }

    function postCourse_type_Close() {
        document.querySelector("#postCourse_theme_Modal").style = "display:none"
    }

    function putCourse_type_Modal(id) {
        setCheckbox(true)
        setVideoCheckbox(true)
        setCourse_typeId(id)
        course_type.map(item=>{
            if (item.id==id) {
                username[1].value=item.name
                content[1].value=item.content
                extra_data[1].value=item.extra_data
                setTimeout(() => {
                    document.querySelector("#checkboxVideo").checked=true
                    document.querySelector("#checkboxTheme").checked=true
                    document.querySelector("#imageChecboxsTheme").value=item.image
                    document.querySelector("#ThemevideoCheckbox1").value=item.video
                }, 100);
                // document.querySelector("#ThemecategoryPut").value=item.category
                // video[1].value=item.video
                // document.querySelector("#Themeusername").value=item.name 
            
            }
        })
        document.querySelector("#putCourse_theme_Modal").style = "display:flex"
    }

    function putCourse_type_Close() {
        setCheckbox(false)
        setVideoCheckbox(false)
        document.querySelector("#putCourse_theme_Modal").style = "display:none"
    }

    function deleteCourse_type_Modal(id){
        setCourse_typeId(id)
        document.querySelector("#deleteCourse_theme_Modal").style="display:flex"
    }

    function deleteCourse_type_Close(){
        document.querySelector("#deleteCourse_theme_Modal").style="display:none"
    }

    const columns = [
        
        {
            title: 'image',
            dataIndex: 'image',
            key: 'username',
            render:(image)=><div><img src={image} alt="" /></div>
        },
        {
            title: 'name',
            dataIndex: 'name',
            key: 'username',
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'balance',
            render: (dataIndex,item) => <div><button onClick={() => putCourse_type_Modal(dataIndex)} className="user_post_button">Редактировать</button>  <button onClick={()=>deleteCourse_type_Modal(dataIndex)} className="user_post_button">Удалить</button>     <button onClick={()=>ThemesPage(item)}  className="user_post_button_malumot">Информация</button></div>,
        },
    ]

    function postCourse_type(){
        var formdata=new FormData()
        formdata.append("name",username[0].value)
        formdata.append("content",content[0].value)
        checkbox==false?formdata.append("image",image[0].files[0]):formdata.append("image",document.querySelector("#image_malumotCheckbox").value)
        // formdata.append("video",video[0].files[0])
        Videocheckbox==false?formdata.append("video",video[0].files[0]):formdata.append("video",document.querySelector("#ThemevideoCheckbox").value)
        formdata.append("extra_data",extra_data[0].value)
        formdata.append("category",localStorage.getItem("CourseCategory"))

        axios.post(`${URL}/api/course_data_theme`,formdata).then(res=>{
        alert("Информация отправлена")
        document.querySelector("#postCourse_theme_Modal").style = "display:none"
        axios.get(`${URL}/api/course_data_theme`, { headers: { Authorization: "Berarer " + sessionStorage.getItem("token") } }).then(res => {
            const CourseTheme=res.data.filter(item=>item.category==localStorage.getItem("CourseCategory"))
            setCourse_type(CourseTheme)
            console.log(res.data);
        })
        }).catch(err=>{
            alert("Информация не отправлена")
        })
    }

    function putCourse_type(){
        var formdata=new FormData()
        formdata.append("name",username[1].value)
        formdata.append("content",content[1].value)
        checkbox==false?formdata.append("image", image[1].files[0]):formdata.append("image", document.querySelector("#imageChecboxsTheme").value)  
        // formdata.append("video",video[1].files[0])
        Videocheckbox==false?formdata.append("video",video[1].files[0]):formdata.append("video",document.querySelector("#ThemevideoCheckbox1").value)
        formdata.append("extra_data",extra_data[1].value)
        formdata.append("category",localStorage.getItem("CourseCategory"))

        axios.put(`${URL}/api/course_data_theme/${course_typeId}`,formdata,{headers:{Authorization:"Bearer " + sessionStorage.getItem("token")}}).then(res=>{
        alert("Информация изменена")
        setCheckbox(false)
        setVideoCheckbox(false)
        document.querySelector("#putCourse_theme_Modal").style = "display:none"
        axios.get(`${URL}/api/course_data_theme`, { headers: { Authorization: "Berarer " + sessionStorage.getItem("token") } }).then(res => {
            const CourseTheme=res.data.filter(item=>item.category==localStorage.getItem("CourseCategory"))
            setCourse_type(CourseTheme)
            console.log(res.data);
        })
        }).catch(err=>{
            alert("Информация не изменилась")
        })
    }

    function deleteCourse_type(){
        axios.delete(`${URL}/api/course_data_theme/${course_typeId}`,{headers:{Authorization:"Bearer " + sessionStorage.getItem("token")}}).then(res=>{
        document.querySelector("#deleteCourse_theme_Modal").style="display:none"
        alert("Данные удалены")    
        axios.get(`${URL}/api/course_data_theme`, { headers: { Authorization: "Berarer " + sessionStorage.getItem("token") } }).then(res => {
            const CourseTheme=res.data.filter(item=>item.category==localStorage.getItem("CourseCategory"))
            setCourse_type(CourseTheme)
            console.log(res.data);
        })
        }).catch(err=>{
            alert("Данные не удалены")
        })
    }

    function ThemesPage(item) {
    window.location="/themes"
    localStorage.setItem("themesID",item.id)
    }


    function Search(e){
        const Serach=course_type.filter(item=>item.name.includes(e.target.value))
        if (e.target.value) {
          setCourse_type(Serach)
        }else{
          setCourse_type(course_type1)
        }
      }

      function Checkbox(checked1){
        setCheckbox(checked1)
        if(checked1==true){
            course_type.map(item=>{
                if (item.id==course_typeId) {
                    setTimeout(() => {
                        document.querySelector("#imageChecboxsTheme").value=item.image
                    }, 100);
                    // document.querySelector("#ThemecategoryPut").value=item.category
                    // video[1].value=item.video
                    // document.querySelector("#Themeusername").value=item.name 
                
                }
            })
        }
      }
      function VideoCheckbox(checked1){
        setVideoCheckbox(checked1)
        if(checked1==true){
            course_type.map(item=>{
                if (item.id==course_typeId) {
                    setTimeout(() => {
                        document.querySelector("#ThemevideoCheckbox1").value=item.video
                    }, 100);
                    // document.querySelector("#ThemecategoryPut").value=item.category
                    // video[1].value=item.video
                    // document.querySelector("#Themeusername").value=item.name 
                
                }
            })
        }
      }

    return (
        <div>

            <div><h1>Тема курса</h1>
            <div  className="search_big_div"><button onClick={() => postCourse_type_Modal()} className="user_post_button">Добавить</button><AiOutlineSearch className="search" /><input onChange={Search} placeholder="Введите здесь..." type="text" /></div>
            <Table dataSource={course_type} columns={columns} /></div>
            

            <div id="postCourse_theme_Modal" className="Modaldiv">
                <div className="postUserModal_div" style={{width:'340px'}}>
                    <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={() => postCourse_type_Close()} /></div>
                    <div style={{display:'flex',flexWrap:"wrap"}} className="postUserModal_div_label">
                        <label htmlFor="">
                            <p>Имя</p>
                            <input id="Themeusername" placeholder="Имя" type="text" />
                        </label>
                        <label htmlFor="">
                            <p>Изображение       <input type="checkbox"  onClick={(e)=>Checkbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
                            {checkbox==false?(<input  id="Themeimage" type="file" />):(<input id="image_malumotCheckbox" type="text" />)}
                        </label>
                        <label htmlFor="">
                            <p>Видео   <input type="checkbox"  onClick={(e)=>VideoCheckbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
                            {Videocheckbox==false?(<input  id="Themevideo" type="file" />):(<input id="ThemevideoCheckbox" type="text" />)}
                        </label>
                        <label htmlFor="">
                            <p>Содержание</p>
                            <input id="Themecontent" placeholder="Содержание" type="text" />
                        </label>
                        <label htmlFor="">
                            <p>Дополнительные данные</p>
                            <input id="Themeextra_data" placeholder="Дополнительные данные" type="text" />
                        </label>
                    </div>
                    <div className="postUserModal_div_button">
                        <button onClick={()=>postCourse_type()}  >Отправить</button>
                    </div>
                </div>
            </div>
            <div id="putCourse_theme_Modal" className="Modaldiv">
                <div className="postUserModal_div"  style={{width:'340px'}}>
                    <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={() => putCourse_type_Close()} /></div>
                    <div style={{display:'flex',flexWrap:"wrap"}} className="postUserModal_div_label">
                        <label htmlFor="">
                            <p>Имя</p>
                            <input id="Themeusername" type="text" />
                        </label>
                        <label htmlFor="">
                            <p>Изображение   <input type="checkbox" id='checkboxTheme'  onClick={(e)=>Checkbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
                            {checkbox==false?(<input id="Themeimage" className="image" placeholder="Изображение" type="file" />):(<input id="imageChecboxsTheme" className="image" placeholder="Изображение" type="text" />)} 
                        </label>
                        <label htmlFor="">
                            <p>Видео   <input type="checkbox" id='checkboxVideo'  onClick={(e)=>VideoCheckbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
                            {Videocheckbox==false?(<input  id="Themevideo" type="file" />):(<input id="ThemevideoCheckbox1" type="text" />)}
                        </label>
                        <label htmlFor="">
                            <p>Содержание</p>
                            <input id="Themecontent" type="text" />
                        </label>
                        <label htmlFor="">
                            <p>Дополнительные данные</p>
                            <input id="Themeextra_data"  type="text" />
                        </label>
                    </div>
                    <div className="postUserModal_div_button">
                        <button onClick={()=>putCourse_type()} >Отправить</button>
                    </div>
                </div>
            </div>
            <div id="deleteCourse_theme_Modal" className="deleteModal">
                <div className="deleteModal_div">
                    <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={() => deleteCourse_type_Close()} /></div>
                    <img src={deleteImg} alt="" />
                    <p>Вы хотите удалить студента</p>
                    <div className="deleteButton_div">
                        <button onClick={() => deleteCourse_type_Close()}>Отмена</button>
                        <button onClick={() => deleteCourse_type()}>Подтвердить</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

