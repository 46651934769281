import React, { useState } from 'react'
import Task from "./Course_task"
import Comment from "./Course_comment" 

export default function Course_task_comment() {
  const [page,setPage]=useState(0)
  return (
    <div>
    {page==1?(<div><button onClick={()=>{setPage(0)}} style={page==0?{backgroundColor:'#1890ff',color:'#fff'}:{}} className='user_post_button_malumot'>Комментарий</button>       <button style={page==1?{backgroundColor:'#1890ff',color:'#fff'}:{}} onClick={()=>{setPage(1)}} className='user_post_button_malumot'>Задача</button><Task/></div>):(<div><button onClick={()=>{setPage(0)}} style={page==0?{backgroundColor:'#1890ff',color:'#fff'}:{}} className='user_post_button_malumot'>Комментарий</button>      <button style={page==1?{backgroundColor:'#1890ff',color:'#fff'}:{}} onClick={()=>{setPage(1)}} className='user_post_button_malumot'>Задача</button><Comment/></div>)}
    </div>
  )
}
