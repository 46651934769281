import { useState } from "react";
import axios from "axios";
import URL from "./Host";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
  Alert,
  Space,
} from "antd";
import signinbg from "../assets/images/img-signin.jpg";
import eye_close from "./img/closee.png";
import eye_open from "./img/open.png";
import ReCAPTCHA from "react-google-recaptcha";


const { Title } = Typography;
const { Content } = Layout;

export default function SignIn() {
  const [showAlert, setShowAlert] = useState(false);

  function onChange(checked) {
    console.log(`switch to ${checked}`);
  }
  function onFinish(values) {}

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  function Login() {
    var formdata = new FormData();

    formdata.append("email", document.querySelector("#email").value);
    formdata.append("password", document.querySelector("#password").value);

    axios
      .post(`${URL}/auth/login/`, formdata)
      .then((res) => {
        localStorage.setItem("rules", 1);
        sessionStorage.setItem("token", res.data.access);
        sessionStorage.setItem("position", res.data.position);
        axios
          .get(`${URL}/auth/oneuser`, {
            headers: { Authorization: "Bearer " + res.data.access },
          })
          .then((res1) => {
            axios
              .get(`${URL}/auth/admin`, {
                headers: { Authorization: "Bearer " + res.data.access },
              })
              .then((res2) => {
                var a = res2.data.filter(
                  (item) => item.user_id == res1.data[0].id
                );
                if (res.data.position == 3) {
                  sessionStorage.setItem("dos", JSON.stringify([{}]));
                  window.location = "/dashboard";
                } else {
                  sessionStorage.setItem("dos", JSON.stringify(a));
                }
              });
          });
      })
      .catch((err) => {
        alert("Нет пароля для входа");
      });
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function eyes_password() {
    let password = document.getElementById("password");
    let eyes = document.getElementById("eyes");

    if (password.type == "password") {
      password.type = "text";
      eyes.src = eye_close;
    } else {
      password.type = "password";
      eyes.src = eye_open;
    }
  }
  return (
    <div>
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Sign In</Title>
              <Title className="font-regular text-muted" level={5}>
                Enter your email and password to sign in
              </Title>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="email"
                  id="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <div className="eyes_bigdiv">
                  <Form.Item
                    className="username"
                    label="Password"
                    name="password"
                    id="password"
                    rules={[
                      {
                        required: true,
                        min: 5,
                        message:
                          "Please input your password with at least 5 characters!",
                      },
                    ]}
                  >
                    <Input type="password" placeholder="Password" />
                  </Form.Item>
                  <img
                    src={eye_open}
                    id="eyes"
                    onClick={() => eyes_password()}
                    alt=""
                  />
                </div>
                
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="button"
                    style={{ width: "100%" }}
                    onClick={() => Login()}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12, maxWidth: "30%" }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img
                style={{ margin: "auto !important" }}
                src={signinbg}
                alt=""
              />
            </Col>
          </Row>
        </Content>
      </Layout>
      {showAlert && (
        <Space
          direction="vertical"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "0%",
          }}
        >
          <Alert
            style={{
              width: "200px",
            }}
            message="Error Text"
            type="error"
          />
        </Space>
      )}
    </div>
  );
}
