import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import lineChart from "./configs/lineChart";

function LineChart() {
  const { Title, Paragraph } = Typography;

  return (
    <div>
      <div  style={{maxWidth:'100% !important'}} className="linechart">
        <div>
          <Title level={5}>Ежемесячные данные</Title>
          <Paragraph className="lastweek">
          Чем в прошлом месяце
          </Paragraph>
        </div>
        <div className="sales">
          <ul>
            <li>{<MinusOutlined />} Трафик</li>
            <li>{<MinusOutlined />} Продажи</li>
          </ul>
        </div>
      </div>

      <ReactApexChart
        className="full-width"
        options={lineChart.options}
        series={lineChart.series}
        type="area"
        height={350}
        width={"100%"}
      />
    </div>
  );
}

export default LineChart;
