import React, { useEffect, useState } from "react";
import axios from "axios";
import URL from "./Host";
import close from "./img/close.png";

export default function Languange() {
  const [language, setLanguage] = useState([]);
  const [languageId, setLanguageId] = useState([]);

  useEffect(() => {
    axios.get(`${URL}/api/language`).then((res) => {
      setLanguage(res.data);
    });
  }, []);

  function deleteLanguange(id) {
    axios
      .delete(`${URL}/api/language/${id}`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Язык удален");
        axios.get(`${URL}/api/language`).then((res) => {
          setLanguage(res.data);
        });
      })
      .catch((err) => {
        alert("Язык не удаляется");
      });
  }

  function putLanguange() {
    var formdata = new FormData();
    formdata.append("lg", document.querySelector("#languangePut").value);

    axios
      .put(`${URL}/api/language/${languageId}`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Добавлен язык");
        axios.get(`${URL}/api/language`).then((res) => {
          setLanguage(res.data);
        });
        document.querySelector("#putLanguangeModal").style = "display:none";
      })
      .catch((err) => {
        alert("Язык не согласен");
      });
  }

  function postLanguange() {
    var formdata = new FormData();
    formdata.append("lg", document.querySelector("#languange").value);

    axios
      .post(`${URL}/api/language`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Добавлен язык");
        document.querySelector("#LanguangeModal").style = "display:none";
        axios.get(`${URL}/api/language`).then((res) => {
          setLanguage(res.data);
        });
      })
      .catch((err) => {
        alert("Язык не согласен");
      });
  }

  function LanguangeModal() {
    document.querySelector("#LanguangeModal").style = "display:flex";
  }

  function LanguangeClose() {
    document.querySelector("#LanguangeModal").style = "display:none";
  }

  function putLanguangeModal(id) {
    setLanguageId(id.id);
    document.querySelector("#languangePut").value = id.lg;
    document.querySelector("#putLanguangeModal").style = "display:flex";
  }

  function putLanguangeClose() {
    document.querySelector("#putLanguangeModal").style = "display:none";
  }

  return (
    <div>
      <button
        onClick={() => LanguangeModal()}
        className="user_post_button_malumot"
      >
        Добавлять
      </button>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {language.map((item) => {
          return (
            <div
              style={{
                padding: "10px",
                background: "#fff",
                boxShadow: "2px 2px 5px 2px grey",
                width: "350px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <h1>
                {item.lg == "af"
                  ? "Afrikaans"
                  : item.lg == "sq"
                  ? "Albanian"
                  : item.lg == "ar"
                  ? "Arabic"
                  : item.lg == "hy"
                  ? "Armenian"
                  : item.lg == "az"
                  ? "Azarbaijani"
                  : item.lg == "eu"
                  ? "Basque"
                  : item.lg == "be"
                  ? "Belarusian"
                  : item.lg == "bn"
                  ? "Bengali"
                  : item.lg == "bg"
                  ? "Bulgarian"
                  : item.lg == "ca"
                  ? "Catalan"
                  : item.lg == "zh-CN"
                  ? "Chinese (Simplified)"
                  : item.lg == "zh-TW"
                  ? "Chinese (Traditional)"
                  : item.lg == "hr"
                  ? "Croatian"
                  : item.lg == "cs"
                  ? "Czech"
                  : item.lg == "da"
                  ? "Danish"
                  : item.lg == "nl"
                  ? "Dutch"
                  : item.lg == "eo"
                  ? "Esperanto"
                  : item.lg == "et"
                  ? "Estonian"
                  : item.lg == "tl"
                  ? "Filipino"
                  : item.lg == "fi"
                  ? "Finnish"
                  : item.lg == "fr"
                  ? "French"
                  : item.lg == "gl"
                  ? "Galician"
                  : item.lg == "ka"
                  ? "Georgian"
                  : item.lg == "de"
                  ? "German"
                  : item.lg == "el"
                  ? "Greek"
                  : item.lg == "gu"
                  ? "Gujarati"
                  : item.lg == "ht"
                  ? "Haitian Creole"
                  : item.lg == "iw"
                  ? "Hebrew"
                  : item.lg == "hi"
                  ? "Hindi"
                  : item.lg == "hu"
                  ? "Hungarian"
                  : item.lg == "is"
                  ? "Icelandic"
                  : item.lg == "id"
                  ? "Indonesian"
                  : item.lg == "ga"
                  ? "Irish"
                  : item.lg == "it"
                  ? "Italian"
                  : item.lg == "ja"
                  ? "Japanese"
                  : item.lg == "kn"
                  ? "Kannada"
                  : item.lg == "ko"
                  ? "Korean"
                  : item.lg == "la"
                  ? "Latian"
                  : item.lg == "lv"
                  ? "Latvian"
                  : item.lg == "lt"
                  ? "Lithuanian"
                  : item.lg == "mk"
                  ? "Macedonian"
                  : item.lg == "ms"
                  ? "Malay"
                  : item.lg == "mt"
                  ? "Maltese"
                  : item.lg == "no"
                  ? "Norwegian"
                  : item.lg == "fa"
                  ? "Persian"
                  : item.lg == "pl"
                  ? "Polish"
                  : item.lg == "pt"
                  ? "Portuguese"
                  : item.lg == "ro"
                  ? "Romanian"
                  : item.lg == "ru"
                  ? "Russian"
                  : item.lg == "sr"
                  ? "Serbian"
                  : item.lg == "sk"
                  ? "Slovak"
                  : item.lg == "sl"
                  ? "Slovenian"
                  : item.lg == "es"
                  ? "Spanish"
                  : item.lg == "sw"
                  ? "Swahili"
                  : item.lg == "sv"
                  ? "Swedish"
                  : item.lg == "ta"
                  ? "Tamil"
                  : item.lg == "te"
                  ? "Telugu"
                  : item.lg == "th"
                  ? "Thai"
                  : item.lg == "tr"
                  ? "Turkish"
                  : item.lg == "uk"
                  ? "Ukrainian"
                  : item.lg == "ur"
                  ? "Urdu"
                  : item.lg == "vi"
                  ? "Vietnamese"
                  : item.lg == "cy"
                  ? "Welsh"
                  : item.lg == "yi"
                  ? "Yiddish"
                  : item.lg == "en"
                  ? "English":""}
              </h1>
              <div style={{ display: "flex", gap: "5px" }}>
              <button
              style={{ marginBottom: "0px" }}
              onClick={() => putLanguangeModal(item)}
              className="user_post_button"
            >
              Edit
            </button>  
              <button
                  style={{ marginBottom: "0px" }}
                  className="user_post_button"
                  onClick={() => deleteLanguange(item.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <div id="LanguangeModal" className="Modaldiv">
        <div style={{ width: "340px" }} className="postUserModal_div">
          <div className="postUserModal_div_icon">
            <img
              src={close}
              className="icon"
              onClick={() => LanguangeClose()}
            />
          </div>
          <div className="postUserModal_div_label">
            <label htmlFor="">
              <p>Язык</p>
              <select name="" id="languange">
                <option value="af">Afrikaans</option>
                <option value="sq">Albanian</option>
                <option value="ar">Arabic</option>
                <option value="hy">Armenian</option>
                <option value="az">Azerbaijani</option>
                <option value="eu">Basque</option>
                <option value="be">Belarusian</option>
                <option value="bn">Bengali</option>
                <option value="bg">Bulgarian</option>
                <option value="ca">Catalan</option>
                <option value="zh-CN">Chinese (Simplified)</option>
                <option value="zh-TW">Chinese (Traditional)</option>
                <option value="hr">Croatian</option>
                <option value="cs">Czech</option>
                <option value="da">Danish</option>
                <option value="nl">Dutch</option>
                <option value="en">English</option>
                <option value="eo">Esperanto</option>
                <option value="et">Estonian</option>
                <option value="tl">Filipino</option>
                <option value="fi">Finnish</option>
                <option value="fr">French</option>
                <option value="gl">Galician</option>
                <option value="ka">Georgian</option>
                <option value="de">German</option>
                <option value="el">Greek</option>
                <option value="gu">Gujarati</option>
                <option value="ht">Haitian Creole</option>
                <option value="iw">Hebrew</option>
                <option value="hi">Hindi</option>
                <option value="hu">Hungarian</option>
                <option value="is">Icelandic</option>
                <option value="id">Indonesian</option>
                <option value="ga">Irish</option>
                <option value="it">Italian</option>
                <option value="ja">Japanese</option>
                <option value="kn">Kannada</option>
                <option value="ko">Korean</option>
                <option value="la">Latin</option>
                <option value="lv">Latvian</option>
                <option value="lt">Lithuanian</option>
                <option value="mk">Macedonian</option>
                <option value="ms">Malay</option>
                <option value="mt">Maltese</option>
                <option value="no">Norwegian</option>
                <option value="fa">Persian</option>
                <option value="pl">Polish</option>
                <option value="pt">Portuguese</option>
                <option value="ro">Romanian</option>
                <option value="ru">Russian</option>
                <option value="sr">Serbian</option>
                <option value="sk">Slovak</option>
                <option value="sl">Slovenian</option>
                <option value="es">Spanish</option>
                <option value="sw">Swahili</option>
                <option value="sv">Swedish</option>
                <option value="ta">Tamil</option>
                <option value="te">Telugu</option>
                <option value="th">Thai</option>
                <option value="tr">Turkish</option>
                <option value="uk">Ukrainian</option>
                <option value="ur">Urdu</option>
                <option value="vi">Vietnamese</option>
                <option value="cy">Welsh</option>
                <option value="yi">Yiddish</option>
              </select>
            </label>
          </div>
          <div className="postUserModal_div_button">
            <button onClick={() => postLanguange()}>Отправить</button>
          </div>
        </div>
      </div>
      <div id="putLanguangeModal" className="Modaldiv">
        <div style={{ width: "340px" }} className="postUserModal_div">
          <div className="postUserModal_div_icon">
            <img
              src={close}
              className="icon"
              onClick={() => putLanguangeClose()}
            />
          </div>
          <div className="postUserModal_div_label">
            <label htmlFor="">
              <p>Язык</p>
              <select name="" id="languangePut">
                <option value="af">Afrikaans</option>
                <option value="sq">Albanian</option>
                <option value="ar">Arabic</option>
                <option value="hy">Armenian</option>
                <option value="az">Azerbaijani</option>
                <option value="eu">Basque</option>
                <option value="be">Belarusian</option>
                <option value="bn">Bengali</option>
                <option value="bg">Bulgarian</option>
                <option value="ca">Catalan</option>
                <option value="zh-CN">Chinese (Simplified)</option>
                <option value="zh-TW">Chinese (Traditional)</option>
                <option value="hr">Croatian</option>
                <option value="cs">Czech</option>
                <option value="da">Danish</option>
                <option value="nl">Dutch</option>
                <option value="en">English</option>
                <option value="eo">Esperanto</option>
                <option value="et">Estonian</option>
                <option value="tl">Filipino</option>
                <option value="fi">Finnish</option>
                <option value="fr">French</option>
                <option value="gl">Galician</option>
                <option value="ka">Georgian</option>
                <option value="de">German</option>
                <option value="el">Greek</option>
                <option value="gu">Gujarati</option>
                <option value="ht">Haitian Creole</option>
                <option value="iw">Hebrew</option>
                <option value="hi">Hindi</option>
                <option value="hu">Hungarian</option>
                <option value="is">Icelandic</option>
                <option value="id">Indonesian</option>
                <option value="ga">Irish</option>
                <option value="it">Italian</option>
                <option value="ja">Japanese</option>
                <option value="kn">Kannada</option>
                <option value="ko">Korean</option>
                <option value="la">Latin</option>
                <option value="lv">Latvian</option>
                <option value="lt">Lithuanian</option>
                <option value="mk">Macedonian</option>
                <option value="ms">Malay</option>
                <option value="mt">Maltese</option>
                <option value="no">Norwegian</option>
                <option value="fa">Persian</option>
                <option value="pl">Polish</option>
                <option value="pt">Portuguese</option>
                <option value="ro">Romanian</option>
                <option value="ru">Russian</option>
                <option value="sr">Serbian</option>
                <option value="sk">Slovak</option>
                <option value="sl">Slovenian</option>
                <option value="es">Spanish</option>
                <option value="sw">Swahili</option>
                <option value="sv">Swedish</option>
                <option value="ta">Tamil</option>
                <option value="te">Telugu</option>
                <option value="th">Thai</option>
                <option value="tr">Turkish</option>
                <option value="uk">Ukrainian</option>
                <option value="ur">Urdu</option>
                <option value="vi">Vietnamese</option>
                <option value="cy">Welsh</option>
                <option value="yi">Yiddish</option>
              </select>
            </label>
          </div>
          <div className="postUserModal_div_button">
            <button onClick={() => putLanguange()}>Отправить</button>
          </div>
        </div>
      </div>
    </div>
  );
}
