import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
} from "antd";

import { useEffect, useState } from "react";
import URL from "./Host";
import axios from "axios"
import { ToTopOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai"
import "./styles/User.css"
import deleteImg from "./img/Inbox cleanup-rafiki.png"
import { AiOutlineSearch } from "react-icons/ai"
import { BiUpload } from "react-icons/bi"
import close from "./img/close.png"





const { Title } = Typography;

const formProps = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

function Tables() {
  const [user, setUser] = useState([])
  const [user1, setUser1] = useState([])
  const [userId, setuserId] = useState([])
  const [positionId, setpositionId] = useState(1)
  const [ban, setBan] = useState()
  const [positionIdhelp, setPositionIdhelp] = useState()
  const [page, setPage] = useState(0)
  const [imageChek,setImageChek]=useState()
  const [dostup,setDostup]=useState(JSON.parse(sessionStorage.getItem("dos")))
  const [checkbox,setCheckbox]=useState(false)


  const username = document.querySelectorAll("#username")
  const last_name = document.querySelectorAll("#last_name")
  const phone_number = document.querySelectorAll("#phone_number")
  const email = document.querySelectorAll("#email")
  const address = document.querySelectorAll("#address")
  const password = document.querySelectorAll("#password")
  const position = document.querySelectorAll("#position")
  const image = document.querySelectorAll("#image")
  const description = document.querySelectorAll("#description")


  useEffect(() => {
    axios.get(`${URL}/auth/users`, { headers: { "Authorization": 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
      setUser(res.data)
      setUser1(res.data)
      // console.log(res.data,"salom");
      res.data.map(item => {
        setBan(item.is_active)

      })
      setPositionIdhelp(res.data)
    })
  }, [])

  function postUserModal() {
    document.querySelector("#postModal").style = "display:flex;"
  }
  function postUserClose() {
    document.querySelector("#postModal").style = "display:none;"
  }
  function putUserModal(id) {
    setuserId(id)
    user.map(item => {

      if (item.id == id) {
        address[1].value = item.address
        description[1].value = item.description
        email[1].value = item.email
        last_name[1].value = item.last_name
        password[1].value = item.password
        phone_number[1].value = item.phone_number
        username[1].value = item.username
        position[1].value = item.position
      }
    })
    document.querySelector("#putModal").style = "display:flex;"
  }
  function puttUserClose() {
    document.querySelector("#putModal").style = "display:none;"
  }
  function deleteModal(id) {
    setuserId(id)
    document.querySelector("#deleteModal").style = "display:flex"
  }
  function deleteClose() {
    document.querySelector("#deleteModal").style = "display:none"
  }


  function postUser() {
    var formdata = new FormData()
    formdata.append("address", address[0].value)
    formdata.append("balance", 0)
    formdata.append("description", description[0].value)
    formdata.append("email", email[0].value)
    checkbox==false?formdata.append("image",image[0].files[0]):formdata.append("image",document.querySelector("#imageChecboxs").value)
    formdata.append("last_name", last_name[0].value)
    formdata.append("phone_number", phone_number[0].value)
    formdata.append("username", username[0].value)
    formdata.append("password", password[0].value)
    formdata.append("position", positionId)
    formdata.append("youtobe",document.querySelector("#youtube_tables").value)
    formdata.append("telegram",document.querySelector("#telegram_tables").value)
    formdata.append("instagram",document.querySelector("#instagram_tables").value)

    axios.post(`${URL}/auth/users`, formdata, { headers: { "Authorization": "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      alert("Добавлена информация")
      document.querySelector("#postModal").style = "display:none;"
      axios.get(`${URL}/auth/users`, { headers: { "Authorization": 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
        setUser(res.data)
      })
    })
      .catch(err => {
        alert("Это адрес электронной почты или имя, пароль введен")
      })
  }
  function putUser(item) {
    var formdata = new FormData()
    formdata.append("address", document.querySelector("#address_malumot").value)
    formdata.append("balance", document.querySelector("#balance_malumot").value)
    formdata.append("description", document.querySelector("#description_malumot").value)
    formdata.append("email", document.querySelector("#email_malumot").value)
    formdata.append("last_name", document.querySelector("#last_name_malumot").value)
    formdata.append("password", document.querySelector("#password_malumot").value)
    formdata.append("phone_number", document.querySelector("#phone_number_malumot").value)
    formdata.append("username", document.querySelector("#username_malumot").value)
    formdata.append("position", positionId)
    checkbox==false?formdata.append("image", document.querySelector("#image_malumot").files[0]):formdata.append("image", document.querySelector("#image_malumotCheckbox").value)  
    formdata.append("youtobe",document.querySelector("#youtube_tables1").value)
    formdata.append("telegram",document.querySelector("#telegram_tables1").value)
    formdata.append("instagram",document.querySelector("#instagram_tables1").value)

    axios.put(`${URL}/auth/userssuperadmin/${userId}`, formdata, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      alert("Информация изменена")
      setPage(0)
      setCheckbox(false)
      axios.get(`${URL}/auth/users`, { headers: { "Authorization": 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
        setUser(res.data)
      })
    }).catch(err => {
      alert("Информация не изменилась")
    })
  }
  function deleteUser() {
    axios.delete(`${URL}/auth/users/${userId}`, { headers: { Authorization: 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
      alert("Данные удалены")
      document.querySelector("#deleteModal").style = "display:none"
      axios.get(`${URL}/auth/users`, { headers: { "Authorization": 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
        setUser(res.data)
      })
    })
      .catch(err => {
        alert("Данные не удалены")
      })
  }
  function PositionId(id) {
    setpositionId(id)
  }
  function bos() {
    bos(positionId)
  }

  function Ban(id, dataindex) {
    var formdata = new FormData()
    formdata.append("is_active", id)

    axios.put(`${URL}/auth/ban/${dataindex}`, formdata, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      window.location.reload()
      axios.get(`${URL}/auth/users`, { headers: { "Authorization": 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
        setUser(res.data)
        res.data.map(item => {
          setBan(item.is_active)
        })
      })
    }).catch(err => {
      alert("Бан не был")
    })
  }

  function pageMalumot(id,salom) {
    setCheckbox(true)
    setPage(1)
    setTimeout(() => {
      user.map(item => {
        if (item.id == id) {
          document.querySelector("#username_malumot").value = item.username
          document.querySelector("#last_name_malumot").value = item.last_name
          document.querySelector("#address_malumot").value = item.address
          document.querySelector("#email_malumot").value = item.email
          document.querySelector("#phone_number_malumot").value = item.phone_number
          document.querySelector("#description_malumot").value = item.description
          document.querySelector("#youtube_tables1").value = item.youtobe
          document.querySelector("#telegram_tables1").value = item.telegram
          document.querySelector("#instagram_tables1").value = item.instagram
          setTimeout(() => {
            document.querySelector("#tablesCheckbox").checked=true
            document.querySelector("#image_malumotCheckbox").value=item.image
          }, 100);
        }
      })
    }, 10);
    setImageChek(salom.image)
    setuserId(id)
  }

  function BanFilter(id) {
    axios.get(`${URL}/auth/users`, { headers: { "Authorization": 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
    const Filter=res.data.filter(item=>item.is_active==id)
    setUser(Filter)
    })
  }

  function BanFilter1() {
    axios.get(`${URL}/auth/users`, { headers: { "Authorization": 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
    setUser(res.data)
    })
  }


  const columns = [
    {
      title: 'Изображение',
      dataIndex: 'image',
      key: 'image',
      render: image => image == null ? (<img alt="no img" src="https://static.vecteezy.com/system/resources/thumbnails/004/141/669/small/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg" />) : (<img alt="img" src={image} />)
    },
    {
      title: 'Имя',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Позиция',
      dataIndex: 'position',
      key: 'position',
      render: (dataIndex) => dataIndex == 2 ? <button style={{ backgroundColor: '#1890ff', marginBottom: '10px' }} className="position_notification">Учитель</button> : dataIndex == 4 ? <button style={{ backgroundColor: 'rgb(27, 155, 27)', marginBottom: '10px' }} className="position_notification">Студент</button> : dataIndex == 5 ? <button style={{ backgroundColor: 'grey', marginBottom: '10px' }} className="position_notification">Администратор</button> : dataIndex == 1 ? <button style={{ backgroundColor: 'grey', marginBottom: '10px' }} className="position_notification">Пользователь</button> : dataIndex == 3 ? <button className="position_notification">СуперАдмин</button> : ""
    },
    {
      title: <Radio.Group  defaultValue="c">
      <Radio.Button  className="filterBan" onClick={()=>BanFilter(true)} value="a">забанить</Radio.Button>
      <Radio.Button  className="filterBan" onClick={()=>BanFilter(false)} value="b">разбанить</Radio.Button>
      <Radio.Button  className="filterBan" onClick={()=>BanFilter1()} value="c">Все</Radio.Button>
    </Radio.Group>,
      dataIndex: 'id',
      key: 'id',
      render: (dataIndex,_salom) => <div> {user.map(item => { if (item.position == 3) { } else { if (item.id == dataIndex) { return <div>{false === item.is_active ? (<button onClick={() => Ban(true, dataIndex)} className="user_post_button_ban">разбанить</button>) : (<button onClick={() => Ban(false, dataIndex)} className="user_post_button_bandan_olish">забанить</button>)}        {(dostup[0].alluser_delete || sessionStorage.getItem("position") == 3)?(<button onClick={() => deleteModal(dataIndex)} className="user_post_button">Удалить</button>):(<div></div>)}      {(dostup[0].alluser_put || sessionStorage.getItem("position") == 3)?(<button onClick={() => pageMalumot(dataIndex,_salom)} className="user_post_button_malumot">Информация</button>):(<div></div>)} </div> } } })}  </div>,
    },
  ]

  function Search(e) {
    const Serach = user.filter(item => item.username.includes(e.target.value))
    if (e.target.value) {
      setUser(Serach)
    } else {
      setUser(user1)
    }
  }

  function Checkbox(checked1){
    setCheckbox(checked1)
    if(checked1==true){
      user.map(item => {
        if (item.id == userId) {
          setTimeout(() => {
            document.querySelector("#image_malumotCheckbox").value=item.image
          }, 100);
        }
      })
    }
  }


  return (
    <div>
      {page == 1 ? (
        <div>
          <div className="exit_button"><button onClick={() => {setPage(0);setCheckbox(false)}}>Назад</button></div>
          <div className="malumot_big_div">
            <label htmlFor="">
              <p>Имя</p>
              <input id="username_malumot" type="text" />
            </label>
            <label htmlFor="">
              <p>Фамилия</p>
              <input id="last_name_malumot" type="text" />
            </label>
            <label htmlFor="">
              <p>Адрес</p>
              <input id="address_malumot" type="text" />
            </label>
            <label htmlFor="">
              <p>Баланс</p>
              {user.map(item => {
                if (item.id == userId) {
                  return <input id="balance_malumot" value={item.balance} type="password" />
                }
              })}
            </label>
            <label htmlFor="">
              <p>Дата присоединения</p>
              {user.map(item => {
                if (item.id == userId) {
                  return <input id="date_joined_malumot" value={item.date_joined.slice(0, 10)} type="text" />
                }
              })}
            </label>
            <label htmlFor="">
              <p>Электронная почта</p>
              <input id="email_malumot" type="text" />
            </label>
            <label htmlFor="">
              <p>Пароль</p>
              {user.map(item => {
                if (item.id == userId) {
                  return <input id="password_malumot" value={item.password} type="password" />
                }
              })}
            </label>
            <label htmlFor="">
              <p>Номер телефона</p>
              <input id="phone_number_malumot" type="text" />
            </label>
            
            <label htmlFor="">
            <p>Youtube link</p>
            <input  id="youtube_tables1"  type="text" />
            </label>
            <label htmlFor="">
            <p>Telegram link</p>
            <input id="telegram_tables1"   type="text" />
            </label>
            <label htmlFor="">
            <p>Instagram link</p>
            <input id="instagram_tables1"  type="text" />
            </label>
            
            {user.map(item => {
              if (item.id == userId) {
                return <div>
                  <label htmlFor="">
                    <p>Время создавать</p>
                    <input id="time_create_malumot" value={item.time_create.slice(0, 10)} type="text" />
                  </label>
                  <label htmlFor="">
                    <p>Обновление времени</p>
                    <input id="time_update_malumot" value={item.time_update.slice(0, 10)} type="text" />
                  </label>
                </div>
              }
            })}
            <label htmlFor="">
              <p>Позиция</p>
              <div className="position">
                <button className="position_button" onClick={() => PositionId(4)} style={positionId == 4 ? { backgroundColor: '#1890ff', color: '#fff' } : {}}>Студент</button>
              </div>
            </label>
            <label htmlFor="">
              <p>Позиция</p>
              <div className="position">
                <button className="position_button" onClick={() => PositionId(2)} style={positionId == 2 ? { backgroundColor: '#1890ff', color: '#fff' } : {}}>Учитель</button>
              </div>
            </label>
            <label htmlFor="">
              <p>Позиция</p>
              <div className="position">
                <button className="position_button" onClick={() => PositionId(5)} style={positionId == 5 ? { backgroundColor: '#1890ff', color: '#fff' } : {}}>Администратор</button>
              </div>
            </label>
            <label htmlFor="">
              <p>Изображение    <input type="checkbox" id="tablesCheckbox"  onClick={(e)=>Checkbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
              {checkbox==false?(<input  id="image_malumot" type="file" />):(<input id="image_malumotCheckbox" type="text" />)}
            </label>
            <label htmlFor="">
              <p>Описание</p>
              <input id="description_malumot" type="text" />
            </label>
          </div>
          <div className="postUserModal_div_button"><button className="user_post_button_malumot" onClick={() => putUser()} >Редактировать</button></div>
        </div>) : (<div><div className="search_big_div">{(dostup[0].alluser_post || sessionStorage.getItem("position") == 3)?(<button onClick={() => postUserModal()} className="user_post_button">Добавить</button>):(<div></div>)}<div style={{position:'relative'}}><AiOutlineSearch className="search" /><input onChange={Search} placeholder="Введите здесь..." type="text" /></div></div>
          <Table dataSource={user} columns={columns} /></div>)}


      <div id="deleteModal" className="deleteModal">
        <div className="deleteModal_div">
          <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={() => deleteClose()} /></div>
          <img src={deleteImg} alt="" />
          <p>Вы хотите удалить этого пользователя</p>
          <div className="deleteButton_div">
            <button onClick={() => deleteClose()}>Отмена</button>
            <button onClick={() => deleteUser()}>Подтвердить</button>
          </div>
        </div>
      </div>
      <div id="postModal" className="Modaldiv">
        <div className="postUserModal_div">
          <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={() => postUserClose()} /></div>
          <div className="postUserModal_div_label">
            <label htmlFor="">
              <p>Имя пользователя</p>
              <input id="username" placeholder="Имя пользователя" type="text" />
            </label>
            <label htmlFor="">
              <p>Фамилия</p>
              <input id="last_name" placeholder="Фамилия" type="text" />
            </label>
            <label htmlFor="">
              <p>Телефон номер</p>
              <input id="phone_number" placeholder="Телефон номер" type="text" />
            </label>
            <label htmlFor="">
              <p>Электронная почта</p>
              <input id="email" placeholder="Электронная почта" type="text" />
            </label>
            <label htmlFor="">
              <p>Адрес</p>
              <input id="address" placeholder="Адрес" type="text" />
            </label>
            <div style={{display:'flex',justifyContent:'space-between'}}>
            <label htmlFor="">
            <p>Youtube link</p>
            <input style={{width:'90px'}} id="youtube_tables"  type="text" />
            </label>
            <label htmlFor="">
            <p>Telegram link</p>
            <input id="telegram_tables"  style={{width:'90px'}} type="text" />
            </label>
            <label htmlFor="">
            <p>Instagram link</p>
            <input id="instagram_tables" style={{width:'90px'}} type="text" />
            </label>
            </div>  

            <label htmlFor="">
              <p>Пароль</p>
              <input id="password" placeholder="Пароль" type="text" />
            </label>
            <label htmlFor="">
              <p>Позиция</p>
              <div className="position">
                <button onClick={() => PositionId(4)} style={positionId == 4 ? { backgroundColor: '#1890ff', color: '#fff' } : {}}>Студент</button>
                <button onClick={() => PositionId(2)} style={positionId == 2 ? { backgroundColor: '#1890ff', color: '#fff' } : {}}>Учитель</button>
                <button onClick={() => PositionId(5)} style={positionId == 5 ? { backgroundColor: '#1890ff', color: '#fff' } : {}}>Администратор</button>
              </div>
            </label>
            <label style={{ position: 'relative' }} htmlFor="">
              <p>Изображение  <input type="checkbox"  onClick={(e)=>Checkbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
              {checkbox==false?(<input id="image" className="image" placeholder="Изображение" type="file" />):(<input id="imageChecboxs" className="image" placeholder="Изображение" type="text" />)}             
              {/*<div className="div_img_upload">
               <p><BiUpload/>  Нажмите, чтобы загрузить</p>
          </div>*/}
              </label>
          </div>
          <label htmlFor="">
            <p>Описание</p>
            <textarea  id="description" placeholder="Описание" className="description" type="text" />
          </label>
          <div className="postUserModal_div_button">
            <button onClick={() => postUser()}>Отправить</button>
          </div>
        </div>
      </div>
      {/* <div id="putModal" className="Modaldiv">
      <div className="postUserModal_div">
      <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={()=>puttUserClose()}/></div>
         <div className="postUserModal_div_label">
        <label htmlFor="">
          <p>Имя пользователя</p>
          <input id="username"  type="text" />
        </label>
        <label htmlFor="">
          <p>Фамилия</p>
          <input id="last_name"  type="text" />
        </label>
        <label htmlFor="">
          <p>Телефон номер</p>
          <input id="phone_number"  type="text" />
        </label>
        <label htmlFor="">
          <p>Электронная почта</p>
          <input id="email"  type="text" />
        </label>
        <label htmlFor="">
          <p>Адрес</p>
          <input id="address"  type="text" />
        </label>
        <label htmlFor="">
          <p>Пароль</p>
          <input id="password"  type="text" />
        </label>
        <label htmlFor="">
          <p>Позиция</p>
          <input id="position"  type="text" />
        </label>
        <label htmlFor="">
          <p>Изображение</p>
          <input id="image"  type="file" />
        </label>
        </div>
        <label htmlFor="">
          <p>Описание</p>
          <input id="description" placeholder="Описание" className="description" type="text" />
        </label>
        <div className="postUserModal_div_button">
        <button onClick={()=>putUser()}>Редактировать</button>
        </div>
      </div>
    </div> */}
    </div>
  );
}

export default Tables;
