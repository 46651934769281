import axios from "axios";
import URL from "../../../pages/Host";

var diagramma=[]

axios.get(`${URL}/pay/payment`,{headers:{Authorization:"Bearer "+sessionStorage.getItem("token")}}).then(res=>{
  
  for (let i = 0; i < 12; i++) {
   diagramma.push(0)
  for (let j = 0; j < res.data.length; j++) {
   if ((res.data[j].time_create).slice(5,7)==`${i}`.padStart(2,"0")) {
    diagramma[i]=diagramma[i]+res.data[j].amout*1
   }
  }
  }
  
})

var course=[]

axios.get(`${URL}/api/super/registerCourse`,{headers:{Authorization:"Bearer "+sessionStorage.getItem("token")}}).then(res1=>{
  for (let i = 0; i < 12; i++) {
   course.push(0)
  for (let j = 0; j < res1.data.length; j++) {
   if ((res1.data[j].time_create).slice(5,7)==`${i}`.padStart(2,"0")) {
    var count=res1.data[j]?1:0
    course[i]=course[i]+count*1
   }
  }
  }
  
})



const lineChart = {
  series: [
    {
      name: "Деньги - $",
      data: diagramma,
      offsetY: 0,
    },
    {
      name: "Курсы распроданы",
      data: course,
      offsetY: 0,
    },
  ],

  options: {
    chart: {
      width: "100%",
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },

    legend: {
      show: false,
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    yaxis: {
      labels: {
        style: {
          fontSize: "14px",
          fontWeight: 600,
          colors: ["#8c8c8c"],
        },
      },
    },

    xaxis: {
      labels: {
        style: {
          fontSize: "14px",
          fontWeight: 600,
          colors: [
            "#8c8c8c",
            "#8c8c8c",
            "#8c8c8c",
            "#8c8c8c",
            "#8c8c8c",
            "#8c8c8c",
            "#8c8c8c",
            "#8c8c8c",
            "#8c8c8c",
            "#8c8c8c",
            "#8c8c8c",
          ],
        },
      },
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },


    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  },
};

export default lineChart;
