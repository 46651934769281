import React, { useEffect, useState } from "react";
import Salom from "./img/Inbox cleanup-rafiki.png";
import { BsTelegram, BsArrow90DegLeft, BsBookmarkCheck } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { AiFillCloseCircle } from "react-icons/ai";
import URL from "./Host";
import axios from "axios";

export default function Course_task() {
  const [comment, setCommment] = useState([]);
  const [user, setUser] = useState([]);
  const [oneuser, setOneuser] = useState([]);
  const [image, setImage] = useState();
  const [Name, setName] = useState();
  const [Task, setTask] = useState([]);
  const [text, setText] = useState();
  const [subComment, setSubComment] = useState([]);
  const [CommentId, setCommentId] = useState(0);
  const [CommenKey, setCommenKey] = useState();
  const [MarkValue, setMarkValue] = useState();
  const [MarkValue1, setMarkValue1] = useState();
  const [Baho, setBaho] = useState();

  useEffect(() => {
    axios
      .get(`${URL}/api/course_theme_task`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        const Filter = res.data.filter(
          (item) => item.course_theme == localStorage.getItem("themesID")
        );
        setTask(Filter);
      });
    axios
      .get(`${URL}/api/course_theme_comment/`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        const Filter = res.data.filter(
          (item) =>
            item.theme == localStorage.getItem("themesID") &&
            item.task_commnet_id == 1
        );
        setCommment(Filter);

        for (let i = 0; i < Filter.length; i++) {
          for (let j = 0; j < res.data.length; j++) {
            if (Filter[i].subcomment == res.data[j].id) {
              // setSubComment(res.data)
              Filter[i].subName = res.data[j].user_id;
              Filter[i].text1 = res.data[j].text;
            }
          }
        }
        setCommment(Filter);
        axios
          .get(`${URL}/auth/users`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res1) => {
            for (let i = 0; i < Filter.length; i++) {
              for (let j = 0; j < res1.data.length; j++) {
                if (Filter[i].subName == res1.data[j].id) {
                  Filter[i].subName = res1.data[j].username;
                }
              }
            }
            setCommment(Filter);
            setUser(res1.data);
          });

        axios
          .get(`${URL}/api/course_theme_task_student`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res21) => {
            for (let i = 0; i < res21.data.length; i++) {
              for (let j = 0; j < Filter.length; j++) {
                if (res21.data[i].feedback == Filter[j].id) {
                  Filter[j].mark = res21.data[i].mark;
                  Filter[j].markId = res21.data[i].id;
                } else {
                  Filter[j].mark = null;
                }
              }
            }
            setCommment(Filter);
          });
      });

    axios
      .get(`${URL}/auth/oneuser`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        res.data.map((item) => {
          setOneuser(item.id);
        });
      });
  }, []);

  function commentPost() {
    var formdata = new FormData();
    formdata.append("theme", localStorage.getItem("themesID"));
    formdata.append("image", null);
    formdata.append("text", document.querySelector("#message_send1").value);
    formdata.append("subcomment", CommentId);
    formdata.append("user_id", oneuser);
    formdata.append("task_commnet_id", 1);

    axios
      .post(`${URL}/api/course_theme_comment/`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        axios
          .get(`${URL}/api/course_theme_comment/`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            const Filter = res.data.filter(
              (item) =>
                item.theme == localStorage.getItem("themesID") &&
                item.task_commnet_id == 1
            );
            setCommment(Filter);

            for (let i = 0; i < Filter.length; i++) {
              for (let j = 0; j < res.data.length; j++) {
                if (Filter[i].subcomment == res.data[j].id) {
                  // setSubComment(res.data)
                  Filter[i].subName = res.data[j].user_id;
                  Filter[i].text1 = res.data[j].text;
                }
              }
            }
            setCommment(Filter);
            axios
              .get(`${URL}/auth/users`, {
                headers: {
                  Authorization: "Bearer " + sessionStorage.getItem("token"),
                },
              })
              .then((res1) => {
                for (let i = 0; i < Filter.length; i++) {
                  for (let j = 0; j < res1.data.length; j++) {
                    if (Filter[i].subName == res1.data[j].id) {
                      Filter[i].subName = res1.data[j].username;
                    }
                  }
                }
                setCommment(Filter);
                setUser(res1.data);
              });

            axios
              .get(`${URL}/api/course_theme_task_student`, {
                headers: {
                  Authorization: "Bearer " + sessionStorage.getItem("token"),
                },
              })
              .then((res21) => {
                for (let i = 0; i < res21.data.length; i++) {
                  for (let j = 0; j < Filter.length; j++) {
                    if (res21.data[i].feedback == Filter[j].id) {
                      Filter[j].mark = res21.data[i].mark;
                      Filter[j].markId = res21.data[i].id;
                    }
                  }
                }
                setCommment(Filter);
              });
          });
        setCommentId(0);
        document.querySelector("#message_send1").value = " ";
        document.querySelectorAll("#subcomment1")[CommenKey].style =
          "position: fixed;top:-100%";
      })
      .catch((err) => {});
  }

  function commentdelete(id) {
    axios
      .delete(`${URL}/api/course_theme_comment/${id}`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        axios
          .get(`${URL}/api/course_theme_comment/`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            const Filter = res.data.filter(
              (item) =>
                item.theme == localStorage.getItem("themesID") &&
                item.task_commnet_id == 1
            );
            setCommment(Filter);

            for (let i = 0; i < Filter.length; i++) {
              for (let j = 0; j < res.data.length; j++) {
                if (Filter[i].subcomment == res.data[j].id) {
                  // setSubComment(res.data)
                  Filter[i].subName = res.data[j].user_id;
                  Filter[i].text1 = res.data[j].text;
                }
              }
            }
            setCommment(Filter);
            axios
              .get(`${URL}/auth/users`, {
                headers: {
                  Authorization: "Bearer " + sessionStorage.getItem("token"),
                },
              })
              .then((res1) => {
                for (let i = 0; i < Filter.length; i++) {
                  for (let j = 0; j < res1.data.length; j++) {
                    if (Filter[i].subName == res1.data[j].id) {
                      Filter[i].subName = res1.data[j].username;
                    }
                  }
                }
                setCommment(Filter);
                setUser(res1.data);
              });

            axios
              .get(`${URL}/api/course_theme_task_student`, {
                headers: {
                  Authorization: "Bearer " + sessionStorage.getItem("token"),
                },
              })
              .then((res21) => {
                for (let i = 0; i < res21.data.length; i++) {
                  for (let j = 0; j < Filter.length; j++) {
                    if (res21.data[i].feedback == Filter[j].id) {
                      Filter[j].mark = res21.data[i].mark;
                      Filter[j].markId = res21.data[i].id;
                    }
                  }
                }
                setCommment(Filter);
              });
          });
      });
  }

  function subCommentModal(key, image, username, text, id) {
    document.querySelectorAll("#subcomment1")[key].style =
      "position: fixed;top:0px";
    setImage(image);
    setName(username);
    setText(text);
    setCommenKey(key);
    setCommentId(id);
  }
  function subCommentModal1(key, image, username, text, id) {
    document.querySelectorAll("#subcomment2")[key].style =
      "position: fixed;top:0px;display:block;";
    setImage(image);
    setName(username);
    setText(text);
    setCommenKey(key);
    setCommentId(id);
  }
  function subCommentClose1(key) {
    setCommentId(0);
    document.querySelectorAll("#subcomment2")[key].style =
      "position: fixed;top:-100%";
  }
  function subCommentModal2(key, image, username, text, id, markId,mark) {
    setMarkValue1(mark);
    setBaho(markId);
    document.querySelectorAll("#subcomment3")[key].style =
      "position: fixed;top:0px;display:block;";
    setImage(image);
    setName(username);
    setText(text);
    setCommenKey(key);
    setCommentId(id);
  }
  function subCommentClose2(key) {
    setCommentId(0);
    document.querySelectorAll("#subcomment3")[key].style =
      "position: fixed;top:-100%";
  }

  function subCommentClose(key) {
    setCommentId(0);
    document.querySelectorAll("#subcomment1")[key].style =
      "position: fixed;top:-100%";
  }

  function commentMarkPost(id, name, text, image, key) {
    var formdata = new FormData();
    formdata.append("content", text);
    formdata.append("course_theme", localStorage.getItem("themesID"));
    formdata.append("image", image);
    formdata.append("feedback", id);
    formdata.append("mark", MarkValue);

    axios
      .post(`${URL}/api/course_theme_task_student`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        axios
          .get(`${URL}/api/course_theme_comment/`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            const Filter = res.data.filter(
              (item) =>
                item.theme == localStorage.getItem("themesID") &&
                item.task_commnet_id == 1
            );
            setCommment(Filter);

            for (let i = 0; i < Filter.length; i++) {
              for (let j = 0; j < res.data.length; j++) {
                if (Filter[i].subcomment == res.data[j].id) {
                  // setSubComment(res.data)
                  Filter[i].subName = res.data[j].user_id;
                  Filter[i].text1 = res.data[j].text;
                }
              }
            }
            setCommment(Filter);
            axios
              .get(`${URL}/auth/users`, {
                headers: {
                  Authorization: "Bearer " + sessionStorage.getItem("token"),
                },
              })
              .then((res1) => {
                for (let i = 0; i < Filter.length; i++) {
                  for (let j = 0; j < res1.data.length; j++) {
                    if (Filter[i].subName == res1.data[j].id) {
                      Filter[i].subName = res1.data[j].username;
                    }
                  }
                }
                setCommment(Filter);
                setUser(res1.data);
              });

            axios
              .get(`${URL}/api/course_theme_task_student`, {
                headers: {
                  Authorization: "Bearer " + sessionStorage.getItem("token"),
                },
              })
              .then((res21) => {
                for (let i = 0; i < res21.data.length; i++) {
                  for (let j = 0; j < Filter.length; j++) {
                    if (res21.data[i].feedback == Filter[j].id) {
                      Filter[j].mark = res21.data[i].mark;
                      Filter[j].markId = res21.data[i].id;
                    }
                  }
                }
                setCommment(Filter);
              });
          });
        setCommentId(0);
        document.querySelectorAll("#subcomment2")[key].style =
          "position: fixed;top:-100%";
      })
      .catch((err) => {
        alert("xoxakbar");
      });
  }
  function commentMarkPut(id, name, text, image, key) {
    var formdata = new FormData();
    formdata.append("content", text);
    formdata.append("course_theme", localStorage.getItem("themesID"));
    formdata.append("image", image);
    formdata.append("feedback", id);
    formdata.append("mark", MarkValue1);

    axios
      .put(`${URL}/api/course_theme_task_student/${Baho}`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        axios
          .get(`${URL}/api/course_theme_comment/`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            const Filter = res.data.filter(
              (item) =>
                item.theme == localStorage.getItem("themesID") &&
                item.task_commnet_id == 1
            );
            setCommment(Filter);

            for (let i = 0; i < Filter.length; i++) {
              for (let j = 0; j < res.data.length; j++) {
                if (Filter[i].subcomment == res.data[j].id) {
                  // setSubComment(res.data)
                  Filter[i].subName = res.data[j].user_id;
                  Filter[i].text1 = res.data[j].text;
                }
              }
            }
            setCommment(Filter);
            axios
              .get(`${URL}/auth/users`, {
                headers: {
                  Authorization: "Bearer " + sessionStorage.getItem("token"),
                },
              })
              .then((res1) => {
                for (let i = 0; i < Filter.length; i++) {
                  for (let j = 0; j < res1.data.length; j++) {
                    if (Filter[i].subName == res1.data[j].id) {
                      Filter[i].subName = res1.data[j].username;
                    }
                  }
                }
                setCommment(Filter);
                setUser(res1.data);
              });

            axios
              .get(`${URL}/api/course_theme_task_student`, {
                headers: {
                  Authorization: "Bearer " + sessionStorage.getItem("token"),
                },
              })
              .then((res21) => {
                for (let i = 0; i < res21.data.length; i++) {
                  for (let j = 0; j < Filter.length; j++) {
                    if (res21.data[i].feedback == Filter[j].id) {
                      Filter[j].mark = res21.data[i].mark;
                      Filter[j].markId = res21.data[i].id;
                    }
                  }
                }
                setCommment(Filter);
              });
          });
        setCommentId(0);
        document.querySelectorAll("#subcomment3")[key].style =
          "position: fixed;top:-100%";
      })
      .catch((err) => {
        alert("xoxakbar");
      });
  }

  return (
    <div>
      <div className="comment_big_div">
        {/* oyna */}
        <div className="comment_big_div_oyna_div">
          {/* ozi */}
          {Task.map((item, key) => {
            return (
              <div>
                <div className="task_big_div">
                  <div className="comment_big_div_oyna_div_message_img">
                    <img src={item.image} alt="" />
                  </div>
                  <div style={{ display: "block" }}>
                    <div>
                      <p>{item.content}</p>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            );
          })}

          {comment.map((item, key) => {
            return (
              <div>
                {user.map((user) => {
                  if (item.user_id == user.id && item.subcomment == 0) {
                    return (
                      <div className="comment_big_div_oyna_div_message">
                        <div className="comment_big_div_oyna_div_message_img">
                          <img src={user.image} alt="" />
                        </div>
                        <div className="comment_big_div_oyna_div_message_div">
                          <h1 style={{ color: "#000" }}>{user.username}</h1>
                          <p style={{ color: "#000" }}>{item.text}</p>
                          <div className="comment_big_div_oyna_div_message_div_delete">
                            <p
                              style={{ color: "#1890ff" }}
                              onClick={() =>
                                subCommentModal(
                                  key,
                                  user.image,
                                  user.username,
                                  item.text,
                                  item.id
                                )
                              }
                            >
                              <BsArrow90DegLeft />
                              Отвечать
                            </p>
                            <p onClick={() => commentdelete(item.id)}>
                              <MdDelete />
                              Удалить
                            </p>

                            {item.mark ? (
                              <p
                                onClick={() =>
                                  subCommentModal2(
                                    key,
                                    user.image,
                                    user.username,
                                    item.text,
                                    item.id,
                                    item.markId,
                                    item.mark
                                  )
                                }
                                style={{ color: "#1890ff" }}
                              >
                                <BsBookmarkCheck />
                                {item.mark}
                              </p>
                            ) : (
                              <p
                                onClick={() =>
                                  subCommentModal1(
                                    key,
                                    user.image,
                                    user.username,
                                    item.text,
                                    item.id
                                  )
                                }
                                style={{ color: "#1890ff" }}
                              >
                                <BsBookmarkCheck />
                                Оценка
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
                <div
                  id="subcomment1"
                  className="comment_big_div_message_div_otevt_oyna"
                >
                  <div className="comment_big_div_oyna_div_message_img">
                    <img src={image} alt="" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      paddingRight: "10px",
                    }}
                  >
                    <div className="comment_big_div_oyna_div_message_div">
                      <h1 style={{ color: "#fff" }}>{Name}</h1>
                      <p style={{ color: "#fff" }}>{text}</p>
                    </div>
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#fff",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => subCommentClose(key)}
                    >
                      <AiFillCloseCircle />
                    </p>
                  </div>
                </div>
                <div
                  id="subcomment2"
                  className="comment_big_div_message_div_otevt_oyna"
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginBottom: "10px",
                      gap: "10px",
                    }}
                  >
                    <div className="comment_big_div_oyna_div_message_img">
                      <img src={image} alt="" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingRight: "10px",
                      }}
                    >
                      <div className="comment_big_div_oyna_div_message_div">
                        <h1 style={{ color: "#fff" }}>{Name}</h1>
                        <p style={{ color: "#fff" }}>{text}</p>
                      </div>
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "#fff",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => subCommentClose1(key)}
                      >
                        <AiFillCloseCircle />
                      </p>
                    </div>
                  </div>
                  <div
                    style={{ borderRadius: "5px" }}
                    className="comment_big_div_message_div"
                  >
                    <input
                      id="mark_send"
                      onChange={(e) => setMarkValue(e.target.value)}
                      maxLength="1"
                      type="text"
                    />
                    <button
                      onClick={() =>
                        commentMarkPost(item.id, Name, text, image, key)
                      }
                    >
                      <BsTelegram />
                    </button>
                  </div>
                </div>
                <div
                  id="subcomment3"
                  className="comment_big_div_message_div_otevt_oyna"
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginBottom: "10px",
                      gap: "10px",
                    }}
                  >
                    <div className="comment_big_div_oyna_div_message_img">
                      <img src={image} alt="" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingRight: "10px",
                      }}
                    >
                      <div className="comment_big_div_oyna_div_message_div">
                        <h1 style={{ color: "#fff" }}>{Name}</h1>
                        <p style={{ color: "#fff" }}>{text}</p>
                      </div>
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "#fff",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => subCommentClose2(key)}
                      >
                        <AiFillCloseCircle />
                      </p>
                    </div>
                  </div>
                  <div
                    style={{ borderRadius: "5px" }}
                    className="comment_big_div_message_div"
                  >
                    <input
                      id="mark_send1"
                      value={MarkValue1}
                      onChange={(e) => setMarkValue1(e.target.value)}
                      maxLength="1"
                      type="text"
                    />
                    <button
                      onClick={() =>
                        commentMarkPut(item.id, Name, text, image, key)
                      }
                    >
                      <BsTelegram />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}

          {comment.map((item, key) => {
            return (
              <div>
                {user.map((user) => {
                  if (item.user_id == user.id && item.subcomment != 0) {
                    return (
                      <div>
                        <div className="comment_big_div_oyna_div_message_div_otvet">
                          <h1>{item.subName}</h1>
                          <p>{item.text1}</p>
                        </div>
                        <div className="comment_big_div_oyna_div_message">
                          <div className="comment_big_div_oyna_div_message_img">
                            <img src={user.image} alt="" />
                          </div>
                          <div className="comment_big_div_oyna_div_message_div">
                            <h1 style={{ color: "#000" }}>{user.username}</h1>
                            <p style={{ color: "#000" }}>{item.text}</p>
                            <div className="comment_big_div_oyna_div_message_div_delete">
                              <p
                                style={{ color: "#1890ff" }}
                                onClick={() =>
                                  subCommentModal(
                                    key,
                                    user.image,
                                    user.username,
                                    item.text,
                                    item.id
                                  )
                                }
                              >
                                <BsArrow90DegLeft />
                                Отвечать
                              </p>
                              <p onClick={() => commentdelete(item.id)}>
                                <MdDelete />
                                Удалить
                              </p>
                              {item.mark ? (
                                <p
                                  onClick={() =>
                                    subCommentModal2(
                                      key,
                                      user.image,
                                      user.username,
                                      item.text,
                                      item.id,
                                      item.markId,
                                      item.mark
                                    )
                                  }
                                  style={{ color: "#1890ff" }}
                                >
                                  <BsBookmarkCheck />
                                  {item.mark}
                                </p>
                              ) : (
                                <p
                                  onClick={() =>
                                    subCommentModal1(
                                      key,
                                      user.image,
                                      user.username,
                                      item.text,
                                      item.id
                                    )
                                  }
                                  style={{ color: "#1890ff" }}
                                >
                                  <BsBookmarkCheck />
                                  Оценка
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            );
          })}

          {/* otevet */}
        </div>

        {/* Message */}
        <div className="comment_big_div_message_div">
          <input id="message_send1" type="text" />
          <button onClick={() => commentPost()}>
            <BsTelegram />
          </button>
        </div>
      </div>
    </div>
  );
}
