import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
} from "antd";
import { AiOutlineSearch } from "react-icons/ai"
import axios from "axios"
import URL from "./Host"
import close from "./img/close.png"
import deleteImg from "./img/Inbox cleanup-rafiki.png"
import Noimg from "./img/noimg.jpg"
import Help_category from "./Help_category"


export default function Knowladge() {
  const [news, setNews] = useState([])
  const [news1, setNews1] = useState([])
  const [page, setPage] = useState(0)
  const [newsId, setNewsId] = useState()
  const [base, setBase] = useState([])
  const [newId, setNewId] = useState()
  const [dostup,setDostup]=useState(JSON.parse(sessionStorage.getItem("dos")))
  const [checkbox,setCheckbox]=useState(false)


  const name = document.querySelectorAll("#Helpusername")
  const image = document.querySelectorAll("#Helpimage")
  const description = document.querySelectorAll("#Helpdestcription")



  useEffect(() => {
    axios.get(`${URL}/api/help/`,{headers:{Authorization:"Bearer " + sessionStorage.getItem("token")}}).then(res => {
      setNews(res.data)
      setNews1(res.data)
    })
    // axios.get(`${URL}/api/base_theme`).then(res => {
    //   setBase(res.data)
    // })

  }, [])


  function newsPage(id) {
    setNewsId(id)
    setPage(1)
  }

  function postNewModal() {
    document.querySelector("#postHelp_Modal").style = "display:flex";
  }
  function postNewClose() {
    document.querySelector("#postHelp_Modal").style = "display:none";
  }

  function putNewModal(id) {
    setCheckbox(true)
    document.querySelector("#checkbox").checked=true
    setNewId(id)
    news.map(item => {
      if (item.id == id) {
        name[1].value = item.title
        description[1].value = item.description
        setTimeout(() => {
          document.querySelector("#image_malumotCheckboxHelp1").value=item.image
        }, 100);

      }
    })
    document.querySelector("#putHelp_Modal").style = "display:flex"
  }
  function putNewClose() {
    setCheckbox(false)
    document.querySelector("#putHelp_Modal").style = "display:none"
  }

  function deleteNewModal(id) {
    setNewId(id)
    document.querySelector("#deleteHelp_Modal").style = "display:flex"
  }
  function deleteNewClose() {
    document.querySelector("#deleteHelp_Modal").style = "display:none"
  }

  const columns = [
    {
      title: 'Изображение',
      dataIndex: 'image',
      key: 'image',
      render: image => <img alt={image} src={image} />
    },
    {
      title: 'Имя',
      dataIndex: 'title',
      key: 'name',
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'balance',
      render: (dataIndex) => <div>{(dostup[0].help_put || sessionStorage.getItem("position")==3)?(<button className="user_post_button" onClick={() => putNewModal(dataIndex)}>Редактировать</button>):(<div></div>)}        {(dostup[0].help_delete || sessionStorage.getItem("position")==3)?(<button className="user_post_button" onClick={() => deleteNewModal(dataIndex)}>Удалить</button>):(<div></div>)}     <button className="user_post_button_malumot" onClick={() => newsPage(dataIndex)}>Информация</button></div>,
    },
  ]

  function postNew() {
    var formdata = new FormData()
    formdata.append("title", name[0].value)
    formdata.append("description", description[0].value)
    // formdata.append("image", image[0].files[0])
    checkbox==false?formdata.append("image",document.querySelector("#Helpimage").files[0]):formdata.append("image",document.querySelector("#image_malumotCheckboxHelp").value)


    axios.post(`${URL}/api/help/`, formdata, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      alert("Добавлена информация")
      document.querySelector("#postHelp_Modal").style = "display:none";
      axios.get(`${URL}/api/help/`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
        setNews(res.data)
      })
    }).catch(err => {
      alert("Информация не добавлена")
    })
  }
  function putNew() {
    var formdata = new FormData()
    formdata.append("title", name[1].value)
    formdata.append("description", description[1].value)
    // formdata.append("image", image[1].files[0])
    checkbox==false?formdata.append("image",document.querySelector("#Helpimage1").files[0]):formdata.append("image",document.querySelector("#image_malumotCheckboxHelp1").value)


    axios.put(`${URL}/api/help/${newId}`, formdata, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      alert("Информация изменена")
      setCheckbox(false)
      document.querySelector("#putHelp_Modal").style = "display:none";
      axios.get(`${URL}/api/help/`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
        setNews(res.data)
      })
    }).catch(err => {
      alert("Информация не изменилась")
    })
  }
  function deleteNew(){
    axios.delete(`${URL}/api/help/${newId}`,{headers:{Authorization:"Bearer " + sessionStorage.getItem("token")}}).then(res=>{
      alert("Данные удалены")
      document.querySelector("#deleteHelp_Modal").style = "display:none"
      axios.get(`${URL}/api/help/`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
        setNews(res.data)
      })
    }).catch(err=>{
      alert("Данные не удалены")
    })
  }

  function Search(e) {
    const Search = news.filter(item => item.title.includes(e.target.value))
    if (e.target.value) {
      setNews(Search)
    } else {
      setNews(news1)
    }
}

function Checkbox(checked1){
  setCheckbox(checked1)
  if(checked1==true){
    news.map(item => {
      if (item.id == newId) {
        setTimeout(() => {
          document.querySelector("#image_malumotCheckboxHelp1").value=item.image
        }, 100);
  
      }
    })
  }
}

  return (
    <div>
      {page == 1 ? (
        news.map(item => {
          if (item.id == newsId) {
            return (
              <div>
                <div className="exit_button"><button onClick={() => setPage(0)}>Назад</button></div>
                <div className='news_page'>
                  {item.image == null ? (<img src={Noimg} alt={Noimg} />) : (<img src={item.image} alt={Noimg} />)}
                  <div style={{ marginTop: '20px', width: '70%' }}>
                    <h1>{item.title}</h1>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            )
          }
        })) : (<div><div className="search_big_div">{(dostup[0].help_post || sessionStorage.getItem("position")==3)?(<button onClick={() => postNewModal()} className="user_post_button">Добавить</button>):(<div></div>)}<div style={{position:'relative'}}><AiOutlineSearch className="search" /><input onChange={Search} placeholder="Введите здесь..." type="text" /></div></div>
          <Table dataSource={news} columns={columns} />
          {(dostup[0].category_get || sessionStorage.getItem("position")==3)?(<Help_category/>):(<div></div>)}
          </div>)}



      <div id="postHelp_Modal" className="Modaldiv">
        <div className="postUserModal_div" style={{ width: '340px' }}>
          <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={() => postNewClose()} /></div>
          <div style={{ display: 'flex', flexWrap: "wrap" }} className="postUserModal_div_label">
            <label htmlFor="">
              <p>Имя</p>
              <input id="Helpusername" placeholder="Имя" type="text" />
            </label>
            <label htmlFor="">
              <p>Изображение     <input type="checkbox"  onClick={(e)=>Checkbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
              {checkbox==false?(<input  id="Helpimage" type="file" />):(<input id="image_malumotCheckboxHelp" type="text" />)}
            </label>
            <label style={{width:'100%'}} htmlFor="">
              <p>Описание</p>
              <textarea className="description"  id="Helpdestcription" placeholder="Описание" type="text" />
            </label>
          </div>
          <div className="postUserModal_div_button">
            <button onClick={() => postNew()}  >Отправить</button>
          </div>
        </div>
      </div>
      <div id="putHelp_Modal" className="Modaldiv">
        <div className="postUserModal_div" style={{ width: '340px' }}>
          <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={() => putNewClose()} /></div>
          <div style={{ display: 'flex', flexWrap: "wrap" }} className="postUserModal_div_label">
            <label htmlFor="">
              <p>Имя</p>
              <input id="Helpusername" type="text" />
            </label>
            <label htmlFor="">
              <p>Изображение     <input type="checkbox" id='checkbox'  onClick={(e)=>Checkbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
              {checkbox==false?(<input  id="Helpimage1" type="file" />):(<input id="image_malumotCheckboxHelp1" type="text" />)}
            </label>
            <label style={{width:'100%'}} htmlFor="">
              <p>Описание</p>
              <textarea className="description"  id='Helpdestcription' placeholder="Описание" type="text" />
            </label>
          </div>
          <div className="postUserModal_div_button">
            <button onClick={() => putNew()}>Отправить</button>
          </div>
        </div>
      </div>
      <div id="deleteHelp_Modal" className="deleteModal">
        <div className="deleteModal_div">
          <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={() => deleteNewClose()} /></div>
          <img src={deleteImg} alt="" />
          <p>Вы хотите удалить студента</p>
          <div className="deleteButton_div">
            <button onClick={() => deleteNewClose()}>Отмена</button>
            <button onClick={()=>deleteNew()}>Подтвердить</button>
          </div>
        </div>
      </div>
    </div>
  )
}
