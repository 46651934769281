import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
} from "antd";

import { useEffect, useState } from "react";
import URL from "./Host";
import axios from "axios"
import { ToTopOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./styles/User.css"
import { AiOutlineClose } from "react-icons/ai"
import deleteImg from "./img/Inbox cleanup-rafiki.png"
import { AiOutlineSearch } from "react-icons/ai"
import close from "./img/close.png"
import Item from "antd/lib/list/Item";



const { Title } = Typography;

const formProps = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

function Teacher() {
  const [teacher, setTeacher] = useState([])
  const [teacher1, setTeacher1] = useState([])
  const [teacherId, setTeacherId] = useState()
  const [positionId, setpositionId] = useState([])
  const [styleId, setStyleId] = useState()
  const [ban, setBan] = useState()
  const [page, setPage] = useState(0)
  const [checkbox,setCheckbox]=useState(false)
  const [dostup,setDostup]=useState(JSON.parse(sessionStorage.getItem("dos")))



  const username = document.querySelectorAll("#username")
  const last_name = document.querySelectorAll("#last_name")
  const phone_number = document.querySelectorAll("#phone_number")
  const email = document.querySelectorAll("#email")
  const address = document.querySelectorAll("#address")
  const password = document.querySelectorAll("#password")
  const position = document.querySelectorAll("#position")
  const image = document.querySelectorAll("#image")
  const description = document.querySelectorAll("#description")

  useEffect(() => {
    axios.get(`${URL}/auth/users`, { headers: { Authorization: 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
      const studentFilter = res.data.filter(item => item.position === 2)
      setTeacher(studentFilter)
      setTeacher1(studentFilter)
      studentFilter.map(item => {
        setStyleId(item.position)
        setpositionId(item.position)
      })
    })
  }, [])

  function postTeacherModal() {
    document.querySelector("#postTeacherModal").style = "display:flex"
  }
  function postTeacherClose() {
    document.querySelector("#postTeacherModal").style = "display:none"
  }
  function putTeacherModal(id) {
    setTeacherId(id)
    // teacher.map(item=>{
    //   if(item.id==id){
    //     address[1].value=item.address
    //     description[1].value=item.description
    //     email[1].value=item.email
    //     last_name[1].value=item.last_name
    //     password[1].value=item.password
    //     phone_number[1].value=item.phone_number
    //     username[1].value=item.username
    //     position[1].value=item.position
    //   }
    // })
    document.querySelector("#putTeacherModal").style = "display:flex"
  }
  function putTeacherClose() {
    document.querySelector("#putTeacherModal").style = "displaynone"
  }
  function deleteTeacherModal(id) {
    setTeacherId(id)
    document.querySelector("#deleteTeacherModal").style = "display:flex"
  }
  function deleteTeacherClose() {
    document.querySelector("#deleteTeacherModal").style = "display:none"
  }

  function postTeacher() {
    var formdata = new FormData()
    formdata.append("address", username[0].value)
    formdata.append("balance", 0)
    formdata.append("description", description[0].value)
    formdata.append("email", email[0].value)
    checkbox==false?formdata.append("image",image[0].files[0]):formdata.append("image",document.querySelector("#imageChecboxs").value)
    formdata.append("last_name", last_name[0].value)
    formdata.append("phone_number", phone_number[0].value)
    formdata.append("username", username[0].value)
    formdata.append("password", password[0].value)
    formdata.append("position", positionId)
    formdata.append("youtobe",document.querySelector("#youtube_tables").value)
    formdata.append("telegram",document.querySelector("#telegram_tables").value)
    formdata.append("instagram",document.querySelector("#instagram_tables").value)


    axios.post(`${URL}/auth/users`, formdata, { headers: { "Authorization": "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      alert("Добавлена информация")
      document.querySelector("#postTeacherModal").style = "display:none"
      axios.get(`${URL}/auth/users`, { headers: { Authorization: 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
        const studentFilter = res.data.filter(item => item.position === 2)
        setTeacher(studentFilter)
      })
    })
      .catch(err => {
        alert("Это адрес электронной почты или имя, пароль введен")
      })
  }
  function deleteTeacher() {
    axios.delete(`${URL}/auth/users/${teacherId}`, { headers: { Authorization: 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
      alert("Данные удалены")
      document.querySelector("#deleteTeacherModal").style = "display:none"
      axios.get(`${URL}/auth/users`, { headers: { Authorization: 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
        const studentFilter = res.data.filter(item => item.position === 2)
        setTeacher(studentFilter)
      })
    })
      .catch(err => {
        alert("Данные не удалены")
      })
  }
  function putTeacher() {
    var formdata = new FormData()
    formdata.append("address", document.querySelector("#address_malumot").value)
    formdata.append("balance", document.querySelector("#balance_malumot").value)
    formdata.append("description", document.querySelector("#description_malumot").value)
    formdata.append("email", document.querySelector("#email_malumot").value)
    formdata.append("last_name", document.querySelector("#last_name_malumot").value)
    formdata.append("password", document.querySelector("#password_malumot").value)
    formdata.append("phone_number", document.querySelector("#phone_number_malumot").value)
    formdata.append("username", document.querySelector("#username_malumot").value)
    formdata.append("position", positionId)
    formdata.append("youtobe",document.querySelector("#youtube_tables1").value)
    formdata.append("telegram",document.querySelector("#telegram_tables1").value)
    formdata.append("instagram",document.querySelector("#instagram_tables1").value)
    checkbox==false?formdata.append("image", document.querySelector("#image_malumot").files[0]):formdata.append("image", document.querySelector("#image_malumotCheckbox").value)  

    axios.put(`${URL}/auth/userssuperadmin/${teacherId}`, formdata, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      alert("Информация изменена")
      setPage(0)
      setCheckbox(false)
      axios.get(`${URL}/auth/users`, { headers: { Authorization: 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
        const studentFilter = res.data.filter(item => item.position === 2)
        setTeacher(studentFilter)
      })
    }).catch(err => {
      alert("Информация не изменилась")
    })
  }
  function PositionId(id) {
    setpositionId(id)
  }

  function Ban(id, dataindex) {
    var formdata = new FormData()
    formdata.append("is_active", id)

    axios.put(`${URL}/auth/ban/${dataindex}`, formdata, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      axios.get(`${URL}/auth/users`, { headers: { "Authorization": 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
        window.location.reload()
        const studentFilter = res.data.filter(item => item.position === 2)
        setTeacher(studentFilter)
        studentFilter.map(item => {
          console.log(item);
          setBan(item.is_active)
        })
      })
    }).catch(err => {
      alert("Бан не был")
    })
  }

  function pageMalumot(id) {
    setCheckbox(true)
    setPage(1)
    setTimeout(() => {
      teacher.map(item => {
        if (item.id == id) {
          document.querySelector("#username_malumot").value = item.username
          document.querySelector("#last_name_malumot").value = item.last_name
          document.querySelector("#address_malumot").value = item.address
          document.querySelector("#email_malumot").value = item.email
          document.querySelector("#phone_number_malumot").value = item.phone_number
          document.querySelector("#description_malumot").value = item.description
          document.querySelector("#youtube_tables1").value = item.youtobe
          document.querySelector("#telegram_tables1").value = item.telegram
          document.querySelector("#instagram_tables1").value = item.instagram
          setTimeout(() => {
            document.querySelector("#taecherCheckbox").checked=true 
            document.querySelector("#image_malumotCheckbox").value =item.image
          }, 100);
        }
      })
    }, 10);
    setTeacherId(id)

  }

  function BanFilter(id) {
    axios.get(`${URL}/auth/users`, { headers: { "Authorization": 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
      const Filter = res.data.filter(item => item.is_active == id && item.position==2)
      setTeacher(Filter)
    })
  }

  function BanFilter1() {
    axios.get(`${URL}/auth/users`, { headers: { "Authorization": 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
      const Filter = res.data.filter(item => item.position==2)
      setTeacher(Filter)
    })
  }

  const columns = [
    {
      title: 'Изображение',
      dataIndex: 'image',
      key: 'image',
      render: image => <img alt={image} src={image} />
    },
    {
      title: 'Имя',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Фамилия',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: <div><Radio.Group  defaultValue="c">
        <Radio.Button onClick={()=>BanFilter(true)} value="a">забанить</Radio.Button>
        <Radio.Button onClick={()=>BanFilter(false)} value="b">разбанить</Radio.Button>
        <Radio.Button onClick={()=>BanFilter1()} value="c">Все</Radio.Button>
      </Radio.Group></div>,
      dataIndex: 'id',
      key: 'balance',
      render: (dataIndex) => <div style={{display:'flex',alignItems:'center',gap:'5px'}}>{teacher.map(item => { if (item.id == dataIndex) { return <div>{false === item.is_active ? (<button onClick={() => Ban(true, dataIndex)} className="user_post_button_ban">разбанить</button>) : (<button onClick={() => Ban(false, dataIndex)} className="user_post_button_bandan_olish">забанить</button>)}</div> } })}   
      {(dostup[0].teacher_delete || sessionStorage.getItem("position")==3)?(<button onClick={() => deleteTeacherModal(dataIndex)} className="user_post_button">Удалить</button>):(<div></div>)}  
          {(dostup[0].teacher_put || sessionStorage.getItem("position")==3)?(<button onClick={() => pageMalumot(dataIndex)} className="user_post_button_malumot">Информация</button>):(<div></div>)}</div>,
    },
  ]

  function Search(e) {
    const Serach = teacher.filter(item => item.username.includes(e.target.value))
    if (e.target.value) {
      setTeacher(Serach)
    } else {
      setTeacher(teacher1)
    }
  }

  function Checkbox(checked1){
    setCheckbox(checked1)
    if(checked1==true){
      teacher.map(item => {
        if (item.id == teacherId) {
          setTimeout(() => {
            document.querySelector("#image_malumotCheckbox").value =item.image
          }, 100);
        }
      })
    }
  }


  return (
    <div>
      {page == 1 ? (
        <div>
          <div className="exit_button"><button onClick={() => {setPage(0);setCheckbox(false)}}>Назад</button></div>
          <div className="malumot_big_div">
            <label htmlFor="">
              <p>Имя</p>
              <input id="username_malumot" type="text" />
            </label>
            <label htmlFor="">
              <p>Фамилия</p>
              <input id="last_name_malumot" type="text" />
            </label>
            <label htmlFor="">
              <p>Адрес</p>
              <input id="address_malumot" type="text" />
            </label>
            <label htmlFor="">
              <p>Баланс</p>
              {teacher.map(item => {
                if (item.id == teacherId) {
                  return <input id="balance_malumot" value={item.balance} type="password" />
                }
              })}
            </label>
            <label htmlFor="">
              <p>Дата присоединения</p>
              {teacher.map(item => {
                if (item.id == teacherId) {
                  return <input id="date_joined_malumot" value={item.date_joined.slice(0, 10)} type="text" />
                }
              })}
            </label>
            <label htmlFor="">
              <p>Электронная почта</p>
              <input id="email_malumot" type="text" />
            </label>
            <label htmlFor="">
              <p>Пароль</p>
              {teacher.map(item => {
                if (item.id == teacherId) {
                  return <input id="password_malumot" value={item.password} type="password" />
                }
              })}
            </label>
            <label htmlFor="">
              <p>Номер телефона</p>
              <input id="phone_number_malumot" type="text" />
            </label>
            <label htmlFor="">
            <p>Youtube link</p>
            <input  id="youtube_tables1"  type="text" />
            </label>
            <label htmlFor="">
            <p>Telegram link</p>
            <input id="telegram_tables1"   type="text" />
            </label>
            <label htmlFor="">
            <p>Instagram link</p>
            <input id="instagram_tables1"  type="text" />
            </label>
            {teacher.map(item => {
              if (item.id == teacherId) {
                return <div>
                  <label htmlFor="">
                    <p>Время создавать</p>
                    <input id="time_create_malumot" value={item.time_create.slice(0, 10)} type="text" />
                  </label>
                  <label htmlFor="">
                    <p>Обновление времени</p>
                    <input id="time_update_malumot" value={item.time_update.slice(0, 10)} type="text" />
                  </label>
                </div>
              }
            })}
            <label htmlFor="">
              <p>Позиция</p>
              <div className="position">
                <button className="position_button" onClick={() => PositionId(2)} style={styleId == 2 ? { backgroundColor: '#1890ff', color: '#fff' } : {}}>Учитель</button>
              </div>
            </label>
            <label htmlFor="">
              <p>Изображение      <input type="checkbox" id="taecherCheckbox" onClick={(e)=>Checkbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
              {checkbox==false?(<input  id="image_malumot" type="file" />):(<input id="image_malumotCheckbox" type="text" />)}
            </label>
            <label htmlFor="">
              <p>Описание</p>
              <input id="description_malumot" type="text" />
            </label>
          </div>
          <div className="postUserModal_div_button"><button className="user_post_button_malumot" onClick={() => putTeacher()} >Редактировать</button></div>
        </div>
      ) : (
        <div><div className="search_big_div">
        {(dostup[0].teacher_post || sessionStorage.getItem("position")==3)?(<button onClick={() => postTeacherModal()} className="user_post_button">Добавить</button>):(<div></div>)}
        <div style={{position:'relative'}}><AiOutlineSearch className="search" /><input onChange={Search} placeholder="Введите здесь..." type="text" /></div></div>
          <Table dataSource={teacher} columns={columns} /></div>)}

      <div id="deleteTeacherModal" className="deleteModal">
        <div className="deleteModal_div">
          <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={() => deleteTeacherClose()} /></div>
          <img src={deleteImg} alt="" />
          <p>Вы хотите удалить студента</p>
          <div className="deleteButton_div">
            <button onClick={() => deleteTeacherClose()}>Отмена</button>
            <button onClick={() => deleteTeacher()}>Подтвердить</button>
          </div>
        </div>
      </div>
      <div id="postTeacherModal" className="Modaldiv">
        <div className="postUserModal_div">
          <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={() => postTeacherClose()} /></div>
          <div className="postUserModal_div_label">
            <label htmlFor="">
              <p>Имя пользователя</p>
              <input id="username" placeholder="Имя пользователя" type="text" />
            </label>
            <label htmlFor="">
              <p>Фамилия</p>
              <input id="last_name" placeholder="Фамилия" type="text" />
            </label>
            <label htmlFor="">
              <p>Телефон номер</p>
              <input id="phone_number" placeholder="Телефон номер" type="text" />
            </label>
            <label htmlFor="">
              <p>Электронная почта</p>
              <input id="email" placeholder="Электронная почта" type="text" />
            </label>
            <div style={{display:'flex',justifyContent:'space-between'}}>
            <label htmlFor="">
            <p>Youtube link</p>
            <input style={{width:'90px'}} id="youtube_tables"  type="text" />
            </label>
            <label htmlFor="">
            <p>Telegram link</p>
            <input id="telegram_tables"  style={{width:'90px'}} type="text" />
            </label>
            <label htmlFor="">
            <p>Instagram link</p>
            <input id="instagram_tables" style={{width:'90px'}} type="text" />
            </label>
            </div>  
            <label htmlFor="">
              <p>Адрес</p>
              <input id="address" placeholder="Адрес" type="text" />
            </label>
            <label htmlFor="">
              <p>Пароль</p>
              <input id="password" placeholder="Пароль" type="text" />
            </label>
            <label htmlFor="">
              <p>Позиция</p>
              <div className="position">
                <button onClick={() => PositionId(2)} style={positionId == 2 ? { backgroundColor: '#1890ff', color: '#fff' } : {}}>Учитель</button>
              </div>
            </label>
            <label htmlFor="">
              <p>Изображение     <input type="checkbox"  onClick={(e)=>Checkbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
              {checkbox==false?(<input id="image" className="image" placeholder="Изображение" type="file" />):(<input id="imageChecboxs" className="image" placeholder="Изображение" type="text" />)}
            </label>
          </div>
          <label htmlFor="">
            <p>Описание</p>
            <textarea id="description" placeholder="Описание" className="description" type="text" />
          </label>
          <div className="postUserModal_div_button">
            <button onClick={() => postTeacher()}>Отправить</button>
          </div>
        </div>
      </div>
      {/* <div id="putTeacherModal" className="Modaldiv">
      <div className="postUserModal_div">
      <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={()=>putTeacherClose()}/></div>
         <div className="postUserModal_div_label">
        <label htmlFor="">
          <p>Имя пользователя</p>
          <input id="username"  type="text" />
        </label>
        <label htmlFor="">
          <p>Фамилия</p>
          <input id="last_name"  type="text" />
        </label>
        <label htmlFor="">
          <p>Телефон номер</p>
          <input id="phone_number"  type="text" />
        </label>
        <label htmlFor="">
          <p>Электронная почта</p>
          <input id="email"  type="text" />
        </label>
        <label htmlFor="">
          <p>Адрес</p>
          <input id="address"  type="text" />
        </label>
        <label htmlFor="">
          <p>Пароль</p>
          <input id="password"  type="text" />
        </label>
        <label htmlFor="">
          <p>Позиция</p>
          <input id="position"  type="text" />
        </label>
        <label htmlFor="">
          <p>Изображение</p>
          <input id="image"  type="file" />
        </label>
        </div>
        <label htmlFor="">
          <p>Описание</p>
          <input id="description" placeholder="Описание" className="description" type="text" />
        </label>
        <div className="postUserModal_div_button">
        <button >Редактировать</button>
        </div>
      </div>
    </div> */}
    </div>
  );
}

export default Teacher;
