import axios from "axios";
import React, { useEffect, useState } from "react";
import URL from "./Host";
import { MdEmail } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import close from "./img/close.png";
import deleteImg from "./img/Inbox cleanup-rafiki.png";
import { BsTelephoneFill } from "react-icons/bs";

export default function Operator() {
  const [partner, setPartner] = useState([]);
  const [operator_work, setOperator_work] = useState([]);
  const [operatorId, setOperatorId] = useState([]);
  const [page, setPage] = useState(0);
  const [pageID, setPageID] = useState(0);
   const [checkbox,setCheckbox]=useState(false)

  const image = document.querySelectorAll("#OperatorImage");
  const name = document.querySelectorAll("#OperatorName");
  const destcription = document.querySelectorAll("#OperatorDescription");
  const email = document.querySelectorAll("#OperatorEmail");
  const twiter = document.querySelectorAll("#OperatorTwiter");
  const call_me = document.querySelectorAll("#OperatorCall_me");
  const WhatsApp = document.querySelectorAll("#OperatorWhatsapp");
  const work=document.querySelectorAll("#Operator_Work_Name")

  useEffect(() => {
    axios
      .get(`${URL}/api/operator`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        setPartner(res.data);
      });
  }, []);

  function deleteOperator() {
    axios
      .delete(`${URL}/api/operator/${operatorId}`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Оператор удален");
        document.querySelector("#deleteOperator").style = "display:none";
        axios
          .get(`${URL}/api/operator`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setPartner(res.data);
          });
      })
      .catch((err) => {
        alert("Не удалось удалить оператора");
      });
  }

  function postOperator() {
    var formdata = new FormData();
    // formdata.append("image", image[0].files[0]);
    checkbox==false?formdata.append("image",document.querySelector("#OperatorImage").files[0]):formdata.append("image",document.querySelector("#image_malumotCheckboxOperator").value)
    formdata.append("name", name[0].value);
    formdata.append("description", destcription[0].value);
    formdata.append("email", email[0].value);
    formdata.append("twiter", twiter[0].value);
    formdata.append("call_me", call_me[0].value);
    formdata.append("whatsapp", WhatsApp[0].value);

    axios
      .post(`${URL}/api/operator`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Оператор добавлен");
        document.querySelector("#postOperator").style = "display:none";
        axios
          .get(`${URL}/api/operator`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setPartner(res.data);
          });
      })
      .catch((err) => {
        alert("Оператор не добавлен");
      });
  }

  function putOperator() {
    var formdata = new FormData();
    // formdata.append("image", image[1].files[0]);
    checkbox==false?formdata.append("image",document.querySelector("#OperatorImage1").files[0]):formdata.append("image",document.querySelector("#image_malumotCheckboxOperator1").value)
    formdata.append("name", name[1].value);
    formdata.append("description", destcription[1].value);
    formdata.append("email", email[1].value);
    formdata.append("twiter", twiter[1].value);
    formdata.append("call_me", call_me[1].value);
    formdata.append("whatsapp", WhatsApp[1].value);

    axios
      .put(`${URL}/api/operator/${operatorId}`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        setCheckbox(false)
        alert("Сменился оператор");
        document.querySelector("#putOperator").style = "display:none";
        axios
          .get(`${URL}/api/operator`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setPartner(res.data);
          });
      })
      .catch((err) => {
        alert("Оператор не менялся");
      });
  }

  function postOperatorOpen() {
    document.querySelector("#postOperator").style = "display:flex";
  }

  function postOperatorClose() {
    document.querySelector("#postOperator").style = "display:none";
  }

  function putOperatorOpen(
    id,
    name1,
    deckription1,
    email1,
    twiter1,
    call_me1,
    whatsapp1,
    image
  ) {
    setCheckbox(true)
    setOperatorId(id);
    name[1].value = name1;
    destcription[1].value = deckription1;
    email[1].value = email1;
    twiter[1].value = twiter1;
    call_me[1].value = call_me1;
    WhatsApp[1].value = whatsapp1;
    setTimeout(() => {
      document.querySelector("#checkboxOperator").checked=true
      document.querySelector("#image_malumotCheckboxOperator1").value=image
    }, 100);
    document.querySelector("#putOperator").style = "display:flex";
  }

  function putOperatorClose() {
    setCheckbox(false)
    document.querySelector("#putOperator").style = "display:none";
  }

  function deleteOperatorOpen(id) {
    setOperatorId(id);
    document.querySelector("#deleteOperator").style = "display:flex";
  }

  function deleteOperatorClose() {
    document.querySelector("#deleteOperator").style = "display:none";
  }

  function Page(id) {
    setPage(1);
    setPageID(id)
    axios
      .get(`${URL}/api/operator_work`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        const Filter=res.data.filter(item=>item.opertor_id==id)
        setOperator_work(Filter);
      });
  }

  function postWork(){
    var formdata=new FormData()
    formdata.append("title",work[0].value)
    formdata.append("opertor_id",pageID)

    axios.post(`${URL}/api/operator_work`,formdata,{headers:{Authorization:"Bearer "+sessionStorage.getItem("token")}}).then(res=>{
    alert("Вакансия добавлена ​​оператору")
    document.querySelector("#postOperator_Work").style = "display:none";
    axios
    .get(`${URL}/api/operator_work`, {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    })
    .then((res) => {
      const Filter=res.data.filter(item=>item.opertor_id==pageID)
      setOperator_work(Filter);
    });
    }).catch(err=>{
     alert("К оператору не добавлено вакансий")
    })
  }
  function putWork(){
    var formdata=new FormData()
    formdata.append("title",work[1].value)
    formdata.append("opertor_id",pageID)

    axios.put(`${URL}/api/operator_work/${pageID}`,formdata,{headers:{Authorization:"Bearer "+sessionStorage.getItem("token")}}).then(res=>{
    alert("Изменена работа оператора")
    document.querySelector("#putOperator_Work").style = "display:none";
    axios
    .get(`${URL}/api/operator_work`, {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    })
    .then((res) => {
      const Filter=res.data.filter(item=>item.opertor_id==pageID)
      setOperator_work(Filter);
    });
    }).catch(err=>{
     alert("Работа оператора не изменилась")
    })
  }

  function deleteOperatorWork(id) {
    axios
      .delete(`${URL}/api/operator_work/${id}`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Оператор удален");
    })
      .catch((err) => {
        alert("Не удалось удалить оператора");
      });
  }

  
  function postOperatorWorkOpen() {
    document.querySelector("#postOperator_Work").style = "display:flex";
  }

  function postOperatorWorkClose() {
    document.querySelector("#postOperator_Work").style = "display:none";
  }

  function putOperator_Work_Open(
    id,
    name1,
  ) {
    setOperatorId(id);
    work[1].value=name1
    document.querySelector("#putOperator_Work").style = "display:flex";
  }

  function putOperator_Work_Close() {
    document.querySelector("#putOperator_Work").style = "display:none";
  }

  function Checkbox(checked1){
    setCheckbox(checked1)
    if(checked1==true){
      partner.map(item => {
        if (item.id == operatorId) {
          setTimeout(() => {
            document.querySelector("#image_malumotCheckboxOperator1").value=item.image
          }, 100);
    
        }
      })
    }
  }

  return (
    <div>
      {page == 1 ? (
        <div>
          <button
            onClick={() => {
              setPage(0);
            }}
            className="user_post_button_malumot"
          >
            Выход
          </button>
          <button
            onClick={()=>postOperatorWorkOpen()}
            style={{ marginLeft: "10px" }}
            className="user_post_button_malumot"
          >
            Добавлять
          </button>
          <div style={{display:'flex',alignItems:'center',justifyContent:'space-around',flexWrap:'wrap'}}>
          {operator_work.map((item) => {
           return<div className="div_card_title">
              <p>{item.title}</p>
              <div className="div_card_title_button">
                <p style={{color:'red'}} onClick={()=>deleteOperatorWork(item.id)}>Delete</p>
                <p onClick={()=>putOperator_Work_Open(item.id,item.title)}>Edit</p>
              </div>
            </div> 
          })}
          </div>
          <div id="postOperator_Work" className="Modaldiv">
          <div className="postUserModal_div" style={{width:'340px'}}>
            <div className="postUserModal_div_icon">
              <img
                src={close}
                className="icon"
                onClick={() => postOperatorWorkClose()}
              />
            </div>
            <div
              style={{ display: "flex", flexWrap: "wrap" }}
              className="postUserModal_div_label"
            >
              <label htmlFor="">
                <p>Заголовок</p>
                <input id="Operator_Work_Name" type="text" />
              </label>
            </div>
            <div className="postUserModal_div_button">
              <button onClick={() => postWork()}>Отправить</button>
            </div>
          </div>
        </div>
        <div id="putOperator_Work" className="Modaldiv">
          <div className="postUserModal_div" style={{width:'340px'}}>
            <div className="postUserModal_div_icon">
              <img
                src={close}
                className="icon"
                onClick={() => putOperator_Work_Close()}
              />
            </div>
            <div
              style={{ display: "flex", flexWrap: "wrap" }}
              className="postUserModal_div_label"
            >
              <label htmlFor="">
                <p>Заголовок</p>
                <input id="Operator_Work_Name" type="text" />
              </label>
            </div>
            <div className="postUserModal_div_button">
              <button onClick={() => putWork()}>Отправить</button>
            </div>
          </div>
        </div>
        </div>
      ) : (
        <div>
          <button
            onClick={() => postOperatorOpen()}
            className="user_post_button_malumot"
          >
            Добавлять
          </button>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              flexWrap: "wrap",
            }}
          >
            {partner.map((item) => {
              return (
                <div id="cards11" className="cards">
                  <div className="forsa_img">
                    <img style={{ maxWidth: "none" }} src={item.image} alt="" />
                  </div>
                  <h1>
                    <a href="#">{item.name}</a>
                  </h1>
                  <p>{item.description}</p>
                  <div className="two">
                    <div className="icon">
                      {/* <a style={{color:"black"}} href={{mailto:${item.twiter}}}><div className="dumalo"><GrFacebookOption/></div></a> */}
                      <a style={{ color: "black" }} href={item.whatsapp}>
                        <div className="dumalo">
                          <IoLogoWhatsapp />
                        </div>
                      </a>
                      <a
                        style={{ color: "black" }}
                        href={`tel:${item.call_me}`}
                      >
                        <div className="dumalo">
                          <BsTelephoneFill />
                        </div>
                      </a>
                      <a
                        style={{ color: "black" }}
                        href={`mailto:${item.email}`}
                      >
                        <div className="dumalo">
                          <MdEmail />
                        </div>
                      </a>
                    </div>
                    <div
                      style={{ display: "flex", gap: "10px" }}
                      className="view"
                    >
                      <h4 onClick={() => Page(item.id)}>Work</h4>
                      <h4
                        onClick={() => deleteOperatorOpen(item.id)}
                        style={{ color: "red" }}
                      >
                        delete
                      </h4>
                      <h4
                        onClick={() =>
                          putOperatorOpen(
                            item.id,
                            item.name,
                            item.description,
                            item.email,
                            item.twiter,
                            item.call_me,
                            item.whatsapp,
                            item.image
                          )
                        }
                      >
                        edit
                      </h4>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div id="postOperator" className="Modaldiv">
            <div className="postUserModal_div">
              <div className="postUserModal_div_icon">
                <img
                  src={close}
                  className="icon"
                  onClick={() => postOperatorClose()}
                />
              </div>
              <div
                style={{ display: "flex", flexWrap: "wrap" }}
                className="postUserModal_div_label"
              >
                <label htmlFor="">
                  <p>Изображение     <input type="checkbox"   onClick={(e)=>Checkbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
                  {checkbox==false?(<input  id="OperatorImage" type="file" />):(<input id="image_malumotCheckboxOperator" type="text" />)}
                </label>
                <label htmlFor="">
                  <p>Имя</p>
                  <input id="OperatorName" type="text" />
                </label>
                <label htmlFor="">
                  <p>Описание</p>
                  <input id="OperatorDescription" type="text" />
                </label>
                <label htmlFor="">
                  <p>Электронная почта</p>
                  <input id="OperatorEmail" type="text" />
                </label>
                <label htmlFor="">
                  <p>Твиттер</p>
                  <input id="OperatorTwiter" type="text" />
                </label>
                <label htmlFor="">
                  <p>Позвоните мне</p>
                  <input id="OperatorCall_me" type="text" />
                </label>
                <label htmlFor="">
                  <p>WhatsApp</p>
                  <input id="OperatorWhatsapp" type="text" />
                </label>
              </div>
              <div className="postUserModal_div_button">
                <button onClick={() => postOperator()}>Отправить</button>
              </div>
            </div>
          </div>
          <div id="putOperator" className="Modaldiv">
            <div className="postUserModal_div" >
              <div className="postUserModal_div_icon">
                <img
                  src={close}
                  className="icon"
                  onClick={() => putOperatorClose()}
                />
              </div>
              <div
                style={{ display: "flex", flexWrap: "wrap" }}
                className="postUserModal_div_label"
              >
                <label htmlFor="">
                  <p>Изображение      <input type="checkbox" id='checkboxOperator'  onClick={(e)=>Checkbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
                  {checkbox==false?(<input  id="OperatorImage1" type="file" />):(<input id="image_malumotCheckboxOperator1" type="text" />)}
                </label>
                <label htmlFor="">
                  <p>Имя</p>
                  <input id="OperatorName" type="text" />
                </label>
                <label htmlFor="">
                  <p>Описание</p>
                  <input id="OperatorDescription" type="text" />
                </label>
                <label htmlFor="">
                  <p>Электронная почта</p>
                  <input id="OperatorEmail" type="text" />
                </label>
                <label htmlFor="">
                  <p>Твиттер</p>
                  <input id="OperatorTwiter" type="text" />
                </label>
                <label htmlFor="">
                  <p>Позвоните мне</p>
                  <input id="OperatorCall_me" type="text" />
                </label>
                <label htmlFor="">
                  <p>WhatsApp</p>
                  <input id="OperatorWhatsapp" type="text" />
                </label>
              </div>
              <div className="postUserModal_div_button">
                <button onClick={() => putOperator()}>Отправить</button>
              </div>
            </div>
          </div>
          <div id="deleteOperator" className="deleteModal">
            <div className="deleteModal_div">
              <div className="postUserModal_div_icon">
                <img
                  src={close}
                  className="icon"
                  onClick={() => deleteOperatorClose()}
                />
              </div>
              <img src={deleteImg} alt="" />
              <p>Вы хотите удалить Оператор</p>
              <div className="deleteButton_div">
                <button onClick={() => deleteOperatorClose()}>Отмена</button>
                <button onClick={() => deleteOperator()}>Подтвердить</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
