import {
  Row,
  Col,
  Card,
  Statistic,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
} from "antd";

import { PlusOutlined, ExclamationOutlined } from "@ant-design/icons";
import mastercard from "../assets/images/mastercard-logo.png";
import paypal from "../assets/images/paypal-logo-2.png";
import visa from "../assets/images/visa-logo.png";
import { useEffect, useState } from "react";
import axios from "axios";
import URL from "./Host";
import close from "./img/close.png"


function Billing() {
  const [registerCourse, setRegisterCourse] = useState([])
  const [course, setCourse] = useState([])
  const [users, setUsers] = useState([])
  const [Payment, setPayment] = useState([])
  const [Payment1, setPayment1] = useState([])
  const [Payment_Filter, setPayment_Filter] = useState([])
  const [Payment_Filter1, setPayment_Filter1] = useState([])
  const [Kluch, setKluch] = useState([])
  const [KluchId, setKluchId] = useState()
  const PayPal = document.querySelectorAll("#PayPal_payment")
  const Strip = document.querySelectorAll("#Strip_payment")
  const Publik = document.querySelectorAll("#Public_payment")

  const data = [
    {
      title: "March, 01, 2021",
      description: "#MS-415646",
      amount: "$180",
    },
    {
      title: "February, 12, 2021",
      description: "#RV-126749",
      amount: "$250",
    },
    {
      title: "April, 05, 2020",
      description: "#FB-212562",
      amount: "$550",
    },
    {
      title: "June, 25, 2019",
      description: "#QW-103578",
      amount: "$400",
    },
    {
      title: "March, 03, 2019",
      description: "#AR-803481",
      amount: "$700",
    },
  ];

  const wifi = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 22.5 20.625"
      key={0}
    >
      <g id="wifi" transform="translate(0.75 0.75)">
        <circle
          id="Oval"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(9 16.875)"
          fill="#fff"
        ></circle>
        <path
          id="Path"
          d="M0,1.36a6.377,6.377,0,0,1,7.5,0"
          transform="translate(6.75 11.86)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></path>
        <path
          id="Path-2"
          data-name="Path"
          d="M14.138,2.216A12.381,12.381,0,0,0,0,2.216"
          transform="translate(3.431 6)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></path>
        <path
          id="Path-3"
          data-name="Path"
          d="M0,3.294a18.384,18.384,0,0,1,21,0"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></path>
      </g>
    </svg>,
  ];

  const angle = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      key={0}
    >
      <g id="bank" transform="translate(0.75 0.75)">
        <path
          id="Shape"
          transform="translate(0.707 9.543)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></path>
        <path
          id="Path"
          d="M10.25,0,20.5,9.19H0Z"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></path>
        <path
          id="Path-2"
          data-name="Path"
          d="M0,.707H20.5"
          transform="translate(0 19.793)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        ></path>
      </g>
    </svg>,
  ];

  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];
  const download = [
    <svg
      width="15"
      height="15"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key="0"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 17C3 16.4477 3.44772 16 4 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17ZM6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L9 10.5858L9 3C9 2.44772 9.44771 2 10 2C10.5523 2 11 2.44771 11 3L11 10.5858L12.2929 9.29289C12.6834 8.90237 13.3166 8.90237 13.7071 9.29289C14.0976 9.68342 14.0976 10.3166 13.7071 10.7071L10.7071 13.7071C10.5196 13.8946 10.2652 14 10 14C9.73478 14 9.48043 13.8946 9.29289 13.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289Z"
        fill="#111827"
      ></path>
    </svg>,
  ];
  const deletebtn = [
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2C8.62123 2 8.27497 2.214 8.10557 2.55279L7.38197 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6L4 16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H12.618L11.8944 2.55279C11.725 2.214 11.3788 2 11 2H9ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V8ZM12 7C11.4477 7 11 7.44772 11 8V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V8C13 7.44772 12.5523 7 12 7Z"
        fill="#111827"
        className="fill-danger"
      ></path>
    </svg>,
  ];

  const information = [
    {
      title: "Oliver Liam",
      description: "Viking Burrito",
      address: "oliver@burrito.com",
      vat: "FRB1235476",
    },
    {
      title: "Lucas Harper",
      description: "Stone Tech Zone",
      address: "lucas@syone-tech.com",
      vat: "FRB1235476",
    },
    {
      title: "Oliver Liam",
      description: "ethan@fiber.com",
      vat: "NumberFRB1235476",
    },
  ];
  const calender = [
    <svg
      width="18"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
        fill="#111827"
        className="fill-muted"
      ></path>
    </svg>,
  ];
  const mins = [
    <svg
      width="10"
      height="10"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 10C5 9.44772 5.44772 9 6 9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11L6 11C5.44772 11 5 10.5523 5 10Z"
        className="fill-danger"
      ></path>
    </svg>,
  ];
  const newest = [
    {
      headding: <h6>NEWEST</h6>,
      avatar: mins,
      title: "Netflix",
      description: "27 March 2021, at 12:30 PM",
      amount: "- $2,500",
      textclass: "text-light-danger",
      amountcolor: "text-danger",
    },
    {
      avatar: <PlusOutlined style={{ fontSize: 10 }} />,
      title: "Apple",
      description: "27 March 2021, at 04:30 AM",
      amount: "+ $2,000",
      textclass: "text-fill",
      amountcolor: "text-success",
    },
  ];
  const yesterday = [
    {
      avatar: <PlusOutlined style={{ fontSize: 10 }} />,
      title: "Stripe",
      description: "26 March 2021, at 12:30 AM",
      amount: "+ $750",
      textclass: "text-fill",
      amountcolor: "text-success",
    },
    {
      avatar: <PlusOutlined style={{ fontSize: 10 }} />,
      title: "HubSpot",
      description: "26 March 2021, at 11:30 AM",
      amount: "+ $1,050",
      textclass: "text-fill",
      amountcolor: "text-success",
    },
    {
      avatar: <PlusOutlined style={{ fontSize: 10 }} />,
      title: "Creative Tim",
      description: "26 March 2021, at 07:30 AM",
      amount: "+ $2,400",
      textclass: "text-fill",
      amountcolor: "text-success",
    },
    {
      avatar: <ExclamationOutlined style={{ fontSize: 10 }} />,
      title: "Webflow",
      description: "26 March 2021, at 04:00 AM",
      amount: "Pending",
      textclass: "text-warning",
      amountcolor: "text-warning-b",
    },
  ];

  useEffect(() => {
    axios.get(`${URL}/api/super/registerCourse`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      setRegisterCourse(res.data)
    })
    axios.get(`${URL}/api/course`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      setCourse(res.data)
    })
    axios.get(`${URL}/auth/users`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      setUsers(res.data)
    })

    axios.get(`${URL}/pay/payment`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      const Filter = res.data.filter(item => item.type == 2)
      setPayment(Filter)
      var a = 0
      for (let i = 0; i < Filter.length; i++) {
        a = Filter[i].amout * 1 + a
      }
      setPayment_Filter(a)
      const Filter1 = res.data.filter(item => item.type == 1)
      setPayment1(Filter1)
      var b = 0
      for (let i = 0; i < Filter1.length; i++) {
        b = Filter1[i].amout * 1 + b
      }
      setPayment_Filter1(b)


    })

    axios.get(`${URL}/super/pay`, { headers: { Authorization: "Beraer " + sessionStorage.getItem("token") } }).then(res => {

      setKluch(res.data)
      document.querySelector("#PayPal_payment").value = res.data.paypal_kluch
      document.querySelector("#Strip_payment").value = res.data.strip_key
      document.querySelector("#Public_payment").value = res.data.public_key
      document.querySelector("#VideoChat_domain").value=res.data.domain
      document.querySelector("#VideoChat_key").value=res.data.daily_API_KEY
    })
  }, [])

  function deleteRegister(id) {
    axios.delete(`${URL}/api/super/registerCourse/${id}`, { headers: { Authorization: 'Bearer ' + sessionStorage.getItem("token") } }).then(res => {
      alert("Данные удалены")
      axios.get(`${URL}/api/super/registerCourse`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
        setRegisterCourse(res.data)
      })
      axios.get(`${URL}/api/course`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
        setCourse(res.data)
      })
      axios.get(`${URL}/auth/users`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
        setUsers(res.data)
      })
    }).catch(err => {
      alert("Данные не удалены")
    })

  }

  function putKluch() {
    var formdata = new FormData()
    formdata.append("strip_key", document.querySelector("#Strip_payment").value)
    formdata.append("public_key", document.querySelector("#Public_payment").value)
    formdata.append("paypal_kluch", document.querySelector("#PayPal_payment").value)
    formdata.append("domain", document.querySelector("#VideoChat_domain").value)
    formdata.append("daily_API_KEY",document.querySelector("#VideoChat_key").value)

    axios.put(`${URL}/super/pay/`, formdata, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
    axios.get(`${URL}/super/pay`, { headers: { Authorization: "Beraer " + sessionStorage.getItem("token") } }).then(res => {

      setKluch(res.data)
      document.querySelector("#PayPal_payment").value = res.data.paypal_kluch
      document.querySelector("#Strip_payment").value = res.data.strip_key
      document.querySelector("#Public_payment").value = res.data.public_key
      document.querySelector("#VideoChat_domain").value=res.data.domain
      document.querySelector("#VideoChat_key").value=res.data.daily_API_KEY
    })
    }).catch(err => {
      alert("xato")
    })
  }

  function FilterKun(date) {
    axios.get(`${URL}/pay/payment`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      const Filter = res.data.filter(item => (item.time_create).slice(0, 10) == date && item.type == 1)
      setPayment1(Filter)
    })
  }
  function FilterTun(date) {
    axios.get(`${URL}/pay/payment`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      const Filter = res.data.filter(item => (item.time_create).slice(0, 10) == date && item.type == 2)
      setPayment(Filter)
    })
  }

  function KursFilter(value) {
    axios.get(`${URL}/api/super/registerCourse`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      const Filter = res.data.filter(item => (item.time_create).slice(0, 10) == value)
      setRegisterCourse(Filter)
    })
  }




  return (
    <div>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={16}>
          <Row gutter={[24, 0]}>
            
            <Col style={{width:'100%',flex:'100%',maxWidth:'50%'}} xs={12} xl={6} className="mb-24">
              <Card bordered={false} className="widget-2 h-full">
                <Statistic
                  title={
                    <div>
                      <div className="icon">{angle}</div>
                      <h6>Stripe</h6>
                      <p>Belong Interactive</p>
                    </div>
                  }
                  value={"$"}
                  prefix={<div style={{ display: 'flex', alignItems: 'center' }}><PlusOutlined style={{ fontSize: '15px' }} /><h1 style={{ marginBottom: "0px" }}>{Payment_Filter1}</h1></div>}
                />
              </Card>
            </Col>
            <Col style={{width:'100%',flex:'100%',maxWidth:'50%'}} xs={12} xl={6} className="mb-24">
              <Card bordered={false} className="widget-2 h-full">
                <Statistic
                  title={
                    <div>
                      <div className="icon">
                        <img src={paypal} alt="paypal" />
                      </div>
                      <h6>Paypal</h6>
                      <p>Freelance Paymente</p>
                    </div>
                  }
                  value={"$"}
                  prefix={<div style={{ display: 'flex', alignItems: 'center' }}><PlusOutlined style={{ fontSize: '15px' }} /><h1 style={{ marginBottom: "0px" }}>{Payment_Filter}</h1></div>}
                />
              </Card>
            </Col>
            <Col xs={24} className="mb-24">
              <Card
                className="header-solid h-full ant-card-p-0"
                title={
                  <div>
                    <Row
                      gutter={[24, 0]}
                      className="ant-row-flex ant-row-flex-middle"
                    >
                      <Col xs={24} md={12}>
                        <h6 className="font-semibold m-0">Payment Methods</h6>
                      </Col>
                      {/* <Col xs={24} md={12} className="d-flex">
                        <Button type="primary">ADD NEW CARD</Button>
                      </Col> */}
                    </Row>
                  </div>
                }
              >
                <Row gutter={[24, 0]}>
                  <Col span={24} md={12}>
                    <p>Stripe key</p>
                    <Card className="payment-method-card">
                      <h6 className="card-number"><input type="text" id="Strip_payment" /></h6>
                    </Card>
                  </Col>
                  <Col span={24} md={12}>
                    <p>Public key</p>
                    <Card className="payment-method-card">
                      <h6 className="card-number"><input type="text" id="Public_payment" /></h6>
                    </Card>
                  </Col>
                  <Col span={24} md={12}>
                    <p>Video Chat domen</p>
                    <Card className="payment-method-card">
                      <h6 className="card-number"><input type="text" id="VideoChat_domain" /></h6>
                    </Card>
                  </Col>
                  <Col span={24} md={12}>
                    <p>Video Chat key</p>
                    <Card className="payment-method-card">
                      <h6 className="card-number"><input type="text" id="VideoChat_key" /></h6>
                    </Card>
                  </Col>
                  <Col style={{ marginTop: '15px', width: '100%', maxWidth: '100%', flex: '100%' }} span={24} md={12}>
                    <p>PayPal key</p>
                    <Card className="payment-method-card">
                      <h6 className="card-number"><input type="text" id="PayPal_payment" /></h6>
                    </Card>
                  </Col>
                  <button className="kluch_button" style={{ marginLeft: 'auto', marginRight: '11px', marginTop: '10px' }} onClick={() => putKluch()}>Редактировать</button>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            className="header-solid h-full ant-invoice-card"
            title={[<div><h6 className="font-semibold m-0">Транзакции для вас осуществляются через Stripe</h6><input style={{ marginTop: '5px' }} className="date_input_billing" onChange={(e) => FilterKun(e.target.value)} type="date" /></div>]}
          >
            <List
              // header={<h6>NEWEST</h6>}
              className="transactions-list ant-newest"
              itemLayout="horizontal"
              dataSource={Payment1}
              style={{ height: '300px', overflowY: 'scroll' }}
              renderItem={(item) => (
                users.map(users => {
                  if (users.id == item.user_id) {
                    return (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar size="small" className="text-fill">
                              <PlusOutlined style={{ fontSize: 10 }} />
                            </Avatar>
                            <div style={{ borderRadius: '50%', marginRight: '-10px', marginLeft: '10px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Avatar style={{ height: '100%' }} size={40} shape="square" src={`${URL}/` + users.image} />
                            </div>
                          </div>
                          }
                          title={users.username}
                          description={(item.time_create).slice(0, 10)}
                        />
                        <div className="amount">
                          <span className="text-success" style={{ paddingRight: "10px" }}>{item.amout}$</span>
                        </div>
                      </List.Item>
                    )
                  }
                })
              )}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col span={24} md={16} className="mb-24">
          <Card
            className="header-solid h-full"
            bordered={false}
            title={[<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><h6 className="font-semibold m-0">Люди, купившие курс</h6><input className="date_input_billing" onChange={(e) => KursFilter(e.target.value)} type="date" /></div>]}
            bodyStyle={{ paddingTop: "0" }}

          >
            <Row style={{ height: '600px', overflowY: 'scroll' }} gutter={[24, 24]}>
              <Col>
                {registerCourse.map((item) => (
                  users.map(user => {
                    if (item.users == user.id) {
                      return (
                        <Card style={{ marginTop: '10px' }} className="card-billing-info" bordered="false">
                          <div className="col-info">
                            <Descriptions title={user.username}>
                              {course.map(course => {
                                if (item.course == course.id) {
                                  return (
                                    <Descriptions.Item label="Cours Name" span={3}>
                                      {course.name}
                                    </Descriptions.Item>
                                  )

                                }
                              })}
                              <Descriptions.Item label=" User Email Address" span={3}>
                                {user.email}
                              </Descriptions.Item>
                              {course.map(course => {
                                if (item.course == course.id) {
                                  return (
                                    <Descriptions.Item label="Course Price" span={3}>
                                      {course.price} $
                                    </Descriptions.Item>
                                  )
                                }
                              })}
                            </Descriptions>
                          </div>
                          <div className="col-action">
                            <Button onClick={() => deleteRegister(item.id)} type="link" danger>
                              {deletebtn}DELETE
                            </Button>
                            {/* <Button type="link" className="darkbtn">
                              {pencil} EDIT
                            </Button> */}
                          </div>
                        </Card>
                      )
                    }
                  })
                ))}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            bodyStyle={{ paddingTop: 0 }}
            className="header-solid h-full  ant-list-yes"
            title={<div><h6 className="font-semibold m-0">Транзакции для вас осуществляются через PayPal</h6><input style={{ marginTop: '5px' }} className="date_input_billing" type="date" onChange={(e) => FilterTun(e.target.value)} /></div>}
            extra={
              <p className="card-header-date">
                {/* {calender} */}
                {/* <span>23 - 30 March 2021</span> */}
              </p>
            }
          >
            <List
              // header={<h6>NEWEST</h6>}
              className="transactions-list ant-newest"
              itemLayout="horizontal"
              dataSource={Payment}
              style={{ height: '500px', overflowY: 'scroll' }}
              renderItem={(item) => (
                users.map(users => {
                  if (users.id == item.user_id) {
                    return (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Avatar size="small" className="text-fill">
                                <PlusOutlined style={{ fontSize: 10 }} />
                              </Avatar>
                              <div style={{ borderRadius: '50%', marginRight: '-10px', marginLeft: '10px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Avatar style={{ height: '100%' }} size={40} shape="square" src={`${URL}/` + users.image} />
                              </div>
                            </div>
                          }

                          title={users.username}
                          description={(item.time_create).slice(0, 10)}
                        />
                        <div className="amount">
                          <span className="text-success" style={{ paddingRight: "10px" }}>{item.amout}$</span>
                        </div>
                      </List.Item>
                    )
                  }
                })
              )}
            />
            {/* 
            <List
              className="yestday transactions-list"
              header={<h6>YESTERDAY</h6>}
              itemLayout="horizontal"
              dataSource={yesterday}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar size="small" className={item.textclass}>
                        {item.avatar}
                      </Avatar>
                    }
                    title={item.title}
                    description={item.description}
                  />
                  <div className="amount">
                    <span className={item.amountcolor}>{item.amount}</span>
                  </div>
                </List.Item>
              )}
            /> */}
          </Card>
        </Col>
      </Row>


    </div>
  );
}

export default Billing;
