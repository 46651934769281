import axios from 'axios'
import React, { useEffect, useState } from 'react'
import URL from './Host'
import AllChat from "./AllChat"

export default function AllChatSelecet() {
  const [user, setUser] = useState([])
  const [page, setPage] = useState(0)
  const [dostup,setDostup]=useState(JSON.parse(sessionStorage.getItem("dos")))



  useEffect(() => {
    axios.get(`${URL}/auth/users`, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      setUser(res.data)
    })
  }, [])

  function AllChatPage() {
    setPage(1)
    localStorage.setItem("AllChatUserId", document.querySelector("#user_chat").value)
  }

  return (
    <div>
      {(dostup[0].chat_get || sessionStorage.getItem("position")==3)?(<button onClick={() => { window.location = "/chat" }} className="user_post_button_malumot">Chat</button>):(<div></div>)}  {(dostup[0].get_allchat || sessionStorage.getItem("position")==3)?(<button onClick={() => { window.location = "/allchat" }} className="user_post_button_malumot">AllChat</button>):(<div></div>)}
      {page == 1 ? (
        <div>
          <button style={{ marginLeft: '5px' }} className='user_post_button_malumot' onClick={() => setPage(0)}>Exit</button>
          <AllChat />
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80vh' }}>
          <div className="All_chat_select_div">
            <select name="" id="user_chat">
              {user.map((item, key) => {
                return <option value={item.id}>{item.username}</option>
              })}
            </select>
            <button style={{ marginBottom: '0px', marginTop: '20px', height: '35px' }} className='user_post_button_malumot' onClick={() => AllChatPage()}>Send</button>
          </div>
        </div>)}
    </div>
  )
}
