import axios from 'axios'
import React, { useEffect, useState } from 'react'
import URL from './Host'
import { Table } from "antd";
import { AiOutlineSearch } from "react-icons/ai"
import close from "./img/close.png"
import deleteImg from "./img/Inbox cleanup-rafiki.png"


export default function Course_theme() {

    const [course_typeId,setCourse_typeId]=useState()
    const [course_type, setCourse_type] = useState([])
    const [course_type1, setCourse_type1] = useState([])


     const username=document.querySelectorAll("#Baseusername")


    useEffect(() => {
        axios.get(`${URL}/api/base_theme`, { headers: { Authorization: "Berarer " + sessionStorage.getItem("token") } }).then(res => {
            setCourse_type(res.data)
            setCourse_type1(res.data)
            console.log(res.data);
        })
    }, [])

    function postCourse_type_Modal() {
        document.querySelector("#postBase_Modal").style = "display:flex"
    }

    function postCourse_type_Close() {
        document.querySelector("#postBase_Modal").style = "display:none"
    }

    function putCourse_type_Modal(id) {
        setCourse_typeId(id)
        course_type.map(item=>{
            if (item.id===id) {
                username[1].value=item.name
            }
        })
        document.querySelector("#putBase_Modal").style = "display:flex"
    }

    function putCourse_type_Close() {
        document.querySelector("#putBase_Modal").style = "display:none"
    }

    function deleteCourse_type_Modal(id){
        setCourse_typeId(id)
        document.querySelector("#deleteBase_Modal").style="display:flex"
    }

    function deleteCourse_type_Close(){
        document.querySelector("#deleteBase_Modal").style="display:none"
    }

    const columns = [
        {
            title: 'Имя',
            dataIndex: 'name',
            key: 'username',
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'balance',
            render: (dataIndex) => <div><button onClick={() => putCourse_type_Modal(dataIndex)} className="user_post_button">Редактировать</button>  <button onClick={()=>deleteCourse_type_Modal(dataIndex)} className="user_post_button">Удалить</button> </div>,
        },
    ]

    function postCourse_type(){
        var formdata=new FormData()
        formdata.append("name",username[0].value)
        

        axios.post(`${URL}/api/base_theme`,formdata,{headers:{Authorization:"Bearer " + sessionStorage.getItem("token")}}).then(res=>{
        alert("Информация отправлена")
        document.querySelector("#postBase_Modal").style = "display:none"
        axios.get(`${URL}/api/base_theme`, { headers: { Authorization: "Berarer " + sessionStorage.getItem("token") } }).then(res => {
            setCourse_type(res.data)
            console.log(res.data);
        })
        }).catch(err=>{
            alert("Эта информация включена")
        })
    }

    function putCourse_type(){
        var formdata=new FormData()
        formdata.append("name",username[1].value)
        

        axios.put(`${URL}/api/base_theme/${course_typeId}`,formdata,{headers:{Authorization:"Bearer " + sessionStorage.getItem("token")}}).then(res=>{
        alert("Информация изменена")
        document.querySelector("#putBase_Modal").style = "display:none"
        axios.get(`${URL}/api/base_theme`, { headers: { Authorization: "Berarer " + sessionStorage.getItem("token") } }).then(res => {
            setCourse_type(res.data)
            console.log(res.data);
        })
        }).catch(err=>{
            alert("Информация не изменилась")
        })
    }

    function deleteCourse_type(){
        axios.delete(`${URL}/api/base_theme/${course_typeId}`,{headers:{Authorization:"Bearer " + sessionStorage.getItem("token")}}).then(res=>{
        document.querySelector("#deleteBase_Modal").style="display:none"
        alert("Данные удалены")    
        axios.get(`${URL}/api/base_theme`, { headers: { Authorization: "Berarer " + sessionStorage.getItem("token") } }).then(res => {
            setCourse_type(res.data)
            console.log(res.data);
        })
        }).catch(err=>{
            alert("Данные не удалены")
        })
    }

    function Search(e){
        const Serach=course_type.filter(item=>item.name.includes(e.target.value))
        if (e.target.value) {
          setCourse_type(Serach)
        }else{
          setCourse_type(course_type1)
        }
      }

    return (
        <div>
            <h1 style={{marginTop:'100px'}} >Основа</h1>
            <div  className="search_big_div"><button onClick={() => postCourse_type_Modal()} className="user_post_button">Добавить</button><div style={{position:'relative'}}><AiOutlineSearch className="search" /><input onChange={Search} placeholder="Введите здесь..." type="text" /></div></div>
            <Table dataSource={course_type} columns={columns} />

            <div id="postBase_Modal" className="Modaldiv">
                <div className="postUserModal_div" style={{width:'340px'}}>
                    <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={() => postCourse_type_Close()} /></div>
                    <div style={{display:'flex',flexWrap:"wrap"}} className="postUserModal_div_label">
                        <label htmlFor="">
                            <p>Имя</p>
                            <input id="Baseusername" placeholder="Имя" type="text" />
                        </label>
                    </div>
                    <div className="postUserModal_div_button">
                        <button onClick={()=>postCourse_type()}  >Отправить</button>
                    </div>
                </div>
            </div>
            <div id="putBase_Modal" className="Modaldiv">
                <div className="postUserModal_div"  style={{width:'340px'}}>
                    <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={() => putCourse_type_Close()} /></div>
                    <div style={{display:'flex',flexWrap:"wrap"}} className="postUserModal_div_label">
                        <label htmlFor="">
                            <p>Имя</p>
                            <input id="Baseusername" type="text" />
                        </label>
                    </div>
                    <div className="postUserModal_div_button">
                        <button onClick={()=>putCourse_type()} >Отправить</button>
                    </div>
                </div>
            </div>
            <div id="deleteBase_Modal" className="deleteModal">
                <div className="deleteModal_div">
                    <div className="postUserModal_div_icon"><img src={close} className="icon" onClick={() => deleteCourse_type_Close()} /></div>
                    <img src={deleteImg} alt="" />
                    <p>Вы действительно хотите удалить эту категорию</p>
                    <div className="deleteButton_div">
                        <button onClick={() => deleteCourse_type_Close()}>Отмена</button>
                        <button onClick={() => deleteCourse_type()}>Подтвердить</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

