import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
} from "antd";

import { useEffect, useState } from "react";
import URL from "./Host";
import axios from "axios";
import { ToTopOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./styles/User.css";
import { AiOutlineClose } from "react-icons/ai";
import deleteImg from "./img/Inbox cleanup-rafiki.png";
import { AiOutlineSearch } from "react-icons/ai";
import close from "./img/close.png";
import Course_type from "./Course_type";
import Course_category from "./Course_category";
import Course_theme from "./Course_theme";

const { Title } = Typography;

const formProps = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

function Course() {
  const [course, setCourse] = useState([]);
  const [course1, setCourse1] = useState([]);
  const [Homiy, setHomiy] = useState([]);
  const [courseId, setCourseId] = useState();
  const [author, setAuthor] = useState();
  const [coursetypes, setCoursetypes] = useState([]);
  const [page, setPage] = useState(0);
  const [coursetypesId, setCoursetypesId] = useState();
  const [authors, setauthors] = useState([]);
  const [user, setUser] = useState([]);
  const [checkbox,setCheckbox]=useState(false)
  const [dostup, setDostup] = useState(
    JSON.parse(sessionStorage.getItem("dos"))
  );

  const name = document.querySelectorAll("#name");
  const homiy_id = document.querySelectorAll("#homiy_id");
  const price = document.querySelectorAll("#price");
  const planned_time = document.querySelectorAll("#planned_time");
  const image = document.querySelectorAll("#image");
  const description = document.querySelectorAll("#description");
  const time_create = document.querySelectorAll("#time_create");
  const time_update = document.querySelectorAll("#time_update");

  useEffect(() => {
    axios
      .get(`${URL}/api/course`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        setCourse(res.data);
        setCourse1(res.data);
        const ishla = res.data.filter((item) => item.id == courseId);
        console.log(ishla);
      });
    axios
      .get(`${URL}/api/cours_types`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        setCoursetypes(res.data);
      });
      axios
      .get(`${URL}/api/homiy`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        setHomiy(res.data);
      });
    axios
      .get(`${URL}/auth/oneuser`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        res.data.map((item) => {
          // console.log(item.id);
          setAuthor(item.id);
        });
      });
    axios
      .get(`${URL}/auth/users`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        setUser(res.data);
      });
  }, []);

  function postCourseModal() {
    document.querySelector("#postCourseModal").style = "display:flex";
  }
  function postCourseClose() {
    document.querySelector("#postCourseModal").style = "display:none";
  }
  function deleteCourseModal(id) {
    setCourseId(id);
    document.querySelector("#deleteCourseModal").style = "display:flex";
  }
  function deleteCourseClose() {
    document.querySelector("#deleteCourseModal").style = "display:none";
  }

  function deleteCourse() {
    axios
      .delete(`${URL}/api/course/${courseId}`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Данные удалены");
        document.querySelector("#deleteCourseModal").style = "display:none";
        axios
          .get(`${URL}/api/course`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setCourse(res.data);
            console.log(res.data);
          });
      })
      .catch((err) => {
        alert("Данные не удалены");
      });
  }

  function postCourse() {
    var formdata = new FormData();
    formdata.append("name", name[0].value);
    formdata.append("price", price[0].value);
    formdata.append("planned_time", planned_time[0].value);
    formdata.append(
      "course_type",
      document.querySelector("#course_type").value
    );
    formdata.append("homiy_id",homiy_id[0].value)
    formdata.append("author", author);
    checkbox==false?formdata.append("image",image[0].files[0]):formdata.append("image",document.querySelector("#imageChecboxsCourse").value)
    formdata.append("description", description[0].value);

    axios
      .post(`${URL}/api/course`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Добавлена информация");
        document.querySelector("#postCourseModal").style = "display:none";
        axios
          .get(`${URL}/api/course`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setCourse(res.data);
          });
      })
      .catch((err) => {
        alert("Заполните информацию полностью");
      });
  }

  function putCourse() {
    var formdata = new FormData();
    formdata.append("name", document.querySelector("#name_malumot").value);
    formdata.append("price", document.querySelector("#price_malumot").value);
    formdata.append(
      "planned_time",
      document.querySelector("#planned_time_malumot").value
    );
    formdata.append(
      "course_type",
      document.querySelector("#course_type_malumot").value
    );
    formdata.append("author", authors);
    checkbox==false?formdata.append("image", document.querySelector("#image_malumot").files[0]):formdata.append("image", document.querySelector("#image_malumotCheckbox").value)  
    formdata.append(
      "description",
      document.querySelector("#description_malumot").value
    );
    formdata.append("homiy_id",homiy_id[1].value)

    axios
      .put(`${URL}/api/course/${courseId}`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        setCheckbox(false)
        alert("Информация изменена");
        setPage(0);
        axios
          .get(`${URL}/api/course`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setCourse(res.data);
          });
      })
      .catch((err) => [alert("Информация не изменилась")]);
  }

  function pageMalumot(id) {
    setCheckbox(true)
    setPage(1);
    setTimeout(() => {
      course.map((item) => {
        if (item.id == id) {
          localStorage.setItem("Course", item.id);
          document.querySelector("#name_malumot").value = item.name;
          document.querySelector("#price_malumot").value = item.price;
          document.querySelector("#planned_time_malumot").value =
            item.planned_time;
          document.querySelector("#description_malumot").value =
            item.description;
          document.querySelector("#course_type_malumot").value =
            item.course_type;
          setauthors(item.author);
          setTimeout(() => {
            document.querySelector("#checkboxCourse").checked=true
            document.querySelector("#image_malumotCheckbox").value=item.image
          }, 100);
        }
      });
    }, 10);
    setCourseId(id);
  }

  const columns = [
    {
      title: 'Изображение',
      dataIndex: 'image',
      key: 'image',
      render: image => <img alt="no img" src={image} />
    },
    {
      title: "Имя",
      dataIndex: "name",
      key: "username",
    },
    {
      title: "Цена",
      dataIndex: "price",
      key: "last_name",
    },
    {
      title: "Запланированное время",
      dataIndex: "planned_time",
      key: "password",
    },
    {
      title: "",
      dataIndex: "id",
      key: "balance",
      render: (dataIndex) => (
        <div>
          {" "}
          {dostup[0].course_put || sessionStorage.getItem("position") == 3 ? (
            <button
              onClick={() => pageMalumot(dataIndex)}
              className="user_post_button"
            >
              Информация
            </button>
          ) : (
            <div></div>
          )}{" "}
          {dostup[0].course_delete ||
          sessionStorage.getItem("position") == 3 ? (
            <button
              onClick={() => deleteCourseModal(dataIndex)}
              className="user_post_button"
            >
              Удалить
            </button>
          ) : (
            <div></div>
          )}{" "}
        </div>
      ),
    },
  ];

  function Search(e) {
    const Serach = course.filter((item) => item.name.includes(e.target.value));
    if (e.target.value) {
      setCourse(Serach);
    } else {
      setCourse(course1);
    }
  }

  
  function Checkbox(checked1){
    setCheckbox(checked1)
    if(checked1==true){
      course.map((item) => {
        if (item.id == courseId) {
          setTimeout(() => {
            document.querySelector("#image_malumotCheckbox").value=item.image
          }, 100);
        }
      });
    }
  }

  return (
    <div>
      <div id="deleteCourseModal" className="deleteModal">
        <div className="deleteModal_div">
          <div className="postUserModal_div_icon">
            <img
              src={close}
              className="icon"
              onClick={() => deleteCourseClose()}
            />
          </div>
          <img src={deleteImg} alt="" />
          <p>Вы хотите удалить студента</p>
          <div className="deleteButton_div">
            <button onClick={() => deleteCourseClose()}>Отмена</button>
            <button onClick={() => deleteCourse()}>Подтвердить</button>
          </div>
        </div>
      </div>
      <div id="postCourseModal" className="Modaldiv">
        <div className="postUserModal_div">
          <div className="postUserModal_div_icon">
            <img
              src={close}
              className="icon"
              onClick={() => postCourseClose()}
            />
          </div>
          <div className="postUserModal_div_label">
            <label htmlFor="">
              <p>Имя</p>
              <input id="name" placeholder="Имя" type="text" />
            </label>
            <label htmlFor="">
              <p>Цена</p>
              <input id="price" placeholder="Цена" type="text" />
            </label>
            <label htmlFor="">
              <p>Запланированное время</p>
              <input
                id="planned_time"
                placeholder="Запланированное время"
                type="text"
              />
            </label>
            <label htmlFor="">
              <p>Тип курса</p>
              <select name="" id="course_type">
                {coursetypes.map((item) => {
                  return (
                    <option id="course_type" value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </label>
            <label htmlFor="">
              <p>Спонсор</p>
              <select name="" id="homiy_id">
                <option value="0">Not</option>
                {Homiy.map((item) => {
                  return (
                    <option id="homiy_id" value={item.id}>
                      {item.title}
                    </option>
                  );
                })}
              </select>
            </label>
            <label htmlFor="">
              <p>Изображение       <input type="checkbox"  onClick={(e)=>Checkbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
              {checkbox==false?(<input id="image" className="image" placeholder="Изображение" type="file" />):(<input id="imageChecboxsCourse" className="image" placeholder="Изображение" type="text" />)}  
            </label>
          </div>
          <label htmlFor="">
            <p>Описание</p>
            <textarea
              id="description"
              placeholder="Описание"
              className="description"
              type="text"
            />
          </label>
          <div className="postUserModal_div_button">
            <button onClick={() => postCourse()}>Отправить</button>
          </div>
        </div>
      </div>
      {page == 1 ? (
        <div>
          <div className="exit_button">
            <button
              onClick={() => {
                {setPage(0);setCheckbox(false)}
              }}
            >
              Назад
            </button>
          </div>
          <div className="malumot_big_div">
            <label htmlFor="">
              <p>Имя</p>
              <input id="name_malumot" type="text" />
            </label>
            <label htmlFor="">
              <p>Цена</p>
              <input id="price_malumot" type="text" />
            </label>
            <label htmlFor="">
              <p>Запланированное время</p>
              <input id="planned_time_malumot" type="text" />
            </label>
            <label htmlFor="">
              <p>Тип курса</p>
              <select name="" id="course_type_malumot">
                {coursetypes.map((item) => {
                  return (
                    <option id="course_type_malumot" value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </label>
            <label htmlFor="">
            <p>Спонсор</p>
            <select name="" id="homiy_id">
              <option value="0">Not</option>
              {Homiy.map((item) => {
                return (
                  <option id="homiy_id" value={item.id}>
                    {item.title}
                  </option>
                );
              })}
            </select>
          </label>
            <label htmlFor="">
              <p>Изображение      <input type="checkbox" id="checkboxCourse"  onClick={(e)=>Checkbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
              {checkbox==false?(<input  id="image_malumot" type="file" />):(<input id="image_malumotCheckbox" type="text" />)}
            </label>
            <label htmlFor="">
              <p>Описание</p>
              <input id="description_malumot" type="text" />
            </label>
            {course.map((item) => {
              return (
                <div>
                  {user.map((user) => {
                    if (item.id == courseId) {
                      if (user.id == item.author) {
                        return (
                          <label htmlFor="">
                            <p>Автор</p>
                            <input
                              id="author_malumot"
                              value={user.username}
                              type="text"
                            />
                          </label>
                        );
                      }
                    }
                  })}
                </div>
              );
            })}
            <label htmlFor="">
              <p>Time_create</p>
              {course.map((item) => {
                if (item.id == courseId) {
                  return (
                    <input
                      id="time_create_malumot"
                      value={item.time_create.slice(0, 10)}
                      type="text"
                    />
                  );
                }
              })}
            </label>
            <label htmlFor="">
              <p>Time_update</p>
              {course.map((item) => {
                if (item.id == courseId) {
                  return (
                    <input
                      value={item.time_update.slice(0, 10)}
                      id="time_update_malumot"
                      type="text"
                    />
                  );
                }
              })}
            </label>
          </div>
          <div className="postUserModal_div_button">
            <button
              className="user_post_button_malumot"
              onClick={() => putCourse()}
            >
              Редактировать
            </button>
          </div>
          {/* 
          <div className="div_course_white_list_big_div">
            <div className="search_big_div"><h1>Тип курса</h1><button className="user_post_button_malumot">Добавить</button></div>
            {coursetypes.map((item) => {
              if (item.id == coursetypesId) {
                return (
                  <div className="div_course_white_list_big">
                    <div className="div_course_white_list_big_name"><h1>{item.name}</h1></div>
                    <div className="div_course_white_list_big_div_button"><button className="user_post_button">Редактировать</button> <button className="user_post_button">Удалить</button></div>
                  </div>
                )
              }
            })}</div> */}

          <Course_category />
        </div>
      ) : (
        <div>
          <h1>Курс</h1>
          <div className="search_big_div">
            {dostup[0].course_post ||
            sessionStorage.getItem("position") == 3 ? (
              <button
                onClick={() => postCourseModal()}
                className="user_post_button"
              >
                Добавить
              </button>
            ) : (
              <div></div>
            )}
            <div style={{ position: "relative" }}>
              <AiOutlineSearch className="search" />
              <input
                onChange={Search}
                placeholder="Введите здесь..."
                type="text"
              />
            </div>
          </div>
          <Table dataSource={course} columns={columns} />
          <Course_type />
        </div>
      )}
    </div>
  );
}

export default Course;
