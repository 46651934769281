import axios from "axios";
import React, { useEffect, useState } from "react";
import close from "./img/close.png";
import deleteImg from "./img/Inbox cleanup-rafiki.png";
import URL from "./Host";
import { AiOutlineSearch } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";

import img_for_null from "./img/noimg.jpg";

export default function Unuvercity() {
  const [univercard, setUnivercard] = useState([]);
  const [univercard1, setUnivercard1] = useState([]);
  const [univercardId, setUnivercardId] = useState();
  const [dostup, setDostup] = useState(
    JSON.parse(sessionStorage.getItem("dos"))
  );
  const [checkbox, setCheckbox] = useState(false);
  const [logotip, setLogotip] = useState(false);

  const title = document.querySelectorAll("#Univertitle");
  const image = document.querySelectorAll("#Univerimage");
  const destcription = document.querySelectorAll("#Univerdeskription");
  const logo = document.querySelectorAll("#Univerlogo");

  useEffect(() => {
    axios
      .get(`${URL}/api/university`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        setUnivercard(res.data);
        setUnivercard1(res.data);
      });
  }, []);

  function postUniverstateOpen() {
    document.querySelector("#postUniverstate_Modal").style = "display:flex";
  }
  function postUniverstateClose() {
    document.querySelector("#postUniverstate_Modal").style = "display:none";
  }
  function putUniverstateOpen(id) {
    setCheckbox(true);
    setLogotip(true);
    setUnivercardId(id);
    univercard.map((item) => {
      if (item.id == id) {
        title[1].value = item.title;
        destcription[1].value = item.deckription;
        setTimeout(() => {
          document.querySelector("#checkboxUnLogo").checked = true;
          document.querySelector("#checkboxUnImage").checked = true;
          document.querySelector("#image_malumotCheckboxLogo1").value =
            item.logo;
          document.querySelector("#image_malumotCheckboxImage1").value =
            item.image;
        }, 100);
      }
    });
    document.querySelector("#putUniverstate_Modal").style = "display:flex";
  }
  function putUniverstateClose() {
    setCheckbox(false);
    setLogotip(false)
    document.querySelector("#putUniverstate_Modal").style = "display:none";
  }
  function deleteUniverstateOpen(id) {
    setUnivercardId(id);
    document.querySelector("#deleteUniverstate_Modal").style = "display:flex";
  }
  function deleteUniverstateClose() {
    document.querySelector("#deleteUniverstate_Modal").style = "display:none";
  }

  function postUniverstate() {
    var formdata = new FormData();
    formdata.append("title", title[0].value);
    // formdata.append("image", image[0].files[0]);
    checkbox == false
      ? formdata.append(
          "image",
          document.querySelector("#Univerimage").files[0]
        )
      : formdata.append(
          "image",
          document.querySelector("#image_malumotCheckboxImage").value
        );
    formdata.append("deckription", destcription[0].value);
    // formdata.append("logo", logo[0].files[0]);
    logotip == false
      ? formdata.append("logo", document.querySelector("#Univerlogo").files[0])
      : formdata.append(
          "logo",
          document.querySelector("#image_malumotCheckboxLogo").value
        );

    axios
      .post(`${URL}/api/university`, formdata, {
        headers: { Authorization: "Beraer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Информация отправлена");
        document.querySelector("#postUniverstate_Modal").style = "display:none";
        axios
          .get(`${URL}/api/university`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setUnivercard(res.data);
            setUnivercard1(res.data);
          });
      })
      .catch((err) => {
        alert("Информация не отправлена, пожалуйста, заполните полностью");
      });
  }

  function putUniverstate() {
    var formdata = new FormData();
    formdata.append("title", title[1].value);
    // formdata.append("image", image[1].files[0]);
    checkbox == false
      ? formdata.append(
          "image",
          document.querySelector("#Univerimage1").files[0]
        )
      : formdata.append(
          "image",
          document.querySelector("#image_malumotCheckboxImage1").value
        );
    formdata.append("deckription", destcription[1].value);
    logotip == false
      ? formdata.append("logo", document.querySelector("#Univerlogo1").files[0])
      : formdata.append(
          "logo",
          document.querySelector("#image_malumotCheckboxLogo1").value
        );

    axios
      .put(`${URL}/api/university/${univercardId}`, formdata, {
        headers: { Authorization: "Beraer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        setCheckbox(false);
        setLogotip(false)
        alert("Информация была изменена");
        document.querySelector("#putUniverstate_Modal").style = "display:none";
        axios
          .get(`${URL}/api/university`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setUnivercard(res.data);
            setUnivercard1(res.data);
          });
      })
      .catch((err) => {
        alert("Информация не изменена, пожалуйста, заполните полностью");
      });
  }

  function deleteUniverstate() {
    axios
      .delete(`${URL}/api/university/${univercardId}`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Информация удалена");
        document.querySelector("#deleteUniverstate_Modal").style =
          "display:none";
        axios
          .get(`${URL}/api/university`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            setUnivercard(res.data);
            setUnivercard1(res.data);
          });
      })
      .catch((err) => {
        alert("Информация не была удалена, пожалуйста, заполните ее полностью");
      });
  }

  function Search(e) {
    const Search = univercard.filter((item) =>
      item.title.includes(e.target.value)
    );
    if (e.target.value) {
      setUnivercard(Search);
    } else {
      setUnivercard(univercard1);
    }
  }

  function Checkbox(checked1) {
    setCheckbox(checked1);
   if(checked1==true){
    univercard.map((item) => {
      if (item.id == univercardId) {
        setTimeout(() => {
          document.querySelector("#image_malumotCheckboxImage1").value =
            item.image;
        }, 100);
      }
    });
   }
  }

  function Logotip(checked1) {
    setLogotip(checked1);
    if(checked1==true){
      univercard.map((item) => {
        if (item.id == univercardId) {
          setTimeout(() => {
            document.querySelector("#image_malumotCheckboxLogo1").value =
              item.logo;
          }, 100);
        }
      });
     }
  }

  return (
    <div>
      <div className="search_big_div">
        {dostup[0].universitet_post ||
        sessionStorage.getItem("position") == 3 ? (
          <button
            onClick={() => postUniverstateOpen()}
            className="user_post_button"
          >
            Добавить
          </button>
        ) : (
          <div></div>
        )}
        <div style={{ position: "relative" }}>
          <AiOutlineSearch className="search" />
          <input onChange={Search} placeholder="Введите здесь..." type="text" />
        </div>
      </div>
      <div className="universty">
        <div className="iniversty_cards">
          {univercard.map((item) => {
            return (
              <div className="iniversty_card">
                <div className="iniversty_img">
                  {item.image === null ? (
                    <img src={img_for_null} alt="" />
                  ) : (
                    <img src={item.image} alt="" />
                  )}
                </div>
                <p>{item.title}</p>
                <h2>{item.deckription}</h2>
                <div
                  style={{ paddingRight: "10px", paddingBottom: "10px" }}
                  className="edication_card_button"
                >
                  {dostup[0].universitet_delete ||
                  sessionStorage.getItem("position") == 3 ? (
                    <button
                      className="user_post_button_red"
                      onClick={() => deleteUniverstateOpen(item.id)}
                    >
                      <MdDeleteOutline />
                    </button>
                  ) : (
                    <div></div>
                  )}
                  {dostup[0].universitet_put ||
                  sessionStorage.getItem("position") == 3 ? (
                    <button onClick={() => putUniverstateOpen(item.id)}>
                      <BiEdit />
                    </button>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div id="postUniverstate_Modal" className="Modaldiv">
        <div className="postUserModal_div" style={{ width: "340px" }}>
          <div className="postUserModal_div_icon">
            <img
              src={close}
              className="icon"
              onClick={() => postUniverstateClose()}
            />
          </div>
          <div
            style={{ display: "flex", flexWrap: "wrap" }}
            className="postUserModal_div_label"
          >
            <label htmlFor="">
              <p>Заголовок</p>
              <input id="Univertitle" placeholder="Заголовок" type="text" />
            </label>
            <label htmlFor="">
              <p>
                Изображение{" "}
                <input
                  type="checkbox"
                  onClick={(e) => Checkbox(e.target.checked)}
                  style={{ width: "15px", height: "15px" }}
                />
              </p>
              {checkbox == false ? (
                <input id="Univerimage" placeholder="Изображение" type="file" />
              ) : (
                <input id="image_malumotCheckboxImage" type="text" />
              )}
            </label>
            <label htmlFor="">
              <p>
                Логотип{" "}
                <input
                  type="checkbox"
                  onClick={(e) => Logotip(e.target.checked)}
                  style={{ width: "15px", height: "15px" }}
                />
              </p>
              {logotip == false ? (
                <input id="Univerlogo" placeholder="Изображение" type="file" />
              ) : (
                <input id="image_malumotCheckboxLogo" type="text" />
              )}
            </label>
            <label htmlFor="">
              <p>Описание</p>
              <input
                id="Univerdeskription"
                placeholder="Описание"
                type="text"
              />
            </label>
          </div>
          <div className="postUserModal_div_button">
            <button onClick={() => postUniverstate()}>Отправить</button>
          </div>
        </div>
      </div>
      <div id="putUniverstate_Modal" className="Modaldiv">
        <div className="postUserModal_div" style={{ width: "340px" }}>
          <div className="postUserModal_div_icon">
            <img
              src={close}
              className="icon"
              onClick={() => putUniverstateClose()}
            />
          </div>
          <div
            style={{ display: "flex", flexWrap: "wrap" }}
            className="postUserModal_div_label"
          >
            <label htmlFor="">
              <p>Заголовок</p>
              <input id="Univertitle" placeholder="Заголовок" type="text" />
            </label>
            <label htmlFor="">
              <p>
                Изображение{" "}
                <input
                  type="checkbox"
                  id="checkboxUnImage"
                  onClick={(e) => Checkbox(e.target.checked)}
                  style={{ width: "15px", height: "15px" }}
                />
              </p>
              {checkbox == false ? (
                <input
                  id="Univerimage1"
                  placeholder="Изображение"
                  type="file"
                />
              ) : (
                <input id="image_malumotCheckboxImage1" type="text" />
              )}
            </label>
            <label htmlFor="">
              <p>
                Логотип{" "}
                <input
                  type="checkbox"
                  id="checkboxUnLogo"
                  onClick={(e) => Logotip(e.target.checked)}
                  style={{ width: "15px", height: "15px" }}
                />
              </p>
              {logotip == false ? (
                <input id="Univerlogo1" placeholder="Изображение" type="file" />
              ) : (
                <input id="image_malumotCheckboxLogo1" type="text" />
              )}
            </label>
            <label htmlFor="">
              <p>Описание</p>
              <input
                id="Univerdeskription"
                placeholder="Описание"
                type="text"
              />
            </label>
          </div>
          <div className="postUserModal_div_button">
            <button onClick={() => putUniverstate()}>Отправить</button>
          </div>
        </div>
      </div>
      <div id="deleteUniverstate_Modal" className="deleteModal">
        <div className="deleteModal_div">
          <div className="postUserModal_div_icon">
            <img
              src={close}
              className="icon"
              onClick={() => deleteUniverstateClose()}
            />
          </div>
          <img src={deleteImg} alt="" />
          <p>Вы хотите удалить Университет</p>
          <div className="deleteButton_div">
            <button onClick={() => deleteUniverstateClose()}>Отмена</button>
            <button onClick={() => deleteUniverstate()}>Подтвердить</button>
          </div>
        </div>
      </div>
    </div>
  );
}
