import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
} from "antd";

import { useEffect, useState } from "react";
import URL from "./Host";
import axios from "axios";
import { ToTopOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./styles/User.css";
import { AiOutlineClose } from "react-icons/ai";
import deleteImg from "./img/Inbox cleanup-rafiki.png";
import { AiOutlineSearch } from "react-icons/ai";
import close from "./img/close.png";
import Item from "antd/lib/list/Item";

const { Title } = Typography;

const formProps = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

function Student() {
  const [student, setStudent] = useState([]);
  const [student1, setStudent1] = useState([]);
  const [studentId, setStudentId] = useState([]);
  const [positionId, setpositionId] = useState([]);
  const [ban, setBan] = useState();
  const [styleId, setStyleId] = useState();
  const [page, setPage] = useState(0);
  const [Admin, setAdmin] = useState([]);
  const [AdminId,setAdminId]=useState()
  const [Adminuser_id, setAdminuser_id] = useState([]);
  const [checkbox,setCheckbox]=useState(false)
  const [dostup,setDostup]=useState(JSON.parse(sessionStorage.getItem("dos")))


  const username = document.querySelectorAll("#username");
  const last_name = document.querySelectorAll("#last_name");
  const phone_number = document.querySelectorAll("#phone_number");
  const email = document.querySelectorAll("#email");
  const address = document.querySelectorAll("#address");
  const password = document.querySelectorAll("#password");
  const image = document.querySelectorAll("#image");
  const description = document.querySelectorAll("#description");
  const getID = document.querySelectorAll("#getID")
  const deleteID = document.querySelectorAll("#deleteID")
  const putID = document.querySelectorAll("#putID")
  const postID = document.querySelectorAll("#postID")
  const getID1 = document.querySelectorAll("#getID1")
  const deleteID1 = document.querySelectorAll("#deleteID1")
  const putID1 = document.querySelectorAll("#putID1")
  const postID1 = document.querySelectorAll("#postID1")

  useEffect(() => {
    axios
      .get(`${URL}/auth/users`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        const studentFilter = res.data.filter((item) => item.position === 5);
        setStudent(studentFilter);
        setStudent1(studentFilter);
        console.log(studentFilter);
        studentFilter.map((item) => {
          setBan(item.is_active);
          setStyleId(item.position);
          setpositionId(item.position);
        });
      });
    axios
      .get(`${URL}/auth/admin`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        setAdmin(res.data);
      });
  }, []);

  function postStudentModal() {
    document.querySelector("#postStudentModal").style = "display:flex;";
  }
  function postStudentClose() {
    document.querySelector("#postStudentModal").style = "display:none";
  }
  function putStudentModal(id) {
    setStudentId(id);
    // student.map(item=>{
    //   if(item.id==id){
    //     address[1].value=item.address
    //     description[1].value=item.description
    //     email[1].value=item.email
    //     last_name[1].value=item.last_name
    //     password[1].value=item.password
    //     phone_number[1].value=item.phone_number
    //     username[1].value=item.username
    //     position[1].value=item.position
    //   }
    // })
    document.querySelector("#putStudentModal").style = "display:flex";
  }
  function putStudentClose() {
    document.querySelector("#putStudentModal").style = "displaynone";
  }
  function deleteStudentModal(id) {
    setStudentId(id);
    document.querySelector("#deleteStudentModal").style = "display:flex";
  }
  function deleteStudentClose() {
    document.querySelector("#deleteStudentModal").style = "display:none";
  }

  function postStudent() {
    var formdata = new FormData();
    formdata.append("address", username[0].value);
    formdata.append("balance", 0);
    formdata.append("description", description[0].value);
    formdata.append("email", email[0].value);
    checkbox==false?formdata.append("image",image[0].files[0]):formdata.append("image",document.querySelector("#imageChecboxs").value)
    formdata.append("last_name", last_name[0].value);
    formdata.append("phone_number", phone_number[0].value);
    formdata.append("username", username[0].value);
    formdata.append("password", password[0].value);
    formdata.append("position", positionId);
    formdata.append("youtobe",document.querySelector("#youtube_tables").value)
    formdata.append("telegram",document.querySelector("#telegram_tables").value)
    formdata.append("instagram",document.querySelector("#instagram_tables").value)

    axios
      .post(`${URL}/auth/users`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Добавлена информация");
        document.querySelector("#postStudentModal").style = "display:none";
        axios
          .get(`${URL}/auth/users`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            const studentFilter = res.data.filter(
              (item) => item.position === 5
            );
            setStudent(studentFilter);
          });
      })
      .catch((err) => {
        alert("Это адрес электронной почты или имя, пароль введен");
      });
  }
  function deleteStudent() {
    axios
      .delete(`${URL}/auth/users/${studentId}`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Данные удалены");
        document.querySelector("#deleteStudentModal").style = "display:none";
        axios
          .get(`${URL}/auth/users`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            const studentFilter = res.data.filter(
              (item) => item.position === 5
            );
            setStudent(studentFilter);
          });
      })
      .catch((err) => {
        alert("Данные не удалены");
      });
  }
  function putStudent() {
    var formdata = new FormData();
    formdata.append(
      "address",
      document.querySelector("#address_malumot").value
    );
    formdata.append(
      "balance",
      document.querySelector("#balance_malumot").value
    );
    formdata.append(
      "description",
      document.querySelector("#description_malumot").value
    );
    formdata.append("email", document.querySelector("#email_malumot").value);
    formdata.append(
      "last_name",
      document.querySelector("#last_name_malumot").value
    );
    formdata.append(
      "password",
      document.querySelector("#password_malumot").value
    );
    formdata.append(
      "phone_number",
      document.querySelector("#phone_number_malumot").value
    );
    formdata.append(
      "username",
      document.querySelector("#username_malumot").value
    );
    formdata.append("youtobe",document.querySelector("#youtube_tables1").value)
    formdata.append("telegram",document.querySelector("#telegram_tables1").value)
    formdata.append("instagram",document.querySelector("#instagram_tables1").value)
    formdata.append("position", positionId);
    checkbox==false?formdata.append("image", document.querySelector("#image_malumot").files[0]):formdata.append("image", document.querySelector("#image_malumotCheckbox").value) 

    axios
      .put(`${URL}/auth/userssuperadmin/${studentId}`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        alert("Информация изменена");
        setPage(0);
        axios
          .get(`${URL}/auth/users`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            const studentFilter = res.data.filter(
              (item) => item.position === 5
            );
            setStudent(studentFilter);
          });
      })
      .catch((err) => {
        alert("Информация не изменилась");
      });
  }

  function PositionId(id) {
    setpositionId(id);
  }

  function Ban(id, dataindex) {
    var formdata = new FormData();
    formdata.append("is_active", id);

    axios
      .put(`${URL}/auth/ban/${dataindex}`, formdata, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        axios
          .get(`${URL}/auth/users`, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            window.location.reload();
            const studentFilter = res.data.filter(
              (item) => item.position === 5
            );
            setStudent(studentFilter);
            studentFilter.map((item) => {
              console.log(item);
              setBan(item.is_active);
            });
          });
      })
      .catch((err) => {
        alert("Бан не был");
      });
  }

  function pageMalumot(id) {
    setPage(1);
    axios
      .get(`${URL}/auth/admin`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        const Filter1 = res.data.filter(item => item.user_id == id)
        setAdmin(Filter1)
      });
    setTimeout(() => {
      student.map((item) => {
        if (item.id == id) {
          document.querySelector("#username_malumot").value = item.username;
          document.querySelector("#last_name_malumot").value = item.last_name;
          document.querySelector("#address_malumot").value = item.address;
          document.querySelector("#email_malumot").value = item.email;
          document.querySelector("#phone_number_malumot").value =
            item.phone_number;
            document.querySelector("#youtube_tables1").value = item.youtobe
            document.querySelector("#telegram_tables1").value = item.telegram
            document.querySelector("#instagram_tables1").value = item.instagram
          document.querySelector("#description_malumot").value =
            item.description;
        }
      });
    }, 10);
    setStudentId(id);
  }

  const columns = [
    {
      title: "Изображение",
      dataIndex: "image",
      key: "image",
      render: (image) => (
        <img alt={image} src={image} />
      ),
    },
    {
      title: "Имя",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Фамилия",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: (
        <Radio.Group defaultValue="c">
          <Radio.Button onClick={() => BanFilter(true)} value="a">
            забанить
          </Radio.Button>
          <Radio.Button onClick={() => BanFilter(false)} value="b">
            разбанить
          </Radio.Button>
          <Radio.Button onClick={() => BanFilter1()} value="c">
            Все
          </Radio.Button>
        </Radio.Group>
      ),
      dataIndex: "id",
      key: "balance",
      render: (dataIndex) => (
        <div style={{display:'flex',alignItems:'center',gap:'5px'}}>
          {student.map((item) => {
            if (item.id == dataIndex) {
              return (
                <div>
                  {false === item.is_active ? (
                    <button
                      onClick={() => Ban(true, dataIndex)}
                      className="user_post_button_ban"
                    >
                      разбанить
                    </button>
                  ) : (
                    <button
                      onClick={() => Ban(false, dataIndex)}
                      className="user_post_button_bandan_olish"
                    >
                      забанить
                    </button>
                  )}
                </div>
              );
            }
          })}{" "}
          {(dostup[0].admin_delete || sessionStorage.getItem("position")==3)?(<button
            onClick={() => deleteStudentModal(dataIndex)}
            className="user_post_button"
          >
            Удалить
          </button>):(<div></div>)}
          {" "}
          {(dostup[0].admin_put || sessionStorage.getItem("position")==3)?(<button
            onClick={() => pageMalumot(dataIndex)}
            className="user_post_button_malumot"
          >
            Информация
          </button>):(<div></div>)}
          
        </div>
      ),
    },
  ];
  function AdminDostup(key) {
    document.querySelectorAll("#admin_dostup_div")[key].classList.toggle("db");
  }

  function Search(e) {
    const Serach = student.filter((item) =>
      item.username.includes(e.target.value)
    );
    if (e.target.value) {
      setStudent(Serach);
    } else {
      setStudent(student1);
    }
  }

  function BanFilter(id) {
    axios
      .get(`${URL}/auth/users`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        const Filter = res.data.filter(
          (item) => item.is_active == id && item.position == 5
        );
        setStudent(Filter);
      });
  }

  function BanFilter1() {
    axios
      .get(`${URL}/auth/users`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        const Filter = res.data.filter((item) => item.position == 5);
        setStudent(Filter);
      });
  }

  function AdminModal() {
    document.querySelector("#postAdmin").style = "display:flex;";
  }
  function AdminClose() {
    document.querySelector("#postAdmin").style = "display:none";
  }
  function putAdminModal(id) {
    setAdminId(id)
    Admin.map(item=>{
      if (item.id==id) {
     getID1[18].checked=item.diogram_get
     getID1[1].checked=item.alluser_get
     postID1[1].checked=item.alluser_post
     deleteID1[1].checked=item.alluser_delete
     putID1[1].checked=item.alluser_put
     getID1[2].checked=item.student_get
     postID1[2].checked=item.student_post
     deleteID1[2].checked=item.student_delete
     putID1[2].checked=item.student_put
     getID1[3].checked=item.teacher_get
     postID1[3].checked=item.teacher_post
     deleteID1[3].checked=item.teacher_delete
     putID1[3].checked=item.teacher_put
     getID1[4].checked=item.admin_get
     postID1[4].checked=item.admin_post
     deleteID1[4].checked=item.admin_delete
     putID1[4].checked=item.admin_put
     getID1[0].checked=item.course_get
     postID1[0].checked=item.course_post
     deleteID1[0].checked=item.course_delete
     putID1[0].checked=item.course_put
     getID1[5].checked=item.news_get
     postID1[5].checked=item.news_post
     deleteID1[5].checked=item.news_delete
     putID1[5].checked=item.news_put
     getID1[6].checked=item.chat_get
     postID1[6].checked=item.chat_post
     deleteID1[6].checked=item.chat_delete
     putID1[6].checked=item.chat_put
     getID1[17].checked=item.get_allchat
     getID1[7].checked=item.dars_get
     postID1[7].checked=item.dars_post
     deleteID1[7].checked=item.dars_delete
     putID1[7].checked=item.dars_put
     getID1[8].checked=item.dars_student_get
     postID1[8].checked=item.dars_student_post
     deleteID1[8].checked=item.dars_student_delete
     putID1[8].checked=item.dars_student_put
     getID1[9].checked=item.test_get
     postID1[9].checked=item.test_post
     deleteID1[9].checked=item.test_delete
     putID1[9].checked=item.test_put
     getID1[10].checked=item.test_student_get
     postID1[10].checked=item.test_student_post
     deleteID1[10].checked=item.test_student_delete
     putID1[10].checked=item.test_student_put
     getID1[11].checked=item.call_me_get
     postID1[11].checked=item.call_me_post
     deleteID1[11].checked=item.call_me_delete
     putID1[11].checked=item.call_me_put
     getID1[12].checked=item.universitet_get
     postID1[12].checked=item.universitet_post
     deleteID1[12].checked=item.universitet_delete
     putID1[12].checked=item.universitet_put
     getID1[13].checked=item.help_get
     postID1[13].checked=item.help_post
     deleteID1[13].checked=item.help_delete
     putID1[13].checked=item.help_put
     getID1[14].checked=item.category_get
     postID1[14].checked=item.category_post
     deleteID1[14].checked=item.category_delete
     putID1[14].checked=item.category_put
     getID1[15].checked=item.notification_get
     postID1[15].checked=item.notification_post
     deleteID1[15].checked=item.notification_delete
     putID1[15].checked=item.notification_put
     getID1[16].checked=item.get_pay
     document.querySelector('#PgetID124').checked=item.create_video
     document.querySelector('#PgetID123').checked=item.pomish
     document.querySelector('#PgetID125').checked=item.servis_get
     document.querySelector('#PpostID126').checked=item.servis_post
      document.querySelector('#PdeleteID128').checked=item.servis_delete

      document.querySelector('#PputID127').checked=item.servis_put

      document.querySelector('#PgetID25').checked=item.homiy_get
      document.querySelector('#PpostID26').checked=item.homiy_post

      document.querySelector('#PdeleteID27').checked=item.homiy_delete
      document.querySelector('#PputID28').checked=item.homiy_put
      document.querySelector('#PgetID225').checked=item.sertifikat_get
      document.querySelector('#PpostID226').checked=item.sertifikat_post
      document.querySelector('#PdeleteID227').checked=item.sertifikat_delete
      document.querySelector('#PputID228').checked=item.sertifikat_put
    }
    })
    document.querySelector("#putAdmin").style = "display:flex;";
  }
  function putAdminClose() {
    document.querySelector("#putAdmin").style = "display:none";
  }

  function deleteAdminModal(id){
    setAdminId(id)
    document.querySelector("#deleteAdmin").style="display:flex"
  }
  function deleteAdminClose(){
    document.querySelector("#deleteAdmin").style="display:none"
  }

  function postAdmin() {
    var formdata = new FormData()
    formdata.append("user_id", studentId)
    formdata.append("diogram_get", getID[18].checked)
    formdata.append("alluser_get", getID[1].checked)
    formdata.append("alluser_post", postID[1].checked)
    formdata.append("alluser_delete", deleteID[1].checked)
    formdata.append("alluser_put", putID[1].checked)
    formdata.append("student_get", getID[2].checked)
    formdata.append("student_post", postID[2].checked)
    formdata.append("student_delete", deleteID[2].checked)
    formdata.append("student_put", putID[2].checked)
    formdata.append("teacher_get", getID[3].checked)
    formdata.append("teacher_post", postID[3].checked)
    formdata.append("teacher_delete", deleteID[3].checked)
    formdata.append("teacher_put", putID[3].checked)
    formdata.append("admin_get", getID[4].checked)
    formdata.append("admin_post", postID[4].checked)
    formdata.append("admin_delete", deleteID[4].checked)
    formdata.append("admin_put", putID[4].checked)
    formdata.append("course_get", getID[0].checked)
    formdata.append("course_post", postID[0].checked)
    formdata.append("course_delete", deleteID[0].checked)
    formdata.append("course_put", putID[0].checked)
    formdata.append("news_get", getID[5].checked)
    formdata.append("news_post", postID[5].checked)
    formdata.append("news_delete", deleteID[5].checked)
    formdata.append("news_put", putID[5].checked)
    formdata.append("chat_get", getID[6].checked)
    formdata.append("chat_post", postID[6].checked)
    formdata.append("chat_delete", deleteID[6].checked)
    formdata.append("chat_put", putID[6].checked)
    formdata.append("get_allchat", getID[17].checked)
    formdata.append("dars_get", getID[7].checked)
    formdata.append("dars_post", postID[7].checked)
    formdata.append("dars_delete", deleteID[7].checked)
    formdata.append("dars_put", putID[7].checked)
    formdata.append("dars_student_get", getID[8].checked)
    formdata.append("dars_student_post", postID[8].checked)
    formdata.append("dars_student_delete", deleteID[8].checked)
    formdata.append("dars_student_put", putID[8].checked)
    formdata.append("test_get", getID[9].checked)
    formdata.append("test_post", postID[9].checked)
    formdata.append("test_delete", deleteID[9].checked)
    formdata.append("test_put", putID[9].checked)
    formdata.append("test_student_get", getID[10].checked)
    formdata.append("test_student_post", postID[10].checked)
    formdata.append("test_student_delete", deleteID[10].checked)
    formdata.append("test_student_put", putID[10].checked)
    formdata.append("call_me_get", getID[11].checked)
    formdata.append("call_me_post", postID[11].checked)
    formdata.append("call_me_delete", deleteID[11].checked)
    formdata.append("call_me_put", putID[11].checked)
    formdata.append("universitet_get", getID[12].checked)
    formdata.append("universitet_post", postID[12].checked)
    formdata.append("universitet_delete", deleteID[12].checked)
    formdata.append("universitet_put", putID[12].checked)
    formdata.append("help_get", getID[13].checked)
    formdata.append("help_post", postID[13].checked)
    formdata.append("help_delete", deleteID[13].checked)
    formdata.append("help_put", putID[13].checked)
    formdata.append("category_get", getID[14].checked)
    formdata.append("category_post", postID[14].checked)
    formdata.append("category_delete", deleteID[14].checked)
    formdata.append("category_put", putID[14].checked)
    formdata.append("notification_get", getID[15].checked)
    formdata.append("notification_post", postID[15].checked)
    formdata.append("notification_delete", deleteID[15].checked)
    formdata.append("notification_put", putID[15].checked)
    formdata.append("get_pay", getID[16].checked)
    formdata.append("create_video", document.querySelector('#getID124').checked)
    formdata.append("pomish",  document.querySelector('#getID123').checked)
    formdata.append("servis_get",  document.querySelector('#getID125').checked)
    formdata.append("servis_post",  document.querySelector('#postID126').checked)
    formdata.append("servis_delete",  document.querySelector('#deleteID128').checked)
    formdata.append("servis_put",  document.querySelector('#putID127').checked)

 formdata.append("homiy_get",  document.querySelector('#getID25').checked)
    formdata.append("homiy_post",  document.querySelector('#postID26').checked)
    formdata.append("homiy_delete",  document.querySelector('#deleteID27').checked)
    formdata.append("homiy_put",  document.querySelector('#putID28').checked)
formdata.append("sertifikat_get",  document.querySelector('#getID225').checked)
    formdata.append("sertifikat_post",  document.querySelector('#postID226').checked)
    formdata.append("sertifikat_delete",  document.querySelector('#deleteID227').checked)
    formdata.append("sertifikat_put",  document.querySelector('#putID228').checked)

    axios.post(`${URL}/auth/admin`, formdata, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      alert("Добавлена ​​информация")
      document.querySelector("#postAdmin").style = "display:none";
      axios
      .get(`${URL}/auth/admin`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        const Filter1 = res.data.filter(item => item.user_id == studentId)
        setAdmin(Filter1)
      });
    }).catch(err => {
      alert("Информация не добавлена, заполните полностью")
    })



  }
  function putAdmin() {
    var formdata = new FormData()
    formdata.append("user_id", studentId)
    formdata.append("diogram_get", getID1[18].checked)
    formdata.append("alluser_get", getID1[1].checked)
    formdata.append("alluser_post", postID1[1].checked)
    formdata.append("alluser_delete", deleteID1[1].checked)
    formdata.append("alluser_put", putID1[1].checked)
    formdata.append("student_get", getID1[2].checked)
    formdata.append("student_post", postID1[2].checked)
    formdata.append("student_delete", deleteID1[2].checked)
    formdata.append("student_put", putID1[2].checked)
    formdata.append("teacher_get", getID1[3].checked)
    formdata.append("teacher_post", postID1[3].checked)
    formdata.append("teacher_delete", deleteID1[3].checked)
    formdata.append("teacher_put", putID1[3].checked)
    formdata.append("admin_get", getID1[4].checked)
    formdata.append("admin_post", postID1[4].checked)
    formdata.append("admin_delete", deleteID1[4].checked)
    formdata.append("admin_put", putID1[4].checked)
    formdata.append("course_get", getID1[0].checked)
    formdata.append("course_post", postID1[0].checked)
    formdata.append("course_delete", deleteID1[0].checked)
    formdata.append("course_put", putID1[0].checked)
    formdata.append("news_get", getID1[5].checked)
    formdata.append("news_post", postID1[5].checked)
    formdata.append("news_delete", deleteID1[5].checked)
    formdata.append("news_put", putID1[5].checked)
    formdata.append("chat_get", getID1[6].checked)
    formdata.append("chat_post", postID1[6].checked)
    formdata.append("chat_delete", deleteID1[6].checked)
    formdata.append("chat_put", putID1[6].checked)
    formdata.append("get_allchat", getID1[17].checked)
    formdata.append("dars_get", getID1[7].checked)
    formdata.append("dars_post", postID1[7].checked)
    formdata.append("dars_delete", deleteID1[7].checked)
    formdata.append("dars_put", putID1[7].checked)
    formdata.append("dars_student_get", getID1[8].checked)
    formdata.append("dars_student_post", postID1[8].checked)
    formdata.append("dars_student_delete", deleteID1[8].checked)
    formdata.append("dars_student_put", putID1[8].checked)
    formdata.append("test_get", getID1[9].checked)
    formdata.append("test_post", postID1[9].checked)
    formdata.append("test_delete", deleteID1[9].checked)
    formdata.append("test_put", putID1[9].checked)
    formdata.append("test_student_get", getID1[10].checked)
    formdata.append("test_student_post", postID1[10].checked)
    formdata.append("test_student_delete", deleteID1[10].checked)
    formdata.append("test_student_put", putID1[10].checked)
    formdata.append("call_me_get", getID1[11].checked)
    formdata.append("call_me_post", postID1[11].checked)
    formdata.append("call_me_delete", deleteID1[11].checked)
    formdata.append("call_me_put", putID1[11].checked)
    formdata.append("universitet_get", getID1[12].checked)
    formdata.append("universitet_post", postID1[12].checked)
    formdata.append("universitet_delete", deleteID1[12].checked)
    formdata.append("universitet_put", putID1[12].checked)
    formdata.append("help_get", getID1[13].checked)
    formdata.append("help_post", postID1[13].checked)
    formdata.append("help_delete", deleteID1[13].checked)
    formdata.append("help_put", putID1[13].checked)
    formdata.append("category_get", getID1[14].checked)
    formdata.append("category_post", postID1[14].checked)
    formdata.append("category_delete", deleteID1[14].checked)
    formdata.append("category_put", putID1[14].checked)
    formdata.append("notification_get", getID1[15].checked)
    formdata.append("notification_post", postID1[15].checked)
    formdata.append("notification_delete", deleteID1[15].checked)
    formdata.append("notification_put", putID1[15].checked)
    formdata.append("get_pay", getID1[16].checked)
formdata.append("create_video", document.querySelector('#PgetID124').checked)
    formdata.append("pomish",  document.querySelector('#PgetID123').checked)
    formdata.append("servis_get",  document.querySelector('#PgetID125').checked)
    formdata.append("servis_post",  document.querySelector('#PpostID126').checked)
    formdata.append("servis_delete",  document.querySelector('#PdeleteID128').checked)
    formdata.append("servis_put",  document.querySelector('#PputID127').checked)

 formdata.append("homiy_get",  document.querySelector('#PgetID25').checked)
    formdata.append("homiy_post",  document.querySelector('#PpostID26').checked)
    formdata.append("homiy_delete",  document.querySelector('#PdeleteID27').checked)
    formdata.append("homiy_put",  document.querySelector('#PputID28').checked)
formdata.append("sertifikat_get",  document.querySelector('#PgetID225').checked)
    formdata.append("sertifikat_post",  document.querySelector('#PpostID226').checked)
    formdata.append("sertifikat_delete",  document.querySelector('#PdeleteID227').checked)
    formdata.append("sertifikat_put",  document.querySelector('#PputID228').checked)

    axios.put(`${URL}/auth/admin/${AdminId}`, formdata, { headers: { Authorization: "Bearer " + sessionStorage.getItem("token") } }).then(res => {
      alert("Информация изменилась")
      document.querySelector("#putAdmin").style = "display:none";
      axios
      .get(`${URL}/auth/admin`, {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      })
      .then((res) => {
        const Filter1 = res.data.filter(item => item.user_id == studentId)
        setAdmin(Filter1)
      });
    }).catch(err => {
      alert("Информация не изменилась, заполняйте полностью")
    })



  }
  function deleteAdmin(){
    axios.delete(`${URL}/auth/admin/${AdminId}`,{headers:{Authorization:"Bearer "+sessionStorage.getItem("token")}}).then(res=>{
    alert("Информация удалена")  
    document.querySelector("#deleteAdmin").style="display:none"
    axios
    .get(`${URL}/auth/admin`, {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    })
    .then((res) => {
      const Filter1 = res.data.filter(item => item.user_id == studentId)
      setAdmin(Filter1)
    });

    }).catch(err=>{
      alert("Данные не были удалены")
    })
  }

  function Checkbox(checked1){
    setCheckbox(checked1)
  }

  return (
    <div>
      {page == 1 ? (
        <div>
          <div className="exit_button">
            <button onClick={() => setPage(0)}>Назад</button>
          </div>
          <div className="malumot_big_div">
            <label htmlFor="">
              <p>Имя</p>
              <input id="username_malumot" type="text" />
            </label>
            <label htmlFor="">
              <p>Фамилия</p>
              <input id="last_name_malumot" type="text" />
            </label>
            <label htmlFor="">
              <p>Адрес</p>
              <input id="address_malumot" type="text" />
            </label>
            <label htmlFor="">
              <p>Баланс</p>
              {student.map((item) => {
                if (item.id == studentId) {
                  return (
                    <input
                      id="balance_malumot"
                      value={item.balance}
                      type="password"
                    />
                  );
                }
              })}
            </label>
            <label htmlFor="">
              <p>Дата присоединения</p>
              {student.map((item) => {
                if (item.id == studentId) {
                  return (
                    <input
                      id="date_joined_malumot"
                      value={item.date_joined.slice(0, 10)}
                      type="text"
                    />
                  );
                }
              })}
            </label>
            <label htmlFor="">
            <p>Youtube link</p>
            <input  id="youtube_tables1"  type="text" />
            </label>
            <label htmlFor="">
            <p>Telegram link</p>
            <input id="telegram_tables1"   type="text" />
            </label>
            <label htmlFor="">
            <p>Instagram link</p>
            <input id="instagram_tables1"  type="text" />
            </label>
            <label htmlFor="">
              <p>Электронная почта</p>
              <input id="email_malumot" type="text" />
            </label>
            <label htmlFor="">
              <p>Пароль</p>
              {student.map((item) => {
                if (item.id == studentId) {
                  return (
                    <input
                      id="password_malumot"
                      value={item.password}
                      type="password"
                    />
                  );
                }
              })}
            </label>
            <label htmlFor="">
              <p>Номер телефона</p>
              <input id="phone_number_malumot" type="text" />
            </label>
            {student.map((item) => {
              if (item.id == studentId) {
                return (
                  <div>
                    <label htmlFor="">
                      <p>Время создавать</p>
                      <input
                        id="time_create_malumot"
                        value={item.time_create.slice(0, 10)}
                        type="text"
                      />
                    </label>
                    <label htmlFor="">
                      <p>Обновление времени</p>
                      <input
                        id="time_update_malumot"
                        value={item.time_update.slice(0, 10)}
                        type="text"
                      />
                    </label>
                  </div>
                );
              }
            })}
            <label htmlFor="">
              <p>Позиция</p>
              <div className="position">
                <button
                  className="position_button"
                  onClick={() => PositionId(5)}
                  style={
                    styleId == 5
                      ? { backgroundColor: "#1890ff", color: "#fff" }
                      : {}
                  }
                >
                  Администратор
                </button>
              </div>
            </label>
            <label htmlFor="">
              <p>Изображение       <input type="checkbox"  onClick={(e)=>Checkbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
              {checkbox==false?(<input  id="image_malumot" type="file" />):(<input id="image_malumotCheckbox" type="text" />)}
            </label>
            <label htmlFor="">
              <p>Описание</p>
              <input id="description_malumot" type="text" />
            </label>
          </div>
          <div className="postUserModal_div_button">
            <button
              className="user_post_button_malumot"
              onClick={() => putStudent()}
            >
              Редактировать
            </button>
          </div>
          <div className="admin_uchun_dostup_big_div">
            <h1 className="admin_uchun_dostup_big_div_h1">
              Для администратора доступ
            </h1>

            {Admin == "" ? (
              <div className="admin_div_button">
                <button onClick={() => AdminModal()}>Создавать</button>
              </div>) : (
              <div>
                <div className="admin_uchun_dostup_div">
                  {Admin.map((item, key) => {

                    return (
                      <div style={{display:'flex',alignItems:'strech',gap:'5px',justifyContent:'space-around',flexWrap:'wrap'}}>
                        <div className="admin_div_button">
                          <button onClick={()=>putAdminModal(item.id)} >Редактировать</button>
                          <button onClick={() => deleteAdminModal(item.id)}>Удалить</button>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Курс</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.course_get} type="checkbox" />
                              <p>Get</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.course_post} type="checkbox" />
                              <p>Post</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.course_put} type="checkbox" />
                              <p>Put</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.course_delete} type="checkbox" />
                              <p>Delete</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Все пользователи</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.alluser_get} type="checkbox" />
                              <p>Get</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.alluser_post} type="checkbox" />
                              <p>Post</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.alluser_put} type="checkbox" />
                              <p>Put</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.alluser_delete} type="checkbox" />
                              <p>Delete</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Студент</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.student_get} type="checkbox" />
                              <p>Get</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.student_post} type="checkbox" />
                              <p>Post</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.student_put} type="checkbox" />
                              <p>Put</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.student_delete} type="checkbox" />
                              <p>Delete</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Учитель</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.teacher_get} type="checkbox" />
                              <p>Get</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.teacher_post} type="checkbox" />
                              <p>Post</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.teacher_put} type="checkbox" />
                              <p>Put</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.teacher_delete} type="checkbox" />
                              <p>Delete</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Админ</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.admin_get} type="checkbox" />
                              <p>Get</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.admin_post} type="checkbox" />
                              <p>Post</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.admin_put} type="checkbox" />
                              <p>Put</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.admin_delete} type="checkbox" />
                              <p>Delete</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Новости</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.news_get} type="checkbox" />
                              <p>Get</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.news_post} type="checkbox" />
                              <p>Post</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.news_put} type="checkbox" />
                              <p>Put</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.news_delete} type="checkbox" />
                              <p>Delete</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Чат</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.chat_get} type="checkbox" />
                              <p>Get</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.chat_post} type="checkbox" />
                              <p>Post</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.chat_put} type="checkbox" />
                              <p>Put</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.chat_delete} type="checkbox" />
                              <p>Delete</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Урок</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.dars_get} type="checkbox" />
                              <p>Get</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.dars_post} type="checkbox" />
                              <p>Post</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.dars_put} type="checkbox" />
                              <p>Put</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.dars_delete} type="checkbox" />
                              <p>Delete</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Урок - ученик</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.dars_student_get} type="checkbox" />
                              <p>Get</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.dars_student_post} type="checkbox" />
                              <p>Post</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.dars_student_put} type="checkbox" />
                              <p>Put</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.dars_student_delete} type="checkbox" />
                              <p>Delete</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Тест</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.test_get} type="checkbox" />
                              <p>Get</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.test_post} type="checkbox" />
                              <p>Post</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.test_put} type="checkbox" />
                              <p>Put</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.test_delete} type="checkbox" />
                              <p>Delete</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Студент-испытатель</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.test_student_get} type="checkbox" />
                              <p>Get</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.test_student_post} type="checkbox" />
                              <p>Post</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.test_student_put} type="checkbox" />
                              <p>Put</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.test_student_delete} type="checkbox" />
                              <p>Delete</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Позвоните мне</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.call_me_get} type="checkbox" />
                              <p>Get</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.call_me_post} type="checkbox" />
                              <p>Post</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.call_me_put} type="checkbox" />
                              <p>Put</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.call_me_delete} type="checkbox" />
                              <p>Delete</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Университет</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.universitet_get} type="checkbox" />
                              <p>Get</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.universitet_post} type="checkbox" />
                              <p>Post</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.universitet_put} type="checkbox" />
                              <p>Put</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.universitet_delete} type="checkbox" />
                              <p>Delete</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Помощь</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.help_get} type="checkbox" />
                              <p>Get</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.help_post} type="checkbox" />
                              <p>Post</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.help_put} type="checkbox" />
                              <p>Put</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.help_delete} type="checkbox" />
                              <p>Delete</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Категория</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.category_get} type="checkbox" />
                              <p>Get</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.category_post} type="checkbox" />
                              <p>Post</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.category_put} type="checkbox" />
                              <p>Put</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.category_delete} type="checkbox" />
                              <p>Delete</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Уведомление</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.notification_get} type="checkbox" />
                              <p>Get</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.notification_post} type="checkbox" />
                              <p>Post</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.notification_put} type="checkbox" />
                              <p>Put</p>
                            </div>
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.notification_delete} type="checkbox" />
                              <p>Delete</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Платить</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.get_pay} type="checkbox" />
                              <p>Get</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Диограмма</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.diogram_get} type="checkbox" />
                              <p>Get</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                          <h1 >Все чаты</h1>
                          <div
                            id="admin_dostup_div"
                            className="admin_uchun_dostup_small_div_element_div"
                          >
                            <div className="admin_uchun_dostup_small_div_element_div_p">
                              <input checked={item.get_allchat} type="checkbox" />
                              <p>Get</p>
                            </div>
                          </div>
                        </div>
                        <div className="admin_uchun_dostup_small_div">
                        <h1 >Помощь</h1>
                        <div
                          id="admin_dostup_div"
                          className="admin_uchun_dostup_small_div_element_div"
                        >
                          <div className="admin_uchun_dostup_small_div_element_div_p">
                            <input checked={item.pomish} type="checkbox" />
                            <p>Get</p>
                          </div>
                        </div>
                      </div>
                      <div className="admin_uchun_dostup_small_div">
                      <h1 >Создание видео</h1>
                      <div
                        id="admin_dostup_div"
                        className="admin_uchun_dostup_small_div_element_div"
                      >
                        <div className="admin_uchun_dostup_small_div_element_div_p">
                          <input checked={item.create_video} type="checkbox" />
                          <p>Get</p>
                        </div>
                      </div>
                    </div>
                    <div className="admin_uchun_dostup_small_div">
                    <h1 >Обслуживание</h1>
                    <div
                      id="admin_dostup_div"
                      className="admin_uchun_dostup_small_div_element_div"
                    >
                      <div className="admin_uchun_dostup_small_div_element_div_p">
                        <input checked={item.servis_get} type="checkbox" />
                        <p>Get</p>
                      </div>
                      <div className="admin_uchun_dostup_small_div_element_div_p">
                        <input checked={item.servis_post} type="checkbox" />
                        <p>Post</p>
                      </div>
                      <div className="admin_uchun_dostup_small_div_element_div_p">
                        <input checked={item.servis_put} type="checkbox" />
                        <p>Put</p>
                      </div>
                      <div className="admin_uchun_dostup_small_div_element_div_p">
                        <input checked={item.servis_delete} type="checkbox" />
                        <p>Delete</p>
                      </div>
                    </div>
                  </div>
                  <div className="admin_uchun_dostup_small_div">
                  <h1 >Спонсор</h1>
                  <div
                    id="admin_dostup_div"
                    className="admin_uchun_dostup_small_div_element_div"
                  >
                    <div className="admin_uchun_dostup_small_div_element_div_p">
                      <input checked={item.homiy_get} type="checkbox" />
                      <p>Get</p>
                    </div>
                    <div className="admin_uchun_dostup_small_div_element_div_p">
                      <input checked={item.homiy_post} type="checkbox" />
                      <p>Post</p>
                    </div>
                    <div className="admin_uchun_dostup_small_div_element_div_p">
                      <input checked={item.homiy_put} type="checkbox" />
                      <p>Put</p>
                    </div>
                    <div className="admin_uchun_dostup_small_div_element_div_p">
                      <input checked={item.homiy_delete} type="checkbox" />
                      <p>Delete</p>
                    </div>
                  </div>
                </div>
                <div className="admin_uchun_dostup_small_div">
                <h1 >Сертификация</h1>
                <div
                  id="admin_dostup_div"
                  className="admin_uchun_dostup_small_div_element_div"
                >
                  <div className="admin_uchun_dostup_small_div_element_div_p">
                    <input checked={item.sertifikat_get} type="checkbox" />
                    <p>Get</p>
                  </div>
                  <div className="admin_uchun_dostup_small_div_element_div_p">
                    <input checked={item.sertifikat_post} type="checkbox" />
                    <p>Post</p>
                  </div>
                  <div className="admin_uchun_dostup_small_div_element_div_p">
                    <input checked={item.sertifikat_put} type="checkbox" />
                    <p>Put</p>
                  </div>
                  <div className="admin_uchun_dostup_small_div_element_div_p">
                    <input checked={item.sertifikat_delete} type="checkbox" />
                    <p>Delete</p>
                  </div>
                </div>
              </div>



                      </div>
                    )

                  })}
                </div>
              </div>
            )}







          </div>
        </div>
      ) : (
        <div>
          <div className="search_big_div">
            {(dostup[0].admin_post || sessionStorage.getItem("position")==3)?(<button
              onClick={() => postStudentModal()}
              className="user_post_button"
            >
              Добавить
            </button>):(<div></div>)}
            <div style={{position:'relative'}}>
            <AiOutlineSearch className="search" />
            <input
              onChange={Search}
              placeholder="Введите здесь..."
              type="text"
            /></div>
          </div>
          <Table dataSource={student} columns={columns} />
        </div>
      )
      }

      <div id="deleteStudentModal" className="deleteModal">
        <div className="deleteModal_div">
          <div className="postUserModal_div_icon">
            <img
              src={close}
              className="icon"
              onClick={() => deleteStudentClose()}
            />
          </div>
          <img src={deleteImg} alt="" />
          <p>Вы хотите удалить студента</p>
          <div className="deleteButton_div">
            <button onClick={() => deleteStudentClose()}>Отмена</button>
            <button onClick={() => deleteStudent()}>Подтвердить</button>
          </div>
        </div>
      </div>
      <div id="postStudentModal" className="Modaldiv">
        <div className="postUserModal_div">
          <div className="postUserModal_div_icon">
            <img
              src={close}
              className="icon"
              onClick={() => postStudentClose()}
            />
          </div>
          <div className="postUserModal_div_label">
            <label htmlFor="">
              <p>Имя пользователя</p>
              <input id="username" placeholder="Имя пользователя" type="text" />
            </label>
            <label htmlFor="">
              <p>Фамилия</p>
              <input id="last_name" placeholder="Фамилия" type="text" />
            </label>
            <label htmlFor="">
              <p>Телефон номер</p>
              <input
                id="phone_number"
                placeholder="Телефон номер"
                type="text"
              />
            </label>
            <div style={{display:'flex',justifyContent:'space-between'}}>
            <label htmlFor="">
            <p>Youtube link</p>
            <input style={{width:'90px'}} id="youtube_tables"  type="text" />
            </label>
            <label htmlFor="">
            <p>Telegram link</p>
            <input id="telegram_tables"  style={{width:'90px'}} type="text" />
            </label>
            <label htmlFor="">
            <p>Instagram link</p>
            <input id="instagram_tables" style={{width:'90px'}} type="text" />
            </label>
            </div>  
            <label htmlFor="">
              <p>Электронная почта</p>
              <input id="email" placeholder="Электронная почта" type="text" />
            </label>
            <label htmlFor="">
              <p>Адрес</p>
              <input id="address" placeholder="Адрес" type="text" />
            </label>
            <label htmlFor="">
              <p>Пароль</p>
              <input id="password" placeholder="Пароль" type="text" />
            </label>
            <label htmlFor="">
              <p>Позиция</p>
              <div className="position">
                <button
                  onClick={() => PositionId(5)}
                  style={
                    positionId == 5
                      ? { backgroundColor: "#1890ff", color: "#fff" }
                      : {}
                  }
                >
                  Администратор
                </button>
              </div>
            </label>
            <label htmlFor="">
              <p>Изображение    <input type="checkbox"  onClick={(e)=>Checkbox(e.target.checked)}  style={{width:'15px',height:'15px'}} /></p>
              {checkbox==false?(<input id="image" className="image" placeholder="Изображение" type="file" />):(<input id="imageChecboxs" className="image" placeholder="Изображение" type="text" />)}      
            </label>
          </div>
          <label htmlFor="">
            <p>Описание</p>
            <textarea
              id="description"
              placeholder="Описание"
              className="description"
              type="text"
            />
          </label>
          <div className="postUserModal_div_button">
            <button onClick={() => postStudent()}>Отправить</button>
          </div>
        </div>
      </div>
      <div id="putStudentModal" className="Modaldiv">
        <div className="postUserModal_div">
          <div className="postUserModal_div_icon">
            <img
              src={close}
              className="icon"
              onClick={() => putStudentClose()}
            />
          </div>
          <div className="postUserModal_div_label">
            <label htmlFor="">
              <p>Имя пользователя</p>
              <input id="username" type="text" />
            </label>
            <label htmlFor="">
              <p>Фамилия</p>
              <input id="last_name" type="text" />
            </label>
            <label htmlFor="">
              <p>Телефон номер</p>
              <input id="phone_number" type="text" />
            </label>
            <label htmlFor="">
              <p>Электронная почта</p>
              <input id="email" type="text" />
            </label>
            <label htmlFor="">
              <p>Адрес</p>
              <input id="address" type="text" />
            </label>
            <label htmlFor="">
              <p>Пароль</p>
              <input id="password" type="text" />
            </label>
            <label htmlFor="">
              <p>Позиция</p>
              <input id="position" type="text" />
            </label>
            <label htmlFor="">
              <p>Изображение</p>
              <input id="image" type="file" />
            </label>
          </div>
          <label htmlFor="">
            <p>Описание</p>
            <input
              id="description"
              placeholder="Описание"
              className="description"
              type="text"
            />
          </label>
          <div className="postUserModal_div_button">
            <button>Редактировать</button>
          </div>
        </div>
      </div>
      <div id="postAdmin" className="Modaldiv">
        <div style={{ width: '870px' }} className="postUserModal_div">
          <div style={{ marginBottom: '5px' }} className="postUserModal_div_icon">
            <img src={close} className="icon" onClick={() => AdminClose()} />
          </div>
          <div style={{ gridTemplateColumns: 'auto auto auto auto' }} className="postUserModal_div_label">
            <div className="admin_dostup_div">
              <h1>Курс</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
                <div><input id="postID" type="checkbox" /><p>Post</p></div>
                <div><input id="putID" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Все пользователи</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
                <div><input id="postID" type="checkbox" /><p>Post</p></div>
                <div><input id="putID" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Студент</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
                <div><input id="postID" type="checkbox" /><p>Post</p></div>
                <div><input id="putID" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Учитель</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
                <div><input id="postID" type="checkbox" /><p>Post</p></div>
                <div><input id="putID" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Админ</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
                <div><input id="postID" type="checkbox" /><p>Post</p></div>
                <div><input id="putID" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Новости</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
                <div><input id="postID" type="checkbox" /><p>Post</p></div>
                <div><input id="putID" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Чат</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
                <div><input id="postID" type="checkbox" /><p>Post</p></div>
                <div><input id="putID" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Урок</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
                <div><input id="postID" type="checkbox" /><p>Post</p></div>
                <div><input id="putID" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Урок - ученик</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
                <div><input id="postID" type="checkbox" /><p>Post</p></div>
                <div><input id="putID" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Тест</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
                <div><input id="postID" type="checkbox" /><p>Post</p></div>
                <div><input id="putID" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Студент-испытатель</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
                <div><input id="postID" type="checkbox" /><p>Post</p></div>
                <div><input id="putID" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Позвоните мне</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
                <div><input id="postID" type="checkbox" /><p>Post</p></div>
                <div><input id="putID" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Университет</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
                <div><input id="postID" type="checkbox" /><p>Post</p></div>
                <div><input id="putID" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Помощь</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
                <div><input id="postID" type="checkbox" /><p>Post</p></div>
                <div><input id="putID" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Категория</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
                <div><input id="postID" type="checkbox" /><p>Post</p></div>
                <div><input id="putID" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Уведомление</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
                <div><input id="postID" type="checkbox" /><p>Post</p></div>
                <div><input id="putID" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Платить</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Все чаты</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Диограмма</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID" type="checkbox" /><p>Get</p></div>
              </div>
            </div>

            <div className="admin_dostup_div">
            <h1>Помощь</h1>
            <div className="admin_dostup_div_element">
              <div><input id="getID123" type="checkbox" /><p>Get</p></div>
            </div>
          </div>
            <div className="admin_dostup_div">
              <h1>Создание видео</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID124" type="checkbox" /><p>Get</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
            <h1>Обслуживание</h1>
            <div className="admin_dostup_div_element">
              <div><input id="getID125" type="checkbox" /><p>Get</p></div>
              <div><input id="postID126" type="checkbox" /><p>Post</p></div>
              <div><input id="putID127" type="checkbox" /><p>Put</p></div>
              <div><input id="deleteID128" type="checkbox" /><p>Delete</p></div>
            </div>
          </div>
          <div className="admin_dostup_div">
          <h1>Спонсор</h1>
          <div className="admin_dostup_div_element">
            <div><input id="getID25" type="checkbox" /><p>Get</p></div>
            <div><input id="postID26" type="checkbox" /><p>Post</p></div>
            <div><input id="putID28" type="checkbox" /><p>Put</p></div>
            <div><input id="deleteID27" type="checkbox" /><p>Delete</p></div>
          </div>
        </div>
        <div className="admin_dostup_div">
        <h1>Сертификация</h1>
        <div className="admin_dostup_div_element">
          <div><input id="getID225" type="checkbox" /><p>Get</p></div>
          <div><input id="postID226" type="checkbox" /><p>Post</p></div>
          <div><input id="putID228" type="checkbox" /><p>Put</p></div>
          <div><input id="deleteID227" type="checkbox" /><p>Delete</p></div>
        </div>
      </div>

          </div>
          <div className="postUserModal_div_button">
            <button onClick={() => postAdmin()}>Отправить</button>
          </div>
        </div>
      </div>
      <div id="putAdmin" className="Modaldiv">
        <div style={{ width: '870px' }} className="postUserModal_div">
          <div style={{ marginBottom: '5px' }} className="postUserModal_div_icon">
            <img src={close} className="icon" onClick={() => putAdminClose()} />
          </div>
          <div style={{ gridTemplateColumns: 'auto auto auto auto' }} className="postUserModal_div_label">
            <div className="admin_dostup_div">
              <h1>Курс</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
                <div><input id="postID1" type="checkbox" /><p>Post</p></div>
                <div><input id="putID1" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID1" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Все пользователи</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
                <div><input id="postID1" type="checkbox" /><p>Post</p></div>
                <div><input id="putID1" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID1" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Студент</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
                <div><input id="postID1" type="checkbox" /><p>Post</p></div>
                <div><input id="putID1" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID1" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Учитель</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
                <div><input id="postID1" type="checkbox" /><p>Post</p></div>
                <div><input id="putID1" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID1" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Админ</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
                <div><input id="postID1" type="checkbox" /><p>Post</p></div>
                <div><input id="putID1" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID1" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Новости</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
                <div><input id="postID1" type="checkbox" /><p>Post</p></div>
                <div><input id="putID1" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID1" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Чат</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
                <div><input id="postID1" type="checkbox" /><p>Post</p></div>
                <div><input id="putID1" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID1" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Урок</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
                <div><input id="postID1" type="checkbox" /><p>Post</p></div>
                <div><input id="putID1" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID1" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Урок - ученик</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
                <div><input id="postID1" type="checkbox" /><p>Post</p></div>
                <div><input id="putID1" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID1" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Тест</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
                <div><input id="postID1" type="checkbox" /><p>Post</p></div>
                <div><input id="putID1" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID1" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Студент-испытатель</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
                <div><input id="postID1" type="checkbox" /><p>Post</p></div>
                <div><input id="putID1" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID1" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Позвоните мне</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
                <div><input id="postID1" type="checkbox" /><p>Post</p></div>
                <div><input id="putID1" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID1" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Университет</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
                <div><input id="postID1" type="checkbox" /><p>Post</p></div>
                <div><input id="putID1" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID1" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Помощь</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
                <div><input id="postID1" type="checkbox" /><p>Post</p></div>
                <div><input id="putID1" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID1" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Категория</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
                <div><input id="postID1" type="checkbox" /><p>Post</p></div>
                <div><input id="putID1" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID1" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Уведомление</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
                <div><input id="postID1" type="checkbox" /><p>Post</p></div>
                <div><input id="putID1" type="checkbox" /><p>Put</p></div>
                <div><input id="deleteID1" type="checkbox" /><p>Delete</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Платить</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Все чаты</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
              <h1>Диограмма</h1>
              <div className="admin_dostup_div_element">
                <div><input id="getID1" type="checkbox" /><p>Get</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
            <h1>Помощь</h1>
            <div className="admin_dostup_div_element">
              <div><input id="PgetID123" type="checkbox" /><p>Get</p></div>
            </div>
          </div>
            <div className="admin_dostup_div">
              <h1>Создание видео</h1>
              <div className="admin_dostup_div_element">
                <div><input id="PgetID124" type="checkbox" /><p>Get</p></div>
              </div>
            </div>
            <div className="admin_dostup_div">
            <h1>Обслуживание</h1>
            <div className="admin_dostup_div_element">
              <div><input id="PgetID125" type="checkbox" /><p>Get</p></div>
              <div><input id="PpostID126" type="checkbox" /><p>Post</p></div>
              <div><input id="PputID127" type="checkbox" /><p>Put</p></div>
              <div><input id="PdeleteID128" type="checkbox" /><p>Delete</p></div>
            </div>
          </div>
          <div className="admin_dostup_div">
          <h1>Спонсор</h1>
          <div className="admin_dostup_div_element">
            <div><input id="PgetID25" type="checkbox" /><p>Get</p></div>
            <div><input id="PpostID26" type="checkbox" /><p>Post</p></div>
            <div><input id="PputID28" type="checkbox" /><p>Put</p></div>
            <div><input id="PdeleteID27" type="checkbox" /><p>Delete</p></div>
          </div>
        </div>
        <div className="admin_dostup_div">
        <h1>Сертификация</h1>
        <div className="admin_dostup_div_element">
          <div><input id="PgetID225" type="checkbox" /><p>Get</p></div>
          <div><input id="PpostID226" type="checkbox" /><p>Post</p></div>
          <div><input id="PputID228" type="checkbox" /><p>Put</p></div>
          <div><input id="PdeleteID227" type="checkbox" /><p>Delete</p></div>
        </div>
      </div>
          </div>
          <div className="postUserModal_div_button">
            <button onClick={() => putAdmin()}>Редактировать</button>
          </div>
        </div>
      </div>
      <div id="deleteAdmin" className="deleteModal">
        <div className="deleteModal_div">
          <div className="postUserModal_div_icon">
            <img
              src={close}
              className="icon"
              onClick={() => deleteAdminClose()}
            />
          </div>
          <img src={deleteImg} alt="" />
          <p>Вы хотите удалить разрешения, предоставленные этому администратору?</p>
          <div className="deleteButton_div">
            <button onClick={() => deleteAdminClose()}>Отмена</button>
            <button onClick={() => deleteAdmin()}>Подтвердить</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Student;
